export const numberWithCommas = (n) => {
  var parts = n.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
};

export const padRight = (message) => {
  for (let i = message.length; i < 32; i++) {
    message += `${Math.floor(Math.random() * 10)}`;
  }
  return message;
};

export const ToFixed = (number, count) => {
  const parsedNumber = parseFloat(number);

  if (count) {
    const pass = parsedNumber * count;
    const convertedNumber = Math.floor(pass);
    return convertedNumber / count;
  } else {
    const pass = parsedNumber * 100;
    const convertedNumber = Math.floor(pass);
    return convertedNumber / 100;
  }
};

export const dateConvertor = (date) =>
  date.getFullYear() +
  "-" +
  ("00" + (date.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + date.getDate()).slice(-2) +
  " " +
  ("00" + date.getHours()).slice(-2) +
  ":" +
  ("00" + date.getMinutes()).slice(-2) +
  ":" +
  ("00" + date.getSeconds()).slice(-2);
