export const languageSelect = (index, language) => {
  switch (index) {
    case "한국어":
      return "한국어";
    case "English":
      return "English";
    case "中國":
      return "中國";
    default:
      return "None";
  }
};

export const languagePack = (index, language) => {
  switch (language) {
    case "korean":
      switch (index) {
        case "OTP 인증번호를 입력하세요.":
          return "OTP 인증번호를 입력하세요.";

        case "올바른 OTP 인증번호가 아닙니다.":
          return "올바른 OTP 인증번호가 아닙니다.";
        case "OTP가 등록되었습니다.":
          return "OTP가 등록되었습니다.";
        case "잘못된 OTP 인증번호입니다.":
          return "잘못된 OTP 인증번호입니다.";
        case "OTP가 해제되었습니다.":
          return "OTP가 해제되었습니다.";
        case "OTP 키가 복사되었습니다.":
          return "OTP 키가 복사되었습니다.";
        case "이미 OTP가 등록되어있습니다.":
          return "이미 OTP가 등록되어있습니다.";
        case "OTP를 해제하시려면 OTP 인증번호를 입력해주세요.":
          return "OTP를 해제하시려면 OTP 인증번호를 입력해주세요.";
        case "OTP 인증번호 입력":
          return "OTP 인증번호 입력";
        case "등록된 OTP가 없습니다. OTP를 등록하시겠습니까?":
          return "등록된 OTP가 없습니다. OTP를 등록하시겠습니까?";
        case "OTP 등록 시 송금 인증에 비밀번호 대신 OTP가 사용됩니다.":
          return "OTP 등록 시 송금 인증에 비밀번호 대신 OTP가 사용됩니다.";
        case "앱에 키를 등록해주세요.":
          return "앱에 키를 등록해주세요.";
        case "앱에 해당 OTP 키를 입력해주세요.":
          return "앱에 해당 OTP 키를 입력해주세요.";

        case "잘못된 비밀키입니다.":
          return "잘못된 비밀키입니다.";
        case "잔액":
          return "잔액";
        case "비밀번호를 입력하세요.":
          return "비밀번호를 입력하세요.";
        case "지원하지 않는 플랫폼입니다.":
          return "지원하지 않는 플랫폼입니다.";
        case "이미 전화번호 인증이 완료된 계정입니다.":
          return "이미 전화번호 인증이 완료된 계정입니다.";
        case "이미 등록된 전화번호입니다.":
          return "이미 등록된 전화번호입니다.";
        case "이미 인증된 계정입니다.":
          return "이미 인증된 계정입니다.";
        case "올바른 전화번호가 아닙니다.":
          return "올바른 전화번호가 아닙니다.";
        case "성공적으로 처리되었습니다.":
          return "성공적으로 처리되었습니다.";
        case "최소 수량은 200,000개 입니다.":
          return "최소 수량은 200,000개 입니다.";
        case "서버 오류입니다. 잠시 후에 다시 시도해주세요.":
          return "서버 오류입니다. 잠시 후에 다시 시도해주세요.";
        case "추천인 코드를 등록했습니다.":
          return "추천인 코드를 등록했습니다.";
        case "이미 등록된 추천인 코드가 있습니다.":
          return "이미 등록된 추천인 코드가 있습니다.";
        case "자기 자신은 등록할 수 없습니다.":
          return "자기 자신은 등록할 수 없습니다.";
        case "올바른 지갑주소가 아닙니다.":
          return "올바른 지갑주소가 아닙니다.";
        case "잔액이 부족합니다.":
          return "잔액이 부족합니다.";
        case "잘못된 요청입니다.":
          return "잘못된 요청입니다.";
        case "인증에 실패하였습니다. 재로그인 해주세요.":
          return "인증에 실패하였습니다. 재로그인 해주세요.";
        case "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.":
          return "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.";
        case "비밀번호가 변경되었습니다. 다시 로그인해주세요.":
          return "비밀번호가 변경되었습니다. 다시 로그인해주세요.";
        case "두 비밀번호가 맞지 않습니다.":
          return "두 비밀번호가 맞지 않습니다.";
        case "보안을 위해 로그아웃합니다. 다시 로그인해주세요.":
          return "보안을 위해 로그아웃합니다. 다시 로그인해주세요.";
        case "전송 오류입니다.":
          return "전송 오류입니다.";
        case "트랜잭션 전송 완료.":
          return "트랜잭션 전송 완료.";
        case "시간 초과입니다. 처음부터 다시 시도해주세요.":
          return "시간 초과입니다. 처음부터 다시 시도해주세요.";
        case "존재하지 않는 E-Mail입니다.":
          return "존재하지 않는 E-Mail입니다.";
        case "잘못된 코드입니다.":
          return "잘못된 코드입니다.";
        case "휴대폰 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "휴대폰 인증이 완료되었습니다. 지갑정보를 확인하세요.";
        case "휴대폰 인증이 완료되었습니다.":
          return "휴대폰 인증이 완료되었습니다.";
        case "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.";
        case "메일 인증이 완료되었습니다.":
          return "메일 인증이 완료되었습니다.";
        case "비밀번호 변경이 완료되었습니다.":
          return "비밀번호 변경이 완료되었습니다.";
        case "지갑 주소가 복사되었습니다.":
          return "지갑 주소가 복사되었습니다.";
        case "개인 키가 복사되었습니다.":
          return "개인 키가 복사되었습니다.";
        case "인증 번호를 전송했습니다.":
          return "인증 번호를 전송했습니다.";
        case "5분 안에 이메일에 전송된 코드를 입력하세요.":
          return "5분 안에 이메일에 전송된 코드를 입력하세요.";
        case "5분 안에 휴대폰에 전송된 코드를 입력하세요.":
          return "5분 안에 휴대폰에 전송된 코드를 입력하세요.";
        case "중복된 E-Mail입니다.":
          return "중복된 E-Mail입니다.";
        case "존재하지 않는 추천인 코드입니다.":
          return "존재하지 않는 추천인 코드입니다.";
        case "잘못된 E-Mail 혹은 비밀번호입니다.":
          return "잘못된 E-Mail 혹은 비밀번호입니다.";
        case "당신의 지갑주소입니다. QR코드를 스캔하세요.":
          return "당신의 지갑주소입니다. QR코드를 스캔하세요.";
        case "개인 키를 클릭하여 복사하세요.":
          return "개인 키를 클릭하여 복사하세요.";
        case "개인 키는 반드시 별도로 저장해주세요.":
          return "개인 키는 반드시 별도로 저장해주세요.";
        case "* 서버에 저장되지 않습니다. *":
          return "* 서버에 저장되지 않습니다. *";
        case "지갑 주소를 클릭하여 복사하세요.":
          return "지갑 주소를 클릭하여 복사하세요.";
        case "인증 신청이 완료되었습니다.":
          return "인증 신청이 완료되었습니다.";
        case "신분증(여권, 면허증, 주민등록증)을 잘 보이게 들고 얼굴과 신분증이 함께 나오도록 사진을 찍어 올려주세요. 또한 주민등록번호 뒷자리와 같은 민감정보는 마스킹처리(포스트잇 등) 되어있지 않을 시":
          return "신분증(여권, 면허증, 주민등록증)을 잘 보이게 들고 얼굴과 신분증이 함께 나오도록 사진을 찍어 올려주세요. 또한 주민등록번호 뒷자리와 같은 민감정보는 마스킹처리(포스트잇 등) 되어있지 않을 시";
        case "인증이 거절될 수 있습니다.":
          return " 인증이 거절될 수 있습니다.";
        case "이미 신청되었습니다.":
          return "이미 신청되었습니다.";
        case "모든 항목을 입력해주세요.":
          return "모든 항목을 입력해주세요.";
        case "이미지를 등록해주세요.":
          return "이미지를 등록해주세요.";
        case "인증":
          return "인증";
        case "휴대폰 인증":
          return "휴대폰 인증";
        case "KYC 인증":
          return "KYC 인증";
        case "홈":
          return "홈";
        case "로그아웃":
          return "로그아웃";
        case "지갑":
          return "지갑";
        case "히스토리":
          return "히스토리";
        case "송금":
          return "송금";
        case "투자 내역으로 전환":
          return "투자 내역으로 전환";
        case "비밀번호 변경":
          return "비밀번호 변경";
        case "추천인 코드":
          return "추천인 코드";
        case "스테이킹":
          return "스테이킹";
        case "CAMP 홈페이지 바로가기":
          return "CAMP 홈페이지 바로가기";
        case "다음":
          return "다음";
        case "컨트랙트 호출":
          return "컨트랙트 호출";
        case "전송 유형":
          return "전송 유형";
        case "전화번호 인증":
          return "전화번호 인증";
        case "추천인 코드를 받았나요?":
          return "추천인 코드를 받았나요?";
        case "당신의 추천인 코드입니다.":
          return "당신의 추천인 코드입니다.";
        case "스테이킹할 토큰 갯수를 입력하세요.(최소 20만개)":
          return "스테이킹할 토큰 갯수를 입력하세요.(최소 20만개)";
        case "스테이킹":
          return "스테이킹";
        case "클립보드로 복사":
          return "클립보드로 복사";
        case "보낸 항목":
          return "보낸 항목";
        case "받은 항목":
          return "받은 항목";
        case "보낼 금액을 입력하세요.":
          return "보낼 금액을 입력하세요.";
        case "수수료를 선택하세요.":
          return "수수료를 선택하세요.";
        case "빠름":
          return "빠름";
        case "보통":
          return "보통";
        case "느림":
          return "느림";
        case "송금":
          return "송금";
        case "받는 사람의 주소를 입력하세요.":
          return "받는 사람의 주소를 입력하세요.";
        case "개인 키를 입력하세요.":
          return "개인 키를 입력하세요.";
        case "개인 키는 서버에 저장되지 않습니다.":
          return "개인 키는 서버에 저장되지 않습니다.";
        case "지갑 주소가 복사되었습니다.":
          return "지갑 주소가 복사되었습니다.";
        case "기록이 존재하지 않습니다.":
          return "기록이 존재하지 않습니다.";
        case "추천인 코드가 복사되었습니다.":
          return "추천인 코드가 복사되었습니다.";
        case "추천인 링크가 복사되었습니다.":
          return "추천인 링크가 복사되었습니다.";
        case "스테이킹 해제신청":
          return "스테이킹 해제신청";
        default:
          return "None";
      }
    case "english":
      switch (index) {
        case "OTP 인증번호를 입력하세요.":
          return "Enter the OTP authentication number.";

        case "올바른 OTP 인증번호가 아닙니다.":
          return "It is not a valid OTP authentication number.";
        case "OTP가 등록되었습니다.":
          return "OTP is registered.";
        case "잘못된 OTP 인증번호입니다.":
          return "Invalid OTP authentication number.";
        case "OTP가 해제되었습니다.":
          return "OTP is turned off.";
        case "OTP 키가 복사되었습니다.":
          return "The OTP key has been copied.";
        case "이미 OTP가 등록되어있습니다.":
          return "OTP is already registered.";
        case "OTP를 해제하시려면 OTP 인증번호를 입력해주세요.":
          return "To cancel OTP, enter the OTP authentication number.";
        case "OTP 인증번호 입력":
          return "Enter OTP authentication number";
        case "등록된 OTP가 없습니다. OTP를 등록하시겠습니까?":
          return "There is no registered OTP. Would you like to register for OTP?";
        case "OTP 등록 시 송금 인증에 비밀번호 대신 OTP가 사용됩니다.":
          return "When registering OTP, OTP is used instead of password for remittance authentication.";
        case "앱에 키를 등록해주세요.":
          return "Please register the key in the app.";
        case "앱에 해당 OTP 키를 입력해주세요.":
          return "Enter the corresponding OTP key in the app.";

        case "잘못된 비밀키입니다.":
          return "Invalid private key.";
        case "잔액":
          return "balance";
        case "비밀번호를 입력하세요.":
          return "비밀번호를 입력하세요.";
        case "올바른 지갑주소가 아닙니다.":
          return "This is not a valid wallet address.";
        case "지원하지 않는 플랫폼입니다.":
          return "Platform not supported.";
        case "모든 항목을 입력해주세요.":
          return "Please enter all items.";
        case "이미지를 등록해주세요.":
          return "Please register the image.";
        case "이미 신청되었습니다.":
          return "It has already been applied.";
        case "신분증(여권, 면허증, 주민등록증)을 잘 보이게 들고 얼굴과 신분증이 함께 나오도록 사진을 찍어 올려주세요. 또한 주민등록번호 뒷자리와 같은 민감정보는 마스킹처리(포스트잇 등) 되어있지 않을 시":
          return "Please hold your ID card (passport, license, resident registration card) and take a picture to show your face and ID card. Sensitive information such as the back seat of resident registration number is not masked (post-it, etc.)";
        case "인증이 거절될 수 있습니다.":
          return " Authentication may be rejected.";
        case "인증 신청이 완료되었습니다.":
          return "Your certification application is complete.";
        case "이미 전화번호 인증이 완료된 계정입니다.":
          return "The account has already been authenticated to the phone number.";
        case "이미 인증된 계정입니다.":
          return "This account is already authenticated.";
        case "이미 등록된 전화번호입니다.":
          return "The phone number is already registered.";
        case "올바른 전화번호가 아닙니다.":
          return "This is not a valid phone number.";
        case "성공적으로 처리되었습니다.":
          return "Successfully processed.";
        case "최소 수량은 200,000개 입니다.":
          return "The minimum quantity is 200,000.";
        case "서버 오류입니다. 잠시 후에 다시 시도해주세요.":
          return "Server error. Please try again later.";
        case "추천인 코드를 등록했습니다.":
          return "Code registered.";
        case "이미 등록된 추천인 코드가 있습니다.":
          return "there is already a registered code.";
        case "자기 자신은 등록할 수 없습니다.":
          return "You cannot register yourself.";
        case "잔액이 부족합니다.":
          return "There is not enough balance.";
        case "잘못된 요청입니다.":
          return "Invalid request.";
        case "인증에 실패하였습니다. 재로그인 해주세요.":
          return "Authentication failed. Please log in again.";
        case "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.":
          return "Invalid password. Please check and try again.";
        case "비밀번호가 변경되었습니다. 다시 로그인해주세요.":
          return "Your password has been changed. Please sign in again.";
        case "두 비밀번호가 맞지 않습니다.":
          return "The passwords do not match.";
        case "보안을 위해 로그아웃합니다. 다시 로그인해주세요.":
          return "Log out for security. Please login again.";
        case "전송 오류입니다.":
          return "Transfer error.";
        case "트랜잭션 전송 완료.":
          return "Transaction Transfer Completed.";
        case "시간 초과입니다. 처음부터 다시 시도해주세요.":
          return "Timeout. Please try again from the beginning.";
        case "존재하지 않는 E-Mail입니다.":
          return "Email that does not exist.";
        case "잘못된 코드입니다.":
          return "Invalid code.";
        case "휴대폰 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "Phone authentication completed. Please check your wallet information.";
        case "휴대폰 인증이 완료되었습니다.":
          return "Phone authentication completed.";
        case "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "Mail authentication completed. Please check your wallet information.";
        case "메일 인증이 완료되었습니다.":
          return "Mail authentication completed.";
        case "비밀번호 변경이 완료되었습니다.":
          return "Password change completed.";
        case "지갑 주소가 복사되었습니다.":
          return "Your wallet address has been copied.";
        case "개인 키가 복사되었습니다.":
          return "Your private key has been copied.";
        case "인증 번호를 전송했습니다.":
          return "The authentication number has been successfully.";
        case "5분 안에 이메일에 전송된 코드를 입력하세요.":
          return "Please enter the code sent in the email within 5 minutes.";
        case "5분 안에 휴대폰에 전송된 코드를 입력하세요.":
          return "Please enter the code sent in the phone within 5 minutes.";
        case "중복된 E-Mail입니다.":
          return "Duplicate email.";
        case "존재하지 않는 추천인 코드입니다.":
          return "This is a non-existent recommendation.";
        case "잘못된 E-Mail 혹은 비밀번호입니다.":
          return "Invalid email or password.";
        case "당신의 지갑주소입니다. QR코드를 스캔하세요.":
          return "This is your wallet address. Scan the QR code.";
        case "개인 키를 클릭하여 복사하세요.":
          return "Click on the private key to copy it.";
        case "개인 키는 반드시 별도로 저장해주세요.":
          return "Please save your personal key separately.";
        case "* 서버에 저장되지 않습니다. *":
          return "* It is not stored on the server. *";
        case "지갑 주소를 클릭하여 복사하세요.":
          return "Click on your wallet address to copy it.";
        case "홈":
          return "Home";
        case "인증":
          return "Authentication";
        case "휴대폰 인증":
          return "Phone auth";
        case "KYC 인증":
          return "KYC auth";
        case "로그아웃":
          return "Logout";
        case "지갑":
          return "Wallet";
        case "히스토리":
          return "History";
        case "송금":
          return "Transfer";
        case "투자 내역으로 전환":
          return "Investment details";
        case "비밀번호 변경":
          return "Change Password";
        case "추천인 코드":
          return "Referral Code";
        case "스테이킹":
          return "Staking";
        case "CAMP 홈페이지 바로가기":
          return "Camp Shortcuts";
        case "다음":
          return "Next";
        case "컨트랙트 호출":
          return "Contract Call";
        case "전송 유형":
          return "Transfer type";
        case "전화번호 인증":
          return "Phone authentication";
        case "추천인 코드를 받았나요?":
          return "Received a referral code?";
        case "당신의 추천인 코드입니다.":
          return "Your code";
        case "스테이킹할 토큰 갯수를 입력하세요.(최소 20만개)":
          return "Please enter the number of tokens to be staked (at least 200,000)";
        case "스테이킹":
          return "Staking";
        case "클립보드로 복사":
          return "Copy to Clipboard";
        case "보낸 항목":
          return "Send";
        case "받은 항목":
          return "Receive";
        case "보낼 금액을 입력하세요.":
          return "Please enter the amount to send.";
        case "수수료를 선택하세요.":
          return "Please select a fee.";
        case "빠름":
          return "Fast";
        case "보통":
          return "Average";
        case "느림":
          return "Slow";
        case "송금":
          return "Transfer";
        case "받는 사람의 주소를 입력하세요.":
          return "Please enter the recipient's address.";
        case "개인 키를 입력하세요.":
          return "Please enter your private key.";
        case "개인 키는 서버에 저장되지 않습니다.":
          return "The private key is not stored on the server.";
        case "지갑 주소가 복사되었습니다.":
          return "Your wallet address has been copied.";
        case "기록이 존재하지 않습니다.":
          return "Recording does not exist.";
        case "추천인 코드가 복사되었습니다.":
          return "Your referral code has been copied.";
        case "추천인 링크가 복사되었습니다.":
          return "Your referral link has been copied.";
        case "스테이킹 해제신청":
          return "Request for Stake Release";
        default:
          return "None";
      }

    default:
      return "None";
  }
};
