export const HOST = "https://wallet.camp-global.com:2053";
export const NEW_CONTRACT_ADDRESS = "0xceca64909127918c80c7c76b503de7306d63eca7";
export const CONTRACT_ADDRESS = [{ address: "0xE9E73E1aE76D17A16cC53E3e87a9a7dA78834d37", name: "camp" }];
export const NODE_ENDPOINT = "https://mainnet.infura.io/v3/2fce53b1faae49bcba6fcdc39fbe48ce";
export const CHAIN_NAME = "mainnet";
export const CLIENT_HOST = "https://wallet.camp-global.com/register";
export const CHAIN_ID = 1;
export const ETHERSCAN_API_ENDPOINT = ({ address, page, offset, sort }) =>
  `https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=${CONTRACT_ADDRESS[0].address}&address=${address}&page=${page}&offset=${offset}&sort=${sort}&apikey=UFPCCE57R2KKXMYWI9SDCQD8P18QWGYF42`;
export const ETHERSCAN_API_ENDPOINT_ETH = ({ address, page, offset, sort }) =>
  `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=${sort}&page=${page}&offset=${offset}&apikey=UFPCCE57R2KKXMYWI9SDCQD8P18QWGYF42`;
export const ETHERSCAN_TX_URL = `https://etherscan.io/tx/`;

/**
 *
 */

// export const HOST = "http://localhost:5413";
// export const NEW_CONTRACT_ADDRESS = "0x7731172bb5CB7BE3e35358904d0A98c61738cdd0";
// export const CONTRACT_ADDRESS = [{ address: "0x7731172bb5CB7BE3e35358904d0A98c61738cdd0", name: "camp" }];
// export const CLIENT_HOST = "http://localhost:3000/register";
// export const CHAIN_ID = 3;
// export const NODE_ENDPOINT = "https://ropsten.infura.io/v3/2fce53b1faae49bcba6fcdc39fbe48ce";
// export const CHAIN_NAME = "ropsten";
// export const ETHERSCAN_API_ENDPOINT_ETH = ({ address, page, offset, sort }) =>
//   `https://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=${sort}&page=${page}&offset=${offset}&apikey=UFPCCE57R2KKXMYWI9SDCQD8P18QWGYF42`;

// export const ETHERSCAN_API_ENDPOINT = ({ address, page, offset, sort }) =>
//   `https://api-ropsten.etherscan.io/api?module=account&action=tokentx&contractaddress=${CONTRACT_ADDRESS[0].address}&address=${address}&page=${page}&offset=${offset}&sort=${sort}&apikey=UFPCCE57R2KKXMYWI9SDCQD8P18QWGYF42`;
// export const ETHERSCAN_TX_URL = `https://ropsten.etherscan.io/tx/`;

export const firebaseConfig = {
  apiKey: "AIzaSyDB3j2wwgiKwlJcL8SW6nxZSDoHKnJboPE",
  authDomain: "camp-263305.firebaseapp.com",
  projectId: "camp-263305",
};

export const GAS_PRICE_API = "https://ethgasstation.info/json/ethgasAPI.json";
export const PAGE_UNIT = 5;
export const ADMIN_PAGE_UNIT = 15;
export const SECOND = 1000;
export const MINUTE = 60;

export const AMOUNT_REGEX = /^[]?\d*(\.?\d*)$/;
export const NUMBER_REGEX = /^[0-9]+$/;
