import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

import useInput from "../../../Hooks/useInput";
import { languagePack } from "../../../Utils/language";
import Theme from "../../../Styles/Theme";
import GlobalContext from "../../../Hooks/globalContext";
import { SquareIcon } from "../../../Components/Common/Icons";
import Input from "../../../Components/Common/Input";
import useState from "../../../Hooks/useState";
import DropZone from "../../../Components/Common/DropZone";
import { HOST } from "../../../Utils/constants";
import Loader from "../../../Components/Admin/Loader";
import PageWrapper from "../../../Components/Common/PageWrapper";

const AuthenticationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const AuthBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Title = styled.span`
  margin-left: 10px;
`;

const ImageLabel = styled.div`
  font-size: 13px;
  margin-top: 15px;
`;

const InputWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;
`;

const ImageNoti = styled.span`
  font-weight: bold;
`;

const Page = () => {
  const { user, language, httpHeader } = useContext(GlobalContext);
  const status = useState(null);
  const statusType = useState(null);
  const step = useState(0);
  const singleFile = useState(null);
  const isLoading = useState(false);
  const reason = useState(null);
  const nameInput = useInput({ defaultValue: "", maxLength: 25 });
  const addressInput = useInput({ defaultValue: "", maxLength: 100 });
  const buttonOnClick = async () => {
    switch (step.state) {
      case 0:
        if (nameInput.value === "" || addressInput.value === "") {
          toast.error(languagePack("모든 항목을 입력해주세요.", language.state), {
            toastId: "step",
          });
          return;
        }
        step.setState(1);
        break;
      case 1:
        if (!singleFile.state) {
          toast.error(languagePack("이미지를 등록해주세요.", language.state), {
            toastId: "step",
          });
          return;
        }
        const frm = new FormData();
        frm.append("address", addressInput.value);
        frm.append("name", nameInput.value);
        frm.append("data", singleFile.state);

        const _header = httpHeader();
        _header.headers["Content-Type"] = "multipart/form-data";

        const { data } = await axios.post(`${HOST}/auth/kyc`, frm, _header);

        if (data.status) {
          toast.success(languagePack("인증 신청이 완료되었습니다.", language.state), {
            toastId: "step",
          });
        } else {
          if (data.code) {
            toast.error(languagePack("이미 신청되었습니다.", language.state), {
              toastId: "step",
            });
          } else {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "step",
            });
          }
        }

        getStatus();

        break;
    }
  };
  const getStatus = async () => {
    const { data } = await axios.post(`${HOST}/auth/kyc/status`, {}, httpHeader());

    if (data.reason) {
      reason.setState(data.reason);
    }

    if (!data.status) {
      statusType.setState(data.code);
    }
    status.setState(data.status);
  };
  const preload = () => {
    getStatus();
  };

  useEffect(preload, []);

  return (
    <PageWrapper>
      <AuthenticationWrapper>
        {status.state === null ? (
          <Loader type="ClipLoader" />
        ) : status.state ? (
          <>
            <AuthBox>
              <SquareIcon color={Theme.mainColor} size={6} />
              <Title>{`${languagePack("KYC 인증", language.state)} - ${step.state + 1}`}</Title>
            </AuthBox>
            {step.state === 0 && (
              <>
                <InputWrapper>
                  <Input placeholder="Name" {...nameInput} />
                </InputWrapper>
                <InputWrapper>
                  <Input placeholder="Address" style={{ marginTop: 0 }} {...addressInput} />
                </InputWrapper>
              </>
            )}

            {step.state === 1 && (
              <>
                <ImageLabel>
                  {languagePack(
                    "신분증(여권, 면허증, 주민등록증)을 잘 보이게 들고 얼굴과 신분증이 함께 나오도록 사진을 찍어 올려주세요. 또한 주민등록번호 뒷자리와 같은 민감정보는 마스킹처리(포스트잇 등) 되어있지 않을 시",
                    language.state
                  )}
                  <ImageNoti>{languagePack("인증이 거절될 수 있습니다.", language.state)}</ImageNoti>
                </ImageLabel>
                <DropZone singleFile={singleFile} />
              </>
            )}

            <Button
              style={{ marginBottom: 25, border: 0, borderRadius: 0, backgroundColor: Theme.mainColor, width: 150 }}
              type="button"
              size="sm"
              onClick={buttonOnClick}
              disabled={isLoading.state}
            >
              {languagePack("다음", language.state)}
            </Button>
            {reason.state && (
              <div style={{ textAlign: "center" }}>
                <div style={{ color: "red", fontWeight: "bold" }}>최근 신청한 KYC 인증의 거절 사유입니다.</div>
                <div style={{ marginTop: 12, fontSize: 13 }}>{reason.state}</div>
              </div>
            )}
          </>
        ) : statusType.state === "request" ? (
          <>{languagePack("이미 신청되었습니다.", language.state)}</>
        ) : (
          <>{languagePack("이미 인증된 계정입니다.", language.state)}</>
        )}
      </AuthenticationWrapper>
    </PageWrapper>
  );
};

export default Page;
