import React from "react";
import styled from "styled-components";

import LogoHorizontal from "../../Resources/Images/header_logo2.png";

const Logo = styled.img`
  width: 171px;
  height: 48px;
`;

const SideBar = styled.div`
  background-color: ${(props) => props.theme.navy};
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 5px 2.5px ${(props) => props.theme.darkGrey};
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  padding: 10px;
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 15px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.35;
  }
`;

const FocusedMenuItem = styled(MenuItem)`
  opacity: 0.35;
`;

const AdminSideBar = ({ page }) => {
  const MenuFactory = ({ text = "None", pageName = null }) => {
    const menuOnClick = () => {
      page.setState(pageName);
    };
    return page.state === pageName ? (
      <FocusedMenuItem onClick={menuOnClick}>{text}</FocusedMenuItem>
    ) : (
      <MenuItem onClick={menuOnClick}>{text}</MenuItem>
    );
  };

  return (
    <SideBar>
      <LogoWrapper>
        <Logo src={LogoHorizontal} />
      </LogoWrapper>
      <MenuFactory pageName={"userList"} text={"유저 목록"} />
      <MenuFactory pageName={"stake"} text={"스테이킹 관리"} />
      <MenuFactory pageName={"reward"} text={"보상 관리"} />
      <MenuFactory pageName={"timeLockForm"} text={"토큰 타임 락"} />
      <MenuFactory pageName={"lockList"} text={"자체 락업 로그"} />
      <MenuFactory pageName={"mintForm"} text={"추가 발행"} />
      <MenuFactory pageName={"burnForm"} text={"토큰 소각"} />
      <MenuFactory pageName={"airdrop"} text={"에어드롭 관리"} />
      <MenuFactory pageName={"kyc"} text={"KYC 인증 관리"} />
      <MenuFactory pageName={"registerEvent"} text={"설정 관리"} />
    </SideBar>
  );
};

export default AdminSideBar;
