import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import Table from "rc-table";
import Paginate from "react-paginate";
import { toast } from "react-toastify";
import { Transaction as EthereumTx } from "ethereumjs-tx";
import Select from "react-select";

import DefaultButton from "../Common/DefaultButton";
import GlobalContext from "../../Hooks/globalContext";
import { HOST, ADMIN_PAGE_UNIT, CONTRACT_ADDRESS, CHAIN_NAME } from "../../Utils/constants";
import useState from "../../Hooks/useState";
import Loader from "./Loader";
import { dateConvertor } from "../../Utils/util";
import Theme from "../../Styles/Theme";

const Top = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bottom = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const UpdateButton = styled.div`
  background-color: cadetblue;
  color: white;
  padding: 5px;
  width: 50px;
  border-radius: 5px;
  text-align: center;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
  :first-child {
    margin-right: 10px;
  }
`;

const DeleteButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.red};
`;

const TableStyles = styled.div`
  padding: 1rem;
  width: 100%;

  ul {
    justify-content: center;
  }

  table {
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.darkGrey};
    width: 100%;
    font-size: 13px;
    button {
      border: 0;
      margin: 0;
      color: white;
      background-color: ${(props) => props.theme.mainColor};
      :first-child {
        margin-right: 5px;
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.mainDarkColor};
      }

      :focus {
        outline: none;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.lightGrey};
      }
    }

    th {
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const SelectWrapper = styled.div`
  width: 300px;
`;

const options = [
  {
    value: "user",
    label: "본인 에어드롭만 보기",
  },
  {
    value: "recommend",
    label: "추천인 에어드롭만 보기",
  },
  {
    value: "all",
    label: "모두 보기",
  },
];
const customStyles = {};
const BottomItem = styled.div``;

const Page = ({ airdropProps: { nonceState, contractState, privateKeyInput, gasSelectedState, gasState, web3 } }) => {
  const table = useState(null);
  const page = useState(null);
  const { httpHeader, user } = useContext(GlobalContext);
  const isLoading = useState(null);
  const selectedSort = useState(null);
  const nowPage = useState(0);

  const handleChange = (selectedOption) => {
    const { value } = selectedOption;
    selectedSort.setState(selectedOption);
    tableFactory({ selected: 0, init: true, sort: value });
  };

  const preload = () => {
    tableFactory({ selected: 0, init: true });
  };

  const tableFactory = async ({ selected, init = false, sort = null }) => {
    if (!sort && selectedSort.state) {
      sort = selectedSort.state.value;
    }
    nowPage.setState(selected);

    page.setState({ ...page.state, loading: true });
    const requestBody = { perPage: ADMIN_PAGE_UNIT, init, selected: selected + 1, sort };
    const { data } = await axios.post(`${HOST}/admin/airdrop`, requestBody, httpHeader());
    table.setState(data.select);
    page.setState({
      ...page.state,
      loading: false,
      pageCount: Math.ceil(data.count / ADMIN_PAGE_UNIT),
    });
  };

  const rejectAirdrop = async ({ id }) => {
    const confirm = { type: 0, id };
    const { data } = await axios.post(`${HOST}/admin/airdrop/crud`, confirm, httpHeader());
    tableFactory({ selected: nowPage.state });
    toast.success(`거절되었습니다.`, {
      autoClose: 2000,
    });
  };

  const confirmAirdrop = ({ amount, walletAddress, id }) => {
    if (isLoading.state) {
      return;
    }

    if (toast.isActive("action")) {
      return;
    }

    if (privateKeyInput.value === "" || privateKeyInput.value.length !== 64) {
      toast.error("올바른 비밀 키를 입력하세요.", {
        toastId: "action",
        autoClose: 2000,
      });
      return;
    }

    let gasPrice = 0;

    if (gasSelectedState.state.fastest) {
      gasPrice = gasState.state.fastest / 10;
    } else if (gasSelectedState.state.average) {
      gasPrice = gasState.state.average / 10;
    } else if (gasSelectedState.state.safeLow) {
      gasPrice = gasState.state.safeLow / 10;
    }
    const weiGasPrice = web3.utils.toWei(gasPrice.toString(), "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);
    const privateKey = Buffer.from(privateKeyInput.value, "hex");
    let contractData = {};

    contractData = contractState.state.methods
      .transfer(walletAddress, web3.utils.toWei(`${Number(amount)}`))
      .encodeABI();

    const rawTransaction = {
      from: user.state.address,
      gasPrice: hexGasPrice,
      gasLimit: web3.utils.toHex(210000),
      to: CONTRACT_ADDRESS,
      value: "0x0",
      data: contractData,
      nonce: web3.utils.toHex(nonceState.state),
    };

    const transaction = new EthereumTx(rawTransaction, { chain: CHAIN_NAME });

    try {
      transaction.sign(privateKey);
    } catch (error) {
      toast.error(`트랜잭션 오류입니다. 올바른 개인키를 입력하세요. : ${error}`, {
        autoClose: 4000,
      });
      return;
    }
    isLoading.setState(true);
    web3.eth.sendSignedTransaction("0x" + transaction.serialize().toString("hex"), async (error, hash) => {
      if (error) {
        toast.error(`트랜잭션 오류 : ${error}`, {
          autoClose: 4000,
        });
        isLoading.setState(false);
      } else {
        nonceState.setState(nonceState.state + 1);
        const confirm = { type: 1, id, hash };
        const { data } = await axios.post(`${HOST}/admin/airdrop/crud`, confirm, httpHeader());
        isLoading.setState(false);
        tableFactory({ selected: nowPage.state });
        toast.success(`에어드롭되었습니다.`, {
          autoClose: 2000,
        });
      }
    });
  };

  useEffect(preload, []);

  const airdropForm = [
    {
      title: "에어드롭 번호",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "에어드롭 타입",
      dataIndex: "type",
      key: "type",
      render: (item) => (
        <>
          {item === 0 && `가입 이벤트(본인)`}
          {item === 1 && `가입 이벤트(추천인)`}
        </>
      ),
    },
    {
      title: "에어드롭 개수",
      dataIndex: "amount",
      key: "amount",
      render: (item) => <>{Number(item)}</>,
    },
    {
      title: "신청 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <>{dateConvertor(new Date(item))}</>,
    },
    {
      title: "지급/거절일",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (item) => <>{item ? dateConvertor(new Date(item)) : "-"}</>,
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <>
          {item === 0 && `지급 대기`}
          {item === 1 && <span style={{ color: "blue" }}>지급됨</span>}
          {item === 2 && <span style={{ color: Theme.red }}>거절됨</span>}
        </>
      ),
    },
    {
      title: "관리",
      key: "admin",
      render: (item) => {
        return (
          <ButtonWrapper>
            {item.status === 0 && (
              <>
                <UpdateButton onClick={confirmAirdrop.bind(null, item)}>승인</UpdateButton>
                <DeleteButton onClick={rejectAirdrop.bind(null, item)}>거절</DeleteButton>
              </>
            )}
          </ButtonWrapper>
        );
      },
    },
  ];

  return table.state ? (
    <>
      <Top>
        <SelectWrapper>
          <Select
            onChange={handleChange}
            isClearable={false}
            isSearchable={false}
            styles={customStyles}
            placeholder={"보기 유형"}
            options={options}
          />
        </SelectWrapper>
        <TableStyles>
          <Table rowKey={({ id }) => id} columns={airdropForm} data={table.state} />
        </TableStyles>
      </Top>
      <Bottom>
        <BottomItem></BottomItem>
        <BottomItem>
          {page.state && (
            <Paginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={page.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={tableFactory}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </BottomItem>
        <BottomItem></BottomItem>
      </Bottom>
    </>
  ) : (
    <Loader />
  );
};

export default Page;
