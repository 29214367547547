import React, { useContext } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import Popup from "reactjs-popup";

import Logo from "../../Resources/Images/logo2.png";
import { EthereumIcon, RefreshIcon } from "../Common/Icons";
import { useHistory } from "react-router-dom";
import globalContext from "../../Hooks/globalContext";

const ElementSymbol = styled.div`
  width: 64px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ElementRoot = styled.div`
  display: flex;
  align-items: center;
`;

const ElementTop = styled(ElementRoot)`
  height: 60%;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;

const ElementBottom = styled(ElementRoot)`
  height: 40%;
  color: #747474;
  font-size: 12px;
  justify-content: space-between;
`;

const ElementRight = styled.div`
  padding: 10px;
  width: calc(100% - 64px);
  height: 100%;
`;

const BottomElement = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
`;

const SymbolImage = styled.img`
  width: 46px;
  height: 45px;
`;

const SelectBox = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectItem = styled.div`
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.mainDarkColor};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  :hover {
    opacity: 0.75;
    cursor: pointer;
  }
  :first-child {
    margin-bottom: 15px;
  }
`;

const Container = ({ symbol, fullName, balance }) => {
  const history = useHistory();
  const { getBalance } = useContext(globalContext);

  const onClick = () => {
    getBalance(symbol.toLowerCase());
  };

  return (
    <BottomElement>
      <Popup
        trigger={
          <ElementSymbol>
            {symbol === "ETH" && <EthereumIcon size={54} />}
            {symbol === "CAMP" && <SymbolImage src={Logo} />}
          </ElementSymbol>
        }
        modal={true}
        contentStyle={{
          padding: 25,
          minWidth: 256,
        }}
      >
        {
          <SelectBox>
            <SelectItem
              onClick={() => {
                history.push(symbol === "ETH" ? "/history/eth" : symbol === "CAMP " ? "/history" : "/history/camp");
              }}
            >
              History
            </SelectItem>
            <SelectItem
              onClick={() => {
                history.push("/transfer");
              }}
            >
              Transfer
            </SelectItem>
          </SelectBox>
        }
      </Popup>
      <ElementRight>
        <ElementTop>
          <span>{symbol}</span>
          {balance && <RefreshIcon onClick={onClick} size={18} />}
        </ElementTop>
        <ElementBottom>
          <span>{fullName}</span>
          {balance !== 0 && !balance ? (
            <ClipLoader sizeUnit={"px"} size={15} color={"#747474"} />
          ) : (
            <span style={{ fontSize: 17 }}>{`${balance}`}</span>
          )}
        </ElementBottom>
      </ElementRight>
    </BottomElement>
  );
};

export default Container;
