import React, { useContext } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipLoader } from "react-spinners";
import { Button } from "reactstrap";

import { QrCodeIcon, CloseIcon } from "../Common/Icons";
import Theme from "../../Styles/Theme";
import { languagePack } from "../../Utils/language";
import globalContext from "../../Hooks/globalContext";

const InfoBox = styled.div`
  margin: 10px 0px;
  display: flex;
  position: relative;
  width: 95%;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
  height: 120px;
  min-height: 120px;
  padding: 25px;
  background-color: ${(props) => props.theme.navy};
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
`;

const InfoTitle = styled.div`
  font-size: 21px;
  color: ${(props) => props.theme.mainColor};
`;
const Address = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.mainColor};
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddressBox = styled.div`
  margin: 15px 0px;
  padding: 15px 0px;
  text-align: center;
  white-space: normal;
  word-break: break-all;
  width: 100%;
`;

const PopupBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  cursor: pointer;
`;

const MyWalletInfo = ({ user }) => {
  const { language } = useContext(globalContext);

  const onCopy = ({ copy, close }) => {
    if (toast.isActive("copy")) {
      return;
    }
    close();
    toast.info(languagePack("지갑 주소가 복사되었습니다.", language.state), {
      toastId: "copy",
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  return (
    <Popup
      trigger={
        <InfoBox>
          <QrCodeIcon size={20} color={Theme.mainColor} />
          <InfoTitle>My Camp-wallet</InfoTitle>
          <Address>{user.state.address}</Address>
        </InfoBox>
      }
      modal={true}
      contentStyle={{
        padding: 25,
        minWidth: 256,
      }}
    >
      {(close) => (
        <PopupBox>
          <CloseBox>
            <CloseIcon color={Theme.darkGrey} onClick={close} />
          </CloseBox>
          {user.state.address ? (
            <>
              <QRCode value={user.state.address} size={192} />
              <AddressBox>{user.state.address}</AddressBox>
              <CopyToClipboard text={user.state.address} onCopy={onCopy.bind(null, { copy: "address", close })}>
                <Button style={{ backgroundColor: Theme.mainColor, border: 0 }} type="button">
                  {languagePack("클립보드로 복사", language.state)}
                </Button>
              </CopyToClipboard>
            </>
          ) : (
            <LoaderWrapper>
              <ClipLoader sizeUnit={"px"} size={30} color={"#747474"} />
            </LoaderWrapper>
          )}
        </PopupBox>
      )}
    </Popup>
  );
};

export default MyWalletInfo;
