import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as firebase from "firebase/app";
import "firebase/auth";

import Namemark from "../../Resources/Images/namemark.png";
import { HOST, NUMBER_REGEX, firebaseConfig } from "../../Utils/constants";
import { languageSelect, languagePack } from "../../Utils/language";
import GlobalContext from "../../Hooks/globalContext";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import Input from "../../Components/Common/Input";
import Theme from "../../Styles/Theme";

const Recaptcha = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  background-color: ${(props) => props.theme.bgBlackColor};
`;

const AuthWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10%;

  input {
    margin-bottom: 15px;
  }
  button {
    margin: 5px 0px;
  }
`;

const NotificationSpan = styled.span`
  font-size: 15px;
  font-weight: 500;
  padding: 7.5px 0px;
  color: white;
  text-align: center;
`;

const WalletBox = styled.pre`
  width: 100%;
  min-height: 50px;
  word-break: break-all;
  white-space: normal;
  text-align: center;
  background-color: #eaeaea;
  cursor: pointer;
  :hover {
    background-color: #bdbdbd;
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;
`;

const LinkSpan = styled(Link)`
  color: ${(props) => props.theme.lightGrey};
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: grey;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const PaddingSpan = styled.span`
  padding-bottom: 10px;
  font-size: 13px;
  color: white;
`;

const IntroTitle = styled.span`
  font-size: 26px;
  color: white;
`;

const IntroButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  background-color: ${(props) => props.theme.mainColor};
  font-size: 20px;
  color: white;
  padding: 10px;
  border-radius: 10px;
  :hover {
    opacity: 0.75;
  }
  cursor: pointer;
`;

const LengthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.mainColor};
  font-size: 16px;
  color: white;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  :hover {
    opacity: 0.75;
  }
  :focus {
    outline: none;
  }
  cursor: pointer;
`;

const IntroBoundary = styled.div`
  width: 100%;
  border: 0.1px solid ${(props) => props.theme.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px 0px;
  opacity: 0.5;
  position: relative;
`;

const IntroOrSpan = styled.div`
  color: ${(props) => props.theme.darkGrey};
  padding: 5px 10px;
  background-color: ${(props) => props.theme.bgBlackColor};
  position: absolute;
  font-size: 20px;
  bottom: -17.5px;
`;

const Div = styled.div`
  width: 100%;
`;

const AuthHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0px;
  padding: 0px 15px;
  background-color: ${(props) => props.theme.black};
  height: 50px;
`;

const LanguageSelect = styled.select`
  color: white;
  border: 0;
  :focus {
    outline: none;
  }
  background-size: 10px;
  background-color: ${(props) => props.theme.navy};
  padding: 5px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 17px;
  border-radius: 20px;
`;

const Clipboard = styled(CopyToClipboard)`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: ${(props) => props.theme.darkGrey};
  margin: 0;
  margin-bottom: 5px;
`;

/** Container  */

const Container = ({ history, match, page }) => {
  const { isLogin, language, httpHeader } = useContext(GlobalContext);
  const walletState = useState();
  const emailInput = useInput({ defaultValue: "" });
  const passwordInput = useInput({ defaultValue: "" });
  const passwordCheckInput = useInput({ defaultValue: "" });
  const tokenInput = useInput({ defaultValue: "" });
  const nameInput = useInput({ defaultValue: "" });
  const phoneInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const verifyCodeInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const recommendInput = useInput({ defaultValue: "" });
  const pagePermit = useState(false);
  const loginVerifyState = useState(false);
  const phoneIsNull = useState(false);
  const recaptcha = useRef(null);

  if (page === "wallet" && !walletState.state) {
    history.push("/login");
  }

  if (page === "login/verify" && !loginVerifyState.state) {
    history.push("/login");
  }

  if (page === "login/verify/phone" && !loginVerifyState.state) {
    history.push("/login");
  }

  if (page === "verify" && !pagePermit.state) {
    history.push("/login");
  }

  const inputClean = () => {
    emailInput.setValue("");
    passwordInput.setValue("");
    passwordCheckInput.setValue("");
    nameInput.setValue("");
    recommendInput.setValue("");
  };

  const sendToken = async () => {
    if (phoneInput.value) {
      if (phoneInput.value === "" || phoneInput.value.length !== 11) {
        toast.error(languagePack("올바른 전화번호가 아닙니다.", language.state), { toastId: "phone" });
        return;
      }
    }

    const { data } = await Axios.post(
      `${HOST}/register/phone/send`,
      { email: emailInput.value, phone: phoneInput.value },
      httpHeader()
    );
    toast.info(languagePack("5분 안에 휴대폰에 전송된 코드를 입력하세요.", language.state), {
      toastId: "login",
    });
  };

  const AuthOnSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    switch (page) {
      case "login":
        if (toast.isActive("login")) {
          return;
        }
        const loginParams = {
          email: emailInput.value,
          password: passwordInput.value,
        };

        Axios.post(`${HOST}/login`, loginParams, config)
          .then((response) => {
            if (response.data.status) {
              isLogin.setState(true);
              localStorage.setItem("token", response.data.token);
            } else {
              switch (response.data.code) {
                // case "phone":
                //   Axios.post(`${HOST}/register/phone/send`, { email: emailInput.value }, config).then(response => {
                //     phoneInput.setValue(response.data.phone);
                //     loginVerifyState.setState(true);
                //     toast.info(languagePack("5분 안에 휴대폰에 전송된 코드를 입력하세요.", language.state), {
                //       toastId: "login"
                //     });
                //     history.push("/login/verify/phone");
                //   });
                //   break;
                // case "phoneIsNull":
                //   phoneIsNull.setState(true);
                //   loginVerifyState.setState(true);
                //   history.push("/login/verify/phone");
                //   break;
                case "email":
                  Axios.post(`${HOST}/auth/send`, { email: emailInput.value }, config).then((response) => {
                    loginVerifyState.setState(true);
                    toast.info(languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state), {
                      toastId: "login",
                    });
                    history.push("/login/verify");
                  });
                  break;
                case "invalid":
                  toast.error(languagePack("잘못된 E-Mail 혹은 비밀번호입니다.", language.state), {
                    toastId: "login",
                  });
                  break;

                case "server":
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "login",
                  });
                  break;

                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "login",
                  });
                  break;
              }
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "login",
            });
          });
        break;

      case "register":
        if (toast.isActive("register")) {
          return;
        }
        if (passwordInput.value !== passwordCheckInput.value) {
          toast.error(languagePack("두 비밀번호가 맞지 않습니다.", language.state), { toastId: "register" });
          return;
        }

        const regsiterParams = {
          email: emailInput.value,
          password: passwordInput.value,
          name: nameInput.value,
          recommend: recommendInput.value,
          phone: phoneInput.value,
        };
        Axios.post(`${HOST}/register`, regsiterParams, config)
          .then((response) => {
            if (response.data.status) {
              // loginVerifyState.setState(true);
              // toast.info(languagePack("5분 안에 휴대폰에 전송된 코드를 입력하세요.", language.state), {
              //   toastId: "register",
              //   position: toast.POSITION.BOTTOM_LEFT
              // });
              // history.push("/login/verify/phone");

              loginVerifyState.setState(true);
              toast.info(languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state), {
                toastId: "register",
                position: toast.POSITION.BOTTOM_LEFT,
              });
              history.push("/login/verify");
            } else {
              switch (response.data.code) {
                case "duplicate":
                  toast.error(languagePack("중복된 E-Mail입니다.", language.state), { toastId: "register" });
                  break;

                case "duplicatePhone":
                  toast.error(languagePack("이미 등록된 전화번호입니다.", language.state), {
                    toastId: "register",
                  });

                  break;
                case "invalid recommend":
                  toast.error(languagePack("존재하지 않는 추천인 코드입니다.", language.state), {
                    toastId: "register",
                  });
                  break;
                case "server":
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "register",
                  });
                  break;
                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "register",
                  });
                  break;
              }

              return;
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "register",
            });
          });

        break;

      case "forgot":
        if (toast.isActive("forgot")) {
          return;
        }

        const forgotParams = {
          email: emailInput.value,
        };
        Axios.post(`${HOST}/auth/send`, forgotParams, config)
          .then((response) => {
            if (response.data.status) {
              toast.info(languagePack("인증 번호를 전송했습니다.", language.state), { toastId: "forgot" });
              pagePermit.setState(true);
              history.push("/password/verify");
            } else {
              switch (response.data.code) {
                case "invalid":
                  toast.error(languagePack("존재하지 않는 E-Mail입니다.", language.state), {
                    toastId: "forgot",
                  });
                  break;
                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "forgot",
                  });
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "register",
            });
          });
        break;

      case "verify":
        if (toast.isActive("verify")) {
          return;
        }
        if (passwordInput.value !== passwordCheckInput.value) {
          toast.error(languagePack("두 비밀번호가 맞지 않습니다.", language.state), { toastId: "register" });
          return;
        }
        const verifyParams = {
          email: emailInput.value,
          password: passwordInput.value,
          token: tokenInput.value,
          type: "password",
        };

        Axios.post(`${HOST}/auth/verify`, verifyParams, config)
          .then((response) => {
            if (response.data.status) {
              toast.success(languagePack("비밀번호 변경이 완료되었습니다.", language.state), {
                toastId: "verify",
              });
              inputClean();
              history.replace("/login");
            } else {
              switch (response.data.code) {
                case "invalid":
                  toast.error(languagePack("존재하지 않는 E-Mail입니다.", language.state), {
                    toastId: "verify",
                  });
                  break;
                case "timeout":
                  toast.error(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state), {
                    toastId: "verify",
                  });
                  history.replace("/password/forgot");
                  break;
                case "token":
                  toast.error(languagePack("잘못된 코드입니다.", language.state), { toastId: "verify" });
                  break;
                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "verify",
                  });
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "register",
            });
          });
        break;
      case "login/verify/phone":
        if (phoneInput.value === "" || phoneInput.value.length !== 11) {
          toast.error(languagePack("올바른 전화번호가 아닙니다.", language.state), { toastId: "phone" });
          return;
        }

        if (tokenInput.value === "") {
          toast.error(languagePack("잘못된 코드입니다.", language.state), { toastId: "phone" });
          return;
        }

        const loginVerifyPhoneParams = {
          email: emailInput.value,
          token: tokenInput.value,
          type: "register",
        };
        Axios.post(`${HOST}/register/verify/phone`, loginVerifyPhoneParams, config)
          .then((response) => {
            if (response.data.status) {
              if (response.data.address) {
                walletState.setState({ address: response.data.address });
                inputClean();
                history.push("/register/wallet");
                toast.success(languagePack("휴대폰 인증이 완료되었습니다. 지갑정보를 확인하세요.", language.state), {
                  toastId: "verify/phone",
                });
              } else {
                inputClean();
                history.push("/login");
                toast.success(languagePack("휴대폰 인증이 완료되었습니다.", language.state), {
                  toastId: "verify/phone",
                });
              }
            } else {
              switch (response.data.code) {
                case "invalid":
                  toast.error(languagePack("올바른 전화번호가 아닙니다.", language.state), {
                    toastId: "verify/phone",
                  });
                  break;
                case "timeout":
                  toast.error(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state), {
                    toastId: "verify/phone",
                  });
                  history.replace("/login");
                  break;
                case "token":
                  toast.error(languagePack("잘못된 코드입니다.", language.state), { toastId: "verify" });
                  break;
                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "verify/phone",
                  });
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "register",
            });
          });
        break;
      case "login/verify":
        const loginVerifyParams = {
          email: emailInput.value,
          token: tokenInput.value,
          type: "register",
        };

        Axios.post(`${HOST}/auth/verify`, loginVerifyParams, config)
          .then((response) => {
            if (response.data.status) {
              if (response.data.address) {
                walletState.setState({ address: response.data.address });
                inputClean();
                history.push("/register/wallet");
                toast.success(languagePack("메일 인증이 완료되었습니다. 지갑정보를 확인하세요.", language.state), {
                  toastId: "verify/phone",
                });
              } else {
                inputClean();
                history.push("/login");
                toast.success(languagePack("메일 인증이 완료되었습니다.", language.state), {
                  toastId: "verify/phone",
                });
              }
            } else {
              switch (response.data.code) {
                case "invalid":
                  toast.error(languagePack("존재하지 않는 E-Mail입니다.", language.state), {
                    toastId: "verify",
                  });
                  break;
                case "timeout":
                  toast.error(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state), {
                    toastId: "verify",
                  });
                  history.replace("/login");
                  break;
                case "token":
                  toast.error(languagePack("잘못된 코드입니다.", language.state), { toastId: "verify" });
                  break;
                default:
                  toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                    toastId: "verify",
                  });
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "register",
            });
          });
        break;
      default:
        break;
    }
  };

  const languageOnChange = ({ target }) => {
    if (target.value !== "") {
      language.setState(target.value);
    }
  };

  const RegisterOnClick = () => {
    history.push("/register");
  };

  const LoginOnClick = () => {
    history.push("/login");
  };

  const onCopy = ({ copy }) => {
    if (toast.isActive("copy")) {
      return;
    }
    toast.info(
      copy === "address"
        ? languagePack("개인 키가 복사되었습니다.", language.state)
        : languagePack("지갑 주소가 복사되었습니다.", language.state),
      {
        toastId: "copy",
        position: toast.POSITION.BOTTOM_LEFT,
      }
    );
  };

  const preload = () => {
    if (match.params.recommend) {
      recommendInput.setValue(Buffer.from(match.params.recommend, "base64").toString());
    }
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptcha.current, {
        size: "invisible",
        callback: (response) => {},
        "expired-callback": () => {},
      });
      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  };

  useEffect(preload, [match.params]);

  return (
    <>
      <AuthHeader>
        <img style={{ width: 85, height: 25 }} src={Namemark} />
        <LanguageSelect onChange={languageOnChange}>
          <option value="">language</option>
          <option value="english">{languageSelect("English", language.state)}</option>
          <option value="korean">{languageSelect("한국어", language.state)}</option>
        </LanguageSelect>
      </AuthHeader>
      <Wrapper>
        <Recaptcha ref={recaptcha} />
        <AuthWrapper onSubmit={AuthOnSubmit}>
          {page === "intro" && (
            <>
              <Div style={{ marginBottom: 50, textAlign: "center" }}>
                <IntroTitle>Log into </IntroTitle>
                <IntroTitle style={{ color: Theme.mainColor }}>Camp-wallet</IntroTitle>
              </Div>
              <LengthWrapper>
                <IntroButton onClick={LoginOnClick}>Log In</IntroButton>
                <IntroBoundary>
                  <IntroOrSpan>or</IntroOrSpan>
                </IntroBoundary>
                <IntroButton onClick={RegisterOnClick} style={{ backgroundColor: Theme.mainDarkColor }}>
                  Create New Account
                </IntroButton>
              </LengthWrapper>
            </>
          )}
          {page === "login" && (
            <LengthWrapper>
              <Input placeholder="E-Mail" type="email" {...emailInput} />
              <Input placeholder="Password" type="password" {...passwordInput} />
              <AuthButton>Login</AuthButton>
              <LinkWrapper style={{ paddingBottom: 10 }}>
                <LinkSpan to="/password/forgot">Forgot password?</LinkSpan>
              </LinkWrapper>
              <LinkSpan to="/intro">Go back</LinkSpan>
            </LengthWrapper>
          )}
          {page === "register" && (
            <LengthWrapper>
              <Input placeholder="* E-Mail" type="email" {...emailInput} />
              <Input placeholder="* Password" type="password" {...passwordInput} />
              <Input placeholder="* Password Check" type="password" {...passwordCheckInput} />
              <Input placeholder="* Name" type="text" {...nameInput} />
              {/* <Input placeholder="Phone" type="text" {...phoneInput} required={false} /> */}
              <Input
                readOnly={!!match.params.recommend}
                placeholder="Referral code"
                type="text"
                {...recommendInput}
                required={false}
              />
              <AuthButton>{`Next`}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/">Go back</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "forgot" && (
            <LengthWrapper>
              <Input placeholder="E-Mail" type="email" {...emailInput} />
              <AuthButton>Verify</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">Go back</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "verify" && (
            <LengthWrapper>
              <Input placeholder="E-Mail" type="email" {...emailInput} />
              <Input placeholder="New Password" type="password" {...passwordInput} />
              <Input placeholder="Password Check" type="password" {...passwordCheckInput} />
              <InputLabel>
                <PaddingSpan>{languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state)}</PaddingSpan>
                <Input placeholder="Token" type="text" {...tokenInput} />
              </InputLabel>
              <AuthButton>Verify</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">Go back</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "login/verify" && (
            <LengthWrapper>
              <Input placeholder="E-Mail" type="email" {...emailInput} />
              <InputLabel>
                <PaddingSpan>{languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state)}</PaddingSpan>
                <Input placeholder="Token" type="text" {...tokenInput} />
              </InputLabel>
              <AuthButton>Verify</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">Go back</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "login/verify/phone" && (
            <LengthWrapper>
              <Input placeholder="Phone" type="text" {...phoneInput} />
              {phoneIsNull.state && (
                <AuthButton type="button" onClick={sendToken} style={{ padding: 5 }}>
                  Send
                </AuthButton>
              )}
              <InputLabel>
                <PaddingSpan>{languagePack("5분 안에 휴대폰에 전송된 코드를 입력하세요.", language.state)}</PaddingSpan>
                <Input placeholder="Token" type="text" {...tokenInput} />
              </InputLabel>
              <AuthButton>Confirm</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">Go back</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "wallet" && walletState.state && (
            <LengthWrapper>
              <NotificationSpan>
                {languagePack("당신의 지갑주소입니다. QR코드를 스캔하세요.", language.state)}
              </NotificationSpan>
              <QRCode value={walletState.state.address} size={180} />
              <NotificationSpan style={{ paddingBottom: 0 }}>
                {languagePack("지갑 주소를 클릭하여 복사하세요.", language.state)}
              </NotificationSpan>
              <Clipboard text={walletState.state.address} onCopy={onCopy.bind(null, { copy: "wallet" })}>
                <WalletBox>{walletState.state.address}</WalletBox>
              </Clipboard>
              <AuthButton type="button" onClick={LoginOnClick}>
                Go back
              </AuthButton>
            </LengthWrapper>
          )}
        </AuthWrapper>
      </Wrapper>
    </>
  );
};

{
  /* <PaddingSpan>
{languagePack("* 개인 키는 반드시 별도로 저장해주세요. *", language.state)}
</PaddingSpan> */
}
export default Container;
