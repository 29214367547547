import React from "react";
import styled from "styled-components";

const Hamburger = styled.svg`
  cursor: pointer;
  :hover {
    fill: gray;
  }
`;

const Refresh = styled.svg`
  cursor: pointer;
  :hover {
    fill: #4c4c4c;
  }
`;

const Close = styled.svg`
  cursor: pointer;
  :hover {
    fill: #d5d5d5;
  }
`;

const Rotate = styled.svg`
  transform: rotate(180deg);
`;

export const QrScannerIcon = ({ size = 24, color = "white", onClick = () => null }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M11 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1zm-2-10h4v4h2v-6h-6v2zm-16 4v-4h4v-2h-6v6h2zm4 16h-4v-4h-2v6h6v-2zm16-4v4h-4v2h6v-6h-2z" />
  </svg>
);

export const ArrowIcon = ({ size = 24, color = "grey", isReceived }) => (
  <>
    {isReceived ? (
      <Rotate width={size} height={size} fill={color} viewBox="0 0 24 24">
        <path d="M24 11.871l-5-4.871v3h-19v4h19v3z" />
      </Rotate>
    ) : (
      <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
        <path d="M24 11.871l-5-4.871v3h-19v4h19v3z" />
      </svg>
    )}
  </>
);

export const HomeIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z" />
  </svg>
);

export const WalletIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V16.72C21.59,16.37 22,15.74 22,15V9C22,8.26 21.59,7.63 21,7.28V5A2,2 0 0,0 19,3H5M5,5H19V7H13A2,2 0 0,0 11,9V15A2,2 0 0,0 13,17H19V19H5V5M13,9H20V15H13V9M16,10.5A1.5,1.5 0 0,0 14.5,12A1.5,1.5 0 0,0 16,13.5A1.5,1.5 0 0,0 17.5,12A1.5,1.5 0 0,0 16,10.5Z" />
  </svg>
);

export const HistoryIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M13.403 24h-13.403v-22h3c1.231 0 2.181-1.084 3-2h8c.821.916 1.772 2 3 2h3v9.15c-.485-.098-.987-.15-1.5-.15l-.5.016v-7.016h-4l-2 2h-3.897l-2.103-2h-4v18h9.866c.397.751.919 1.427 1.537 2zm5.097-11c3.035 0 5.5 2.464 5.5 5.5s-2.465 5.5-5.5 5.5c-3.036 0-5.5-2.464-5.5-5.5s2.464-5.5 5.5-5.5zm0 2c1.931 0 3.5 1.568 3.5 3.5s-1.569 3.5-3.5 3.5c-1.932 0-3.5-1.568-3.5-3.5s1.568-3.5 3.5-3.5zm2.5 4h-3v-3h1v2h2v1zm-15.151-4.052l-1.049-.984-.8.823 1.864 1.776 3.136-3.192-.815-.808-2.336 2.385zm6.151 1.052h-2v-1h2v1zm2-2h-4v-1h4v1zm-8.151-4.025l-1.049-.983-.8.823 1.864 1.776 3.136-3.192-.815-.808-2.336 2.384zm8.151 1.025h-4v-1h4v1zm0-2h-4v-1h4v1zm-5-6c0 .552.449 1 1 1 .553 0 1-.448 1-1s-.447-1-1-1c-.551 0-1 .448-1 1z" />
  </svg>
);

export const TransactionIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M24 3v10h-4.809l-2.134-2h4.943v-6h-12v-.384l-3.17 2.972 2.27 2.412h-2.747l-2.353-2.5 8-7.5v3h12zm-6.712 6.42c-.581 0-.936-.41-1.073-.92h1.119l.055-.334h-1.232l-.002-.332h1.291l.054-.334h-1.292c.131-.518.476-.938 1.025-.938.188 0 .398.033.64.104l.127-.527c-.309-.092-.603-.139-.873-.139-.994 0-1.591.691-1.766 1.5h-.361l-.055.334h.367l-.002.332h-.422l-.054.334h.523c.173.809.777 1.5 1.848 1.5.25 0 .501-.047.746-.137l-.147-.539c-.175.064-.348.096-.516.096zm2.712 7.08l-8-7.5v3h-12v9h12v3l8-7.5zm-13.332 2.062v.438h-.418v-.416c-.431-.008-.878-.11-1.25-.303l.19-.686c.398.155.928.319 1.343.226.479-.108.577-.601.048-.838-.389-.181-1.573-.336-1.573-1.352 0-.567.432-1.075 1.242-1.188v-.443h.418v.424c.301.008.64.061 1.017.176l-.15.687c-.319-.112-.674-.215-1.018-.193-.62.036-.676.573-.242.798.713.335 1.644.584 1.644 1.478 0 .714-.562 1.095-1.251 1.192z" />
  </svg>
);

export const EthereumIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M12,1.75L5.75,12.25L12,16L18.25,12.25L12,1.75M5.75,13.5L12,22.25L18.25,13.5L12,17.25L5.75,13.5Z" />
  </svg>
);

export const HamburgerIcon = ({ size = 24, color = "#1a1a1a", onClick }) => (
  <Hamburger width={size} height={size} fill={color} onClick={onClick} viewBox="0 0 24 24">
    <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
  </Hamburger>
);

export const RefreshIcon = ({ size = 24, color = "grey", onClick }) => (
  <Refresh width={size} height={size} fill={color} onClick={onClick} viewBox="0 0 24 24">
    <path d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
  </Refresh>
);

export const LockIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} onClick={onClick} viewBox="0 0 24 24">
    <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-5 7.723v2.277h-2v-2.277c-.595-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723zm-5-7.723v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8z" />
  </svg>
);

export const UnlockIcon = ({ size = 24, color = "grey", onClick }) => (
  <svg width={size} height={size} fill={color} onClick={onClick} viewBox="0 0 24 24">
    <path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12z" />
  </svg>
);

export const CloseIcon = ({ size = 24, color = "white", onClick }) => (
  <Close width={size} height={size} fill={color} onClick={onClick} viewBox="0 0 24 24">
    <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />{" "}
  </Close>
);

export const RightArrowIcon = ({ size = 24, color = "white" }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
);

export const QrCodeIcon = ({ size = 24, color = "white" }) => (
  <svg style={{ position: "absolute", top: 15, left: 25 }} width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M21 21v3h3v-3h-1v-1h-1v1h-1zm2 1v1h-1v-1h1zm-23 2h8v-8h-8v8zm1-7h6v6h-6v-6zm20 3v-1h1v1h-1zm-19-2h4v4h-4v-4zm8-3v2h-1v-2h1zm2-8h1v1h-1v-1zm1-1h1v1h-1v-1zm1 2v-1h1v1h-1zm0-2h1v-6h-3v1h-3v1h3v1h2v3zm-1-4v-1h1v1h-1zm-7 4h-4v-4h4v4zm6 0h-1v-2h-2v-1h3v1h1v1h-1v1zm-4-6h-8v8h8v-8zm-1 7h-6v-6h6v6zm3 0h-1v-1h2v2h-1v-1zm-3 3v1h-1v-1h1zm15 6h2v3h-1v-1h-2v1h-1v-1h-1v-1h1v-1h1v1h1v-1zm-4 2h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v1h-1v1h-2v1h-1v6h5v-1h-1v-2h-1v2h-2v-1h1v-1h-1v-1h3v-1h2v2h-1v1h1v2h1v-2h1v1h1v-1h1v-2h1v-1h-2v-1zm-1 3h-1v-1h1v1zm6-6v-2h1v2h-1zm-9 5v1h-1v-1h1zm5 3v-1h1v2h-2v-1h1zm-3-23v8h8v-8h-8zm7 7h-6v-6h6v6zm-1-1h-4v-4h4v4zm1 4h1v2h-1v1h-2v-4h1v2h1v-1zm-4 6v-3h1v3h-1zm-13-7v1h-2v1h1v1h-3v-1h1v-1h-2v1h-1v-2h6zm-1 4v-1h1v3h-1v-1h-1v1h-1v-1h-1v1h-2v-1h1v-1h4zm-4-1v1h-1v-1h1zm19-2h-1v-1h1v1zm-13 4h1v1h-1v-1zm15 2h-1v-1h1v1zm-5 1v-1h1v1h-1zm-1-1h1v-3h2v-1h1v-1h-1v-1h-2v-1h-1v1h-1v-1h-1v1h-1v-1h-1v1h-1v1h-1v-1h1v-1h-4v1h2v1h-2v1h1v2h1v-1h2v2h1v-4h1v2h3v1h-2v1h2v1zm1-5h1v1h-1v-1zm-2 1h-1v-1h1v1z" />
  </svg>
);

export const SquareIcon = ({ size = 24, color = "white" }) => (
  <svg width={size} height={size} fill={color} viewBox="0 0 24 24">
    <path d="M24 0h-24v24h24v-24z" />
  </svg>
);
