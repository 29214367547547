import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { BeatLoader, ClipLoader } from "react-spinners";
import Select from "react-select";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import Axios from "axios";

import { QrScannerIcon, EthereumIcon } from "../../Components/Common/Icons";
import GasSelector from "../../Components/Common/GasSelector";
import Input from "../../Components/Common/Input";
import AmountInput from "../../Components/Common/AmountInput";
import { languagePack } from "../../Utils/language";
import Theme from "../../Styles/Theme";
import Logo from "../../Resources/Images/logo2.png";
import GlobalContext from "../../Hooks/globalContext";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import {
  AMOUNT_REGEX,
  CHAIN_ID,
  CHAIN_NAME,
  CONTRACT_ADDRESS,
  ETHERSCAN_TX_URL,
  GAS_PRICE_API,
  HOST,
  NUMBER_REGEX,
} from "../../Utils/constants";
import { ToFixed, numberWithCommas } from "../../Utils/util";
import PageWrapper from "../../Components/Common/PageWrapper";

const TransferWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  :last-child {
    padding-top: 20px;
  }
`;

const Form = styled.div`
  margin-top: 3px;
  background-color: white;
`;

const QrForm = styled(Form)`
  position: relative;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;

  font-size: 14px;
  color: ${(props) => props.theme.mainColor};
  :hover {
    opacity: 0.75;
    text-decoration: underline;
  }
`;

const TransferInput = styled.input`
  border: 0;
  border-radius: 0;
  width: 100%;
  letter-spacing: 5px;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 20px;
  padding-right: 40px;
  text-align: right;
`;

const BlockInputWrapper = styled.div`
  width: 100%;
`;

const InputLabel = styled.div`
  font-size: 15px;
  font-weight: 800;
  width: 100%;
  text-align: center;
  margin-top: 10px;
`;

const PrivateKeySubTitle = styled.div`
  font-size: 13px;
  font-weight: 800;
  width: 100%;
  text-align: center;
  color: red;
  padding: 5px 0px;
`;

const PrivateKeyInput = styled(TransferInput)`
  letter-spacing: 0px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  padding-left: 40px;
`;

const Loader = styled.div`
  width: 100%;
  height: calc(100% - 170px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  -webkit-transition: width 0.25s, height 0.25s, background-color 0.25s, -webkit-transform 0.25s;
  transition: width 0.25s, height 0.25s, background-color 0.25s, transform 0.25s;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OptionSpan = styled.span`
  margin-left: 5px;
`;

const PasswordTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  font-size: 13px;
  margin-bottom: 15px;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    background-color: ${(props) => props.theme.lightGrey};
  }

  th {
    white-space: nowrap;
    border-bottom: 1px solid ${(props) => props.theme.darkGrey};
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid ${(props) => props.theme.darkGrey};
    :last-child {
      border-right: 0;
    }
  }
  td {
    margin: 0;
    padding: 0.5rem;
    white-space: nowrap;
    :last-child {
      border-right: 0;
    }
  }
`;

const Container = () => {
  const {
    user,
    qrData,
    httpHeader,
    language,
    balance,
    web3,
    pushToken,
    logoutOnSubmit,
    contract,
    toggleMain,
    getBalance,
  } = useContext(GlobalContext);
  const otpIsNull = useState(null);
  const otpInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const passwordInput = useInput({ defaultValue: "" });
  const locked = useState(null);
  const transferState = useState(false);
  const tokenAmountInput = useInput({
    defaultValue: "",
    maxLength: 64,
    regex: AMOUNT_REGEX,
  });

  const toAddressInput = useInput({
    defaultValue: "",
    maxLength: 42,
  });

  const privateKeyInput = useInput({
    defaultValue: "",
    maxLength: 64,
  });
  const selected = useState(null);
  const gasState = useState({});
  const gasSelectedState = useState({
    fastest: false,
    average: true,
    safeLow: false,
  });

  const transactionInit = () => {
    privateKeyInput.setValue("");
    toAddressInput.setValue("");
    tokenAmountInput.setValue("");
    passwordInput.setValue("");
  };

  const transferOnClick = ({ locked }) => {
    if (tokenAmountInput.value === "") {
      transferState.setState(false);
      return;
    }

    if (toAddressInput.value === "") {
      transferState.setState(false);
      return;
    }

    if (user.state.pkey === 0 && privateKeyInput.value === "") {
      transferState.setState(false);
      return;
    }

    if (!web3.utils.isAddress(toAddressInput.value)) {
      toast.error(languagePack("올바른 지갑주소가 아닙니다.", language.state), {
        toastId: "jwt",
      });
      transferState.setState(false);
      return;
    }

    transferState.setState(true);
    web3.eth
      .getTransactionCount(user.state.address, "pending")
      .then((count) => {
        return count;
      })
      .then(async (count) => {
        let gasPrice = 0;
        if (gasSelectedState.state.fastest) {
          gasPrice = gasState.state.fastest / 10;
        } else if (gasSelectedState.state.average) {
          gasPrice = gasState.state.average / 10;
        } else if (gasSelectedState.state.safeLow) {
          gasPrice = gasState.state.safeLow / 10;
        }

        const weiGasPrice = web3.utils.toWei(gasPrice.toString(), "gwei");
        const hexGasPrice = web3.utils.toHex(weiGasPrice);
        const privateKey = Buffer.from(privateKeyInput.value, "hex");

        let rawTransaction = {};

        if (selected.state.value === "Eth") {
          rawTransaction = {
            from: user.state.address,
            gasPrice: hexGasPrice,
            gasLimit: web3.utils.toHex(210000),
            to: toAddressInput.value,
            value: web3.utils.toHex(web3.utils.toWei(tokenAmountInput.value, "ether")),
            chainId: CHAIN_ID,
            nonce: web3.utils.toHex(count),
          };
        } else if (selected.state.value === "Camp") {
          rawTransaction = {
            from: user.state.address,
            gasPrice: hexGasPrice,
            gasLimit: web3.utils.toHex(210000),
            to: CONTRACT_ADDRESS[0].address,
            value: "0x0",
            data: contract.state["camp"].methods
              .transfer(toAddressInput.value, web3.utils.toWei(tokenAmountInput.value))
              .encodeABI(),
            nonce: web3.utils.toHex(count),
          };
        } else {
          toast.error(`${languagePack("전송 오류입니다.", language.state)}(3)`, { toastId: "transaction" });
          return;
        }

        // const transaction = new EthereumTx(rawTransaction, { chain: CHAIN_NAME });
        // transaction.sign(privateKey);

        const requestBody = {
          rawTransaction,
          CHAIN_NAME,
          p: user.state.pkey === 0 ? privateKeyInput.value : null,
        };

        const { data } = await Axios.post(`${HOST}/wallet/send/transaction`, requestBody, httpHeader());

        if (!data.status) {
          toast.error(`${languagePack("전송 오류입니다.", language.state)}(0)`, { toastId: "transaction" });
          return;
        }

        web3.eth.sendSignedTransaction(data.tx, async (error, hash) => {
          const requestBody = {
            sendType: selected.state.value.toLowerCase(),
            send: user.state.address,
            receive: toAddressInput.value,
            amount: tokenAmountInput.value,
            hash,
            error: error ? error.message : null,
          };
          if (error) {
            toast.error(`${languagePack("전송 오류입니다.", language.state)}(1) (${error})`, {
              toastId: "transaction",
            });
            const { data } = await Axios.post(`${HOST}/wallet/log`, requestBody, httpHeader());
            transferState.setState(false);
          } else {
            toast.success(languagePack("트랜잭션 전송 완료.", language.state), { toastId: "transaction" });
            const { data } = await Axios.post(`${HOST}/wallet/log`, requestBody, httpHeader());
            transactionInit();
            // window.open(`${ETHERSCAN_TX_URL}${hash}`);
            transferState.setState(false);
            if (user.state.pkey === 0) {
              logoutOnSubmit();
              toast.error(languagePack("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", language.state), {
                toastId: "jwt",
              });
            }
          }
        });
      })
      .catch((error) => {
        toast.error(`${languagePack("전송 오류입니다.", language.state)(2)} (${error.reason})`, {
          toastId: "transaction",
        });
        transferState.setState(false);
      });
  };

  const options = [
    {
      value: "Camp",
      label: (
        <OptionWrapper>
          <div style={{ marginRight: 3 }}>
            <img src={Logo} style={{ width: 16, height: 16 }} />
            <OptionSpan>Camp</OptionSpan>
          </div>
          <div>
            {balance.state && balance.state.camp !== "" && `(${Number(ToFixed(balance.state.camp, 100000000))})`}
          </div>
        </OptionWrapper>
      ),
    },
    {
      value: "Eth",
      label: (
        <OptionWrapper>
          <div style={{ marginRight: 3 }}>
            <EthereumIcon size={18} />
            <OptionSpan>Ethereum</OptionSpan>
          </div>
          <div>{balance.state && balance.state.eth !== "" && `(${Number(ToFixed(balance.state.eth, 100000000))})`}</div>
        </OptionWrapper>
      ),
    },
  ];

  const handleChange = (selectedOption) => {
    selected.setState(selectedOption);
  };

  const authenticate = async () => {
    if (otpIsNull.state === 0) {
      if (toast.isActive("otp")) {
        transferState.setState(false);
        return;
      }

      if (otpInput.value === "" || otpInput.value.length !== 6) {
        transferState.setState(false);
        return toast.error(languagePack("올바른 OTP 인증번호가 아닙니다.", language.state), { toastId: "otp" });
      }

      transferState.setState(true);

      const requestParams = {
        pin: otpInput.value,
      };
      const { data } = await Axios.post(`${HOST}/user/otp/validation`, requestParams, httpHeader());

      if (data.status) {
        transferOnClick({ locked });
      } else {
        toast.error(languagePack("잘못된 OTP 인증번호입니다.", language.state), {
          toastId: "transfer",
        });
        transferState.setState(false);
      }
    } else if (otpIsNull.state === 1) {
      if (passwordInput.value.length < 2) {
        transferState.setState(false);
        return;
      }
      if (toast.isActive("transfer")) {
        transferState.setState(false);
        return;
      }

      transferState.setState(true);
      const { data } = await Axios.post(`${HOST}/password/check`, { password: passwordInput.value }, httpHeader());

      if (data.status) {
        transferOnClick({ locked });
      } else {
        toast.error(languagePack("잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.", language.state), {
          toastId: "transfer",
        });
        transferState.setState(false);
      }
    }
  };

  const openQr = () => {
    if (toast.isActive("qr")) {
      return;
    }
    try {
      window.ReactNativeWebView.postMessage("qrcode");
    } catch (e) {
      toast.error(languagePack("지원하지 않는 플랫폼입니다.", language.state), {
        toastId: "qr",
      });
    }
  };

  const inputQr = () => {
    if (qrData.state) {
      if (qrData.state.length > 42) {
        const [, realCode] = qrData.state.split("ethereum:"); // if metamask qrcode
        toAddressInput.setValue(realCode);
      } else {
        toAddressInput.setValue(qrData.state);
      }
      qrData.setState(null);
    }
  };

  const getLocked = async () => {
    const { data } = await Axios.post(`${HOST}/api/lockup`, {}, httpHeader());
    locked.setState({ sum: Number(data.sum), availableAmount: Number(data.available_amount) });
  };

  const preload = () => {
    if (user.state && contract.state && !toggleMain.state) {
      toggleMain.setState(true);
      getBalance();
    }
  };

  const qrInit = async () => {
    if (pushToken.state) {
      const { data } = await Axios.post(`${HOST}/register/push`, { pushToken: pushToken.state }, httpHeader());

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      Axios.post(`${HOST}/user/info`, {}, config)
        .then((response) => {
          user.setState(response.data.user);
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            logoutOnSubmit();
            toast.error(languagePack("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", language.state), {
              toastId: "jwt",
              autoClose: 4000,
            });
          }
        });
    }
  };

  const getOtp = async () => {
    const { data } = await Axios.post(`${HOST}/user/otp/check`, null, httpHeader());
    otpIsNull.setState(data.isNull);
  };

  useEffect(() => {
    getOtp();
    qrInit();
    getLocked();
    Axios.post(GAS_PRICE_API).then((response) => {
      gasState.setState(response.data);
    });
  }, []);

  useEffect(preload, [user.state, contract.state]);

  useEffect(inputQr, [qrData.state]);

  return (
    <PageWrapper>
      <TransferWrapper>
        {locked.state ? (
          <>
            {balance.state && (
              <>
                <Select
                  onChange={handleChange}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={languagePack("전송 유형", language.state)}
                  options={options}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      borderBottom: "1px dotted lightGrey ",
                      padding: 20,
                    }),
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      marginBottom: 20,
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      height: 46,
                    }),
                    singleValue: (provided, state) => {
                      return { ...provided };
                    },
                  }}
                />
                {selected.state && selected.state.value === "Camp" && (
                  <Table>
                    <thead></thead>
                    <tbody>
                      {/* <tr>
                      <td>Available Amount</td>
                      <td style={{ fontWeight: "bold" }}>{numberWithCommas(locked.state.availableAmount)}</td>
                    </tr> */}
                      <tr>
                        <td>Locked Amount</td>
                        <td style={{ fontWeight: "bold", textAlign: "right" }}>
                          {numberWithCommas(
                            ToFixed(
                              locked.state.sum - locked.state.availableAmount > Number(balance.state.camp)
                                ? balance.state.camp
                                : locked.state.sum - locked.state.availableAmount,
                              100
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </>
            )}

            {selected.state && (
              <>
                {transferState.state && (
                  <Loader>
                    <BeatLoader sizeUnit={"px"} size={10} color={Theme.mainColor} />
                  </Loader>
                )}
                <InputLabel>{languagePack("보낼 금액을 입력하세요.", language.state)}</InputLabel>
                <AmountInput tokenAmountInput={tokenAmountInput} asset={selected.state.value} />
                <InputLabel>{languagePack("수수료를 선택하세요.", language.state)}</InputLabel>
                <GasSelector gasState={gasState} gasSelectedState={gasSelectedState} language={language.state} />
                {tokenAmountInput.value && (
                  <>
                    <InputLabel>{languagePack("받는 사람의 주소를 입력하세요.", language.state)}</InputLabel>
                    <InputWrapper>
                      <PrivateKeyInput style={{ paddingRight: 40, fontSize: 11 }} {...toAddressInput} />
                      <IconWrapper onClick={openQr}>
                        <QrScannerIcon color={Theme.darkGrey} />
                      </IconWrapper>
                    </InputWrapper>
                  </>
                )}
                {tokenAmountInput.value && toAddressInput.value.length === 42 && user.state.pkey === 0 && (
                  <BlockInputWrapper>
                    <InputLabel>{languagePack("개인 키를 입력하세요.", language.state)}</InputLabel>
                    <PrivateKeyInput
                      style={{ paddingRight: 35, fontSize: 11 }}
                      placeholder="Private key"
                      type="password"
                      {...privateKeyInput}
                    />
                  </BlockInputWrapper>
                )}
                <InputWrapper>
                  {user.state.pkey === 0
                    ? tokenAmountInput.value &&
                      toAddressInput.value &&
                      privateKeyInput.value.length === 64 && (
                        <Popup
                          trigger={
                            <Button block type="button" disabled={transferState.state}>
                              {languagePack("송금", language.state)}
                            </Button>
                          }
                          modal={true}
                          contentStyle={{
                            padding: 25,
                            width: "90%",
                            border: 0,
                            borderRadius: 10,
                          }}
                        >
                          {(close) => (
                            <div>
                              <PasswordTitle>{languagePack("비밀번호를 입력하세요.", language.state)}</PasswordTitle>
                              <Input {...passwordInput} type="password" placeholder="Password" />
                              <Button
                                style={{ marginTop: 10 }}
                                block
                                type="button"
                                onClick={authenticate}
                                disabled={transferState.state}
                              >
                                {languagePack("송금", language.state)}
                              </Button>
                            </div>
                          )}
                        </Popup>
                      )
                    : tokenAmountInput.value &&
                      toAddressInput.value.length === 42 && (
                        <Popup
                          trigger={
                            <Button block type="button" disabled={transferState.state}>
                              {languagePack("송금", language.state)}
                            </Button>
                          }
                          modal={true}
                          contentStyle={{
                            padding: 25,
                            width: "90%",
                            border: 0,
                            borderRadius: 10,
                          }}
                        >
                          {(close) => (
                            <>
                              {otpIsNull.state === 1 && (
                                <div>
                                  <PasswordTitle>
                                    {languagePack("비밀번호를 입력하세요.", language.state)}
                                  </PasswordTitle>
                                  <Input {...passwordInput} type="password" placeholder="Password" />
                                  <Button
                                    style={{ marginTop: 10 }}
                                    block
                                    type="button"
                                    onClick={authenticate}
                                    disabled={transferState.state}
                                  >
                                    {languagePack("송금", language.state)}
                                  </Button>
                                </div>
                              )}
                              {otpIsNull.state === 0 && (
                                <div>
                                  <PasswordTitle>
                                    {languagePack("OTP 인증번호를 입력하세요.", language.state)}
                                  </PasswordTitle>
                                  <Input {...otpInput} placeholder="OTP Code" />
                                  <Button
                                    style={{ marginTop: 10 }}
                                    block
                                    type="button"
                                    onClick={authenticate}
                                    disabled={transferState.state}
                                  >
                                    {languagePack("송금", language.state)}
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </Popup>
                      )}
                </InputWrapper>
              </>
            )}
          </>
        ) : (
          <Loader>
            <ClipLoader sizeUnit={"px"} size={15} />
          </Loader>
        )}
      </TransferWrapper>
    </PageWrapper>
  );
};

export default Container;
