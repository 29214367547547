import React, { useContext } from "react";
import styled from "styled-components";
import Axios from "axios";
import Popup from "reactjs-popup";
import { Transaction as EthereumTx } from "ethereumjs-tx";

import { dateConvertor, ToFixed } from "../../Utils/util";
import Theme from "../../Styles/Theme";
import Button from "../Common/DefaultButton";
import GlobalContext from "../../Hooks/globalContext";
import useInput from "../../Hooks/useInput";
import useState from "../../Hooks/useState";
import { HOST, CHAIN_ID, CHAIN_NAME, GAS_PRICE_API, NUMBER_REGEX } from "../../Utils/constants";
import Input from "../Common/Input";
import { toast } from "react-toastify";

const ButtonWrapper = styled.div`
  display: flex;
`;

const UpdateButton = styled.div`
  color: white;
  padding: 5px;
  background-color: orange;
  border-radius: 5px;
  text-align: center;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
  :not(:last-child) {
    margin-right: 5px;
  }
`;

const BlueButton = styled(UpdateButton)`
  background-color: blue;
`;

const DeleteButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.navbar};
`;

const AnotherButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.mainColor};
`;

const OrangeButton = styled(UpdateButton)`
  background-color: orange;
`;

const DefaultButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.button};
  width: 70px;
`;

const DeleteForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.black};
`;

const DeleteLabel = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const RedLabel = styled.span`
  color: red;
`;

const BlueLabel = styled.span`
  color: blue;
`;

const StrongSpan = styled.span`
  font-weight: bold;
`;

const QnaRow = styled.div`
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 13px;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const ColumnFactory = (params) => {
  const { httpHeader, modal, web3, getTxCount, getBalance, user, language } = useContext(GlobalContext);
  const lockAmount = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });
  const lockDay = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });

  const getHeader = () => ({
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });

  return {
    lockupList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "락업 갯수",
        dataIndex: "locked_amount",
        key: "locked_amount",
        render: (item) => {
          return <>{Number(item)}</>;
        },
      },
      //   {
      //     title: "토큰 타입",
      //     dataIndex: "token_type",
      //     key: "token_type",
      //   },
      {
        title: "날짜",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "강제 해제상태",
        dataIndex: "status",
        key: "status",
        render: (item) => {
          return <>{item === 0 ? "-" : <RedLabel>강제 해제됨</RedLabel>}</>;
        },
      },
      {
        title: "락업 해제일",
        dataIndex: "release_time",
        key: "release_time",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "락업해제 후 일(day) 간격 n",
        dataIndex: "n_time",
        key: "n_time",
        render: (item) => {
          return <>{item ? `${item} 일` : "-"}</>;
        },
      },
      {
        title: "n일마다 해제될 수량 %",
        dataIndex: "m_percent",
        key: "m_percent",
        render: (item) => {
          return <>{item ? `${item} %` : "-"}</>;
        },
      },
      {
        title: "유저 번호",
        dataIndex: "user_id",
        key: "user_id",
      },
      {
        title: "관리",
        render: (item) => {
          const toggle = async () => {
            const requestBody = {
              id: item.id,
              toggle: item.status,
            };
            const { data } = await Axios.post(`${HOST}/admin/lockup/toggle`, requestBody, httpHeader());

            params.reset({ selected: params.nowPage.state });
          };

          return (
            <ButtonWrapper>
              {item.status === 0 ? (
                <OrangeButton onClick={toggle} style={{ width: "auto" }}>
                  락업 강제 해제
                </OrangeButton>
              ) : (
                <DeleteButton onClick={toggle} style={{ width: "auto" }}>
                  재 락업
                </DeleteButton>
              )}
            </ButtonWrapper>
          );
        },
      },
    ],
  };
};

export default ColumnFactory;
