import React from "react";
import styled from "styled-components";

const Header = styled.div`
  height: 90px;
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.black};
`;

const SubTitle = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.darkGrey};
`;

const titleFactory = (page) => {
  switch (page) {
    case "userList":
      return { title: "유저 목록", subTitle: "유저를 관리합니다." };
    case "airdrop":
      return { title: "에어드롭 관리", subTitle: "에어드롭을 지급해야하는 유저를 관리합니다." };
    case "stake":
      return { title: "스테이킹 관리", subTitle: "스테이킹을 신청한 유저를 관리합니다." };
    case "reward":
      return { title: "보상 관리", subTitle: "스테이킹에 의한 보상을 관리합니다." };
    case "timeLockForm":
      return { title: "토큰 타임 락", subTitle: "토큰을 지정한 시간만큼 Lock합니다." };
    case "mintForm":
      return { title: "추가 발행", subTitle: "발행량을 늘립니다." };
    case "burnForm":
      return { title: "토큰 소각", subTitle: "발행량을 소각합니다." };
    case "kyc":
      return { title: "KYC 인증 관리", subTitle: "유저의 KYC 인증을 관리합니다." };
    case "lockList":
      return { title: "자체 락업 리스트", subTitle: "컨트랙트에 의한 락업이 아닌 월렛 자체의 락업 로그입니다." };
    case "registerEvent":
      return {
        title: "설정 관리",
        subTitle:
          "가입 이벤트를 시작/종료 할 수 있으며 유저 가입 에어드롭 갯수와 추천인 에어드롭 갯수 조정 등 설정을 관리합니다.",
      };
  }
};

const AdminHeader = ({ page }) => {
  const info = titleFactory(page);

  return (
    <Header>
      <Title>{info.title}</Title>
      <SubTitle>{info.subTitle}</SubTitle>
    </Header>
  );
};

export default AdminHeader;
