import React, { useContext } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { toast } from "react-toastify";

import { languagePack } from "../../../Utils/language";
import Input from "../../../Components/Common/Input";
import Theme from "../../../Styles/Theme";
import PageWrapper from "../../../Components/Common/PageWrapper";
import useInput from "../../../Hooks/useInput";
import globalContext from "../../../Hooks/globalContext";
import Axios from "axios";
import useState from "../../../Hooks/useState";
import { HOST } from "../../../Utils/constants";

const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  input {
    margin: 10px 0px;
  }
`;

const PasswordBox = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0px;
`;

const Container = () => {
  const { language, user, logoutOnSubmit } = useContext(globalContext);
  const oldPasswordInput = useInput({ defaultValue: "" });
  const passwordInput = useInput({ defaultValue: "" });
  const passwordCheckInput = useInput({ defaultValue: "" });
  const passwordButtonState = useState(false);
  const passwordOnClick = () => {
    if (toast.isActive("password")) {
      return;
    }
    if (passwordInput.value !== passwordCheckInput.value) {
      toast.error(languagePack("두 비밀번호가 맞지 않습니다.", language.state), { toastId: "password" });
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    passwordButtonState.setState(true);
    Axios.post(
      `${HOST}/user/password`,
      { email: user.state.email, password: oldPasswordInput.value, newPassword: passwordInput.value },
      config
    )
      .then((response) => {
        if (response.data.status) {
          passwordInput.setValue("");
          passwordCheckInput.setValue("");
          toast.success(languagePack("비밀번호가 변경되었습니다. 다시 로그인해주세요.", language.state), {
            toastId: "password",
          });
          logoutOnSubmit();
          passwordButtonState.setState(false);
        } else {
          switch (response.data.code) {
            case "invalid":
              toast.error(languagePack("잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.", language.state), {
                toastId: "password",
              });
              break;
            default:
              toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
                toastId: "password",
              });
              break;
          }
          passwordButtonState.setState(false);
        }
      })
      .catch((err) => {
        toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
          toastId: "password",
        });
        passwordButtonState.setState(false);
      });
  };

  return (
    <PageWrapper>
      <PasswordWrapper>
        <PasswordBox>
          <ButtonWrapper>
            <Input placeholder="Password" type="password" {...oldPasswordInput} />
          </ButtonWrapper>
          <Input placeholder="New Password" type="password" {...passwordInput} />
          <Input placeholder="New Password Check" type="password" {...passwordCheckInput} />
          <ButtonWrapper>
            <Button
              style={{ border: 0, backgroundColor: Theme.mainColor }}
              type="button"
              onClick={passwordOnClick}
              block
            >
              Reset
            </Button>
          </ButtonWrapper>
        </PasswordBox>
      </PasswordWrapper>
    </PageWrapper>
  );
};

export default Container;
