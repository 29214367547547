import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import Popup from "reactjs-popup";

import { languagePack } from "../../Utils/language";
import { AMOUNT_REGEX, HOST, GAS_PRICE_API, CHAIN_NAME, CONTRACT_ADDRESS } from "../../Utils/constants";
import { numberWithCommas, dateConvertor, ToFixed } from "../../Utils/util";
import Theme from "../../Styles/Theme";
import useInput from "../../Hooks/useInput";
import useState from "../../Hooks/useState";
import AmountInput from "../../Components/Common/AmountInput";
import { SquareIcon } from "../../Components/Common/Icons";
import GlobalContext from "../../Hooks/globalContext";
import PageWrapper from "../../Components/Common/PageWrapper";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransferInput = styled.input`
  border: 0;
  border-radius: 0;
  width: 100%;
  letter-spacing: 5px;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 20px;
  padding-right: 40px;
  text-align: right;
`;

const StakingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const BlockInputWrapper = styled.div`
  width: 100%;
`;

const PrivateKeyInput = styled(TransferInput)`
  letter-spacing: 0px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  padding-left: 40px;
`;

const InputLabel = styled.div`
  font-size: 15px;
  font-weight: 800;
  width: 100%;
  text-align: center;
`;

const Stake = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const BalanceLabel = styled.div`
  width: 100%;
  font-size: 13px;
  color: ${(props) => props.theme.darkGrey};
  text-align: center;
`;

const StakeText = styled.div`
  padding-left: 40px;
  margin-bottom: 20px;
`;

const Title = styled.span`
  margin-left: 10px;
`;

const StakingPage = () => {
  const { httpHeader, logoutOnSubmit, web3, contract, user, language } = useContext(GlobalContext);
  const permission = useState(null);
  const stakeInput = useInput({ defaultValue: "", maxLength: 64, regex: AMOUNT_REGEX });
  const privateKeyInput = useInput({
    defaultValue: "",
    maxLength: 64,
  });
  const isLoading = useState(false);
  const isStaked = useState(null);
  const rewardStatus = useState(null);
  const stakedAmount = useState(null);
  const stakedAt = useState(null);
  const balance = useState(null);
  const pkey = useState(null);

  const Notification = (close) => {
    return (
      <div style={{ fontSize: 13 }}>
        - 거래소 상장 전 보유하고 있는 CAMP COIN이 200,000 CAMP 이상인 회원
        <br />
        <br />
        Staking 신청기간
        <br />
        - 거래소 상장 전까지
        <br />
        <br />
        Staking 혜택
        <br />
        - 스테이킹 비율에 따라 본인 지갑으로 자동 에어드랍
        <br />
        <br />
        Staking 해제
        <br />
        - 스테이킹 해제 시 추후 스테이킹 신청 불가
        <br />
        - 스테이킹 해제 신청은 스테이킹 해제신청 버튼 클릭으로 해제
        <br />
        - 스테이킹 해제와 동시에 스테이킹 혜택 소멸
        <br />
        <br />
        Staking 주의사항
        <br />
        - 스테이킹 유지 기간동안 CAMP COIN 출금 불가
        <br />
        - 스테이킹 기간 동안 CAMP COIN 가격변동에 의한 손실이 발생할 수 있으며 캠프 글로벌은 이에 대한 어떠한 책임도
        지지 않습니다.
        <br />
        <br />
        <br />
        <span style={{ color: "red" }}>위 내용을 반드시 숙지하고 스테이킹 해주세요.</span>
        <Button
          style={{ border: 0, backgroundColor: Theme.mainColor }}
          disabled={isLoading.state}
          type="button"
          onClick={stakeOnClick.bind(null, close)}
          block
        >
          {languagePack("스테이킹", language.state)}
        </Button>
      </div>
    );
  };

  const UnstakeNoti = (close) => {
    return (
      <div style={{ fontSize: 13 }}>
        Staking 해제
        <br />
        - 스테이킹 해제 시 추후 스테이킹 신청 불가
        <br />
        - 스테이킹 해제 신청은 스테이킹 해제신청 버튼 클릭으로 해제
        <br />
        - 스테이킹 해제와 동시에 스테이킹 혜택 소멸
        <br />
        <br />
        <br />
        <span style={{ color: "red" }}>위 내용을 반드시 숙지하고 신청해주세요.</span>
        <Button
          style={{ border: 0, backgroundColor: Theme.mainColor }}
          disabled={isLoading.state}
          type="button"
          onClick={unStakeOnClick.bind(null, close)}
          block
        >
          {languagePack("스테이킹 해제신청", language.state)}
        </Button>
      </div>
    );
  };

  const stakeOnClick = async (close) => {
    const parsed = Number(stakeInput.value);

    if (toast.isActive("stake")) {
      return;
    }

    if (!balance.state) {
      return toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
        toastId: "stake",
      });
    }

    if (!pkey.state && privateKeyInput.value === "") {
      return toast.error(languagePack("모든 항목을 입력해주세요.", language.state), {
        toastId: "stake",
      });
    }

    if (!pkey.state && privateKeyInput.value.length < 63) {
      return toast.error(languagePack("잘못된 비밀키입니다.", language.state), {
        toastId: "stake",
      });
    }

    const parsedToken = Number(balance.state);

    if (parsedToken < parsed) {
      return toast.error(languagePack("잔액이 부족합니다.", language.state), { toastId: "stake" });
    }

    if (parsed < 200000) {
      return toast.error(languagePack("최소 수량은 200,000개 입니다.", language.state), { toastId: "stake" });
    }

    isLoading.setState(true);

    const count = await web3.eth.getTransactionCount(user.state.address, "pending");
    const { data } = await axios.post(GAS_PRICE_API);
    const {
      data: { adminAddress },
    } = await axios.post(`${HOST}/api/address`, null, httpHeader());

    const gasPrice = data.average / 10;
    const weiGasPrice = web3.utils.toWei(gasPrice.toString(), "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);
    const privateKey = Buffer.from(privateKeyInput.value, "hex");

    const rawTransaction = {
      from: user.state.address,
      gasPrice: hexGasPrice,
      gasLimit: web3.utils.toHex(210000),
      to: CONTRACT_ADDRESS[0].address,
      value: "0x0",
      data: contract.state["camp"].methods.transfer(adminAddress, web3.utils.toWei(stakeInput.value)).encodeABI(),
      nonce: web3.utils.toHex(count),
    };

    // const transaction = new EthereumTx(rawTransaction, { chain: CHAIN_NAME });
    // transaction.sign(privateKey);

    const requestBody = {
      rawTransaction,
      CHAIN_NAME,
      p: user.state.pkey === 0 ? privateKeyInput.value : null,
    };

    const { data: _data } = await axios.post(`${HOST}/wallet/send/transaction`, requestBody, httpHeader());

    if (!_data.status) {
      toast.error(`${languagePack("전송 오류입니다.", language.state)}`, { toastId: "transaction" });
      return;
    }

    web3.eth.sendSignedTransaction(_data.tx, async (error, hash) => {
      if (error) {
        toast.error(`${languagePack("전송 오류입니다.", language.state)} (${error})`, {
          toastId: "transaction",
        });
        // transferState.setState(false);
      } else {
        const body = {
          amount: stakeInput.value,
          hash,
        };
        const { data } = await axios.post(`${HOST}/user/staking`, body, httpHeader());
        await getUser();
        toast.success(languagePack("트랜잭션 전송 완료.", language.state), { toastId: "transaction" });
        if (user.state.pkey === 0) {
          logoutOnSubmit();
          toast.error(languagePack("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", language.state), {
            toastId: "jwt",
          });
        }
      }
    });

    isLoading.setState(false);
  };

  const unStakeOnClick = async () => {
    const { data } = await axios.post(`${HOST}/user/unstake`, { detail: true }, httpHeader());
    toast.success(languagePack("성공적으로 처리되었습니다.", language.state), {
      toastId: "stake",
    });
    await getUser();
  };

  const getUser = async () => {
    const { data } = await axios.post(`${HOST}/user/info`, { detail: true }, httpHeader());
    permission.setState(data.user.kycAuth === 1);
    isStaked.setState(data.user.isStaked === 1);
    stakedAmount.setState(data.user.stakedAmount);
    stakedAt.setState(data.user.stakedAt);
    pkey.setState(data.user.pkey === 1);

    rewardStatus.setState({ reward: data.user.reward === 1, rewardTime: data.user.rewardTime });
  };

  // const getStaking = async () => {
  //   const config = {
  //     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //   };
  //   const { data } = await axios.post(`${HOST}/user/stake`, {}, config);
  //   isStaked.setState(data.user);
  // };

  const Init = async () => {
    const token = await contract.state["camp"].methods.balanceOf(user.state.address).call();
    balance.setState(web3.utils.fromWei(token));
  };

  useEffect(() => {
    if (contract.state) {
      getUser();
      Init();
    }
  }, [contract.state]);

  const Loader = () => {
    return (
      <LoaderWrapper>
        <ClipLoader sizeUnit={"px"} size={30} color={"#747474"} />
      </LoaderWrapper>
    );
  };

  const AlreadyStaking = () => {
    return (
      <>
        <Stake>
          <SquareIcon color={Theme.mainColor} size={6} />
          <Title>Staking</Title>
        </Stake>
        <StakeText>{stakedAmount.state && numberWithCommas(Number(stakedAmount.state))} CAMP</StakeText>
        <Stake>
          <SquareIcon color={Theme.mainColor} size={6} />
          <Title>Date</Title>
        </Stake>
        <StakeText>{dateConvertor(new Date(stakedAt.state))}</StakeText>
        {rewardStatus.state.reward ? (
          <>
            {rewardStatus.state.rewardTime ? (
              <div style={{ color: "green", textAlign: "center" }}>
                * {dateConvertor(new Date(rewardStatus.state.rewardTime))} 보상이 지급됨.
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>* 해제 신청상태입니다. 관리자의 승인을 기다려주세요.</div>
            )}
          </>
        ) : (
          <>
            <Popup
              trigger={
                <Button style={{ border: 0, backgroundColor: Theme.mainColor }} block>
                  {languagePack("스테이킹 해제신청", language.state)}
                </Button>
              }
              modal={true}
              contentStyle={{
                padding: 25,
                width: "90%",
                border: 0,
                borderRadius: 10,
              }}
            >
              {(close) => (
                <>
                  <UnstakeNoti close={close} />
                </>
              )}
            </Popup>
            <div style={{ color: "red", textAlign: "center" }}>* 스테이킹 해제시 재신청이 불가능합니다.</div>
          </>
        )}
      </>
    );
  };

  const KycAlert = () => {
    return <div style={{ textAlign: "center" }}>KYC 인증받은 유저만 스테이킹할 수 있습니다.</div>;
  };

  return (
    <PageWrapper>
      <StakingPageWrapper>
        {permission.state ? ( //KYC 인증 여부
          <>
            {stakedAmount.state ? (
              rewardStatus.state && <AlreadyStaking />
            ) : (
              <>
                <InputLabel>
                  {languagePack("스테이킹할 토큰 갯수를 입력하세요.(최소 20만개)", language.state)}
                </InputLabel>
                <BalanceLabel>
                  {balance.state && `(${languagePack("잔액", language.state) + " " + parseInt(balance.state)})`}
                </BalanceLabel>
                <AmountInput tokenAmountInput={stakeInput} />
                {!pkey.state && (
                  <BlockInputWrapper>
                    <InputLabel>{languagePack(10, language.state)}</InputLabel>
                    <PrivateKeyInput
                      style={{ paddingRight: 35, fontSize: 11 }}
                      placeholder="Private key"
                      type="password"
                      {...privateKeyInput}
                    />
                  </BlockInputWrapper>
                )}
                <Popup
                  trigger={
                    <Button style={{ border: 0, backgroundColor: Theme.mainColor }} block>
                      {languagePack("스테이킹", language.state)}
                    </Button>
                  }
                  modal={true}
                  contentStyle={{
                    padding: 25,
                    width: "90%",
                    border: 0,
                    borderRadius: 10,
                  }}
                >
                  {(close) => (
                    <>
                      <Notification close={close} />
                    </>
                  )}
                </Popup>
              </>
            )}
          </>
        ) : permission.state === null ? (
          <Loader />
        ) : (
          <KycAlert />
        )}
      </StakingPageWrapper>
    </PageWrapper>
  );
};

export default StakingPage;
