export default {
  black: "#151515",
  mainColor: "#43A3C9",
  mainDarkColor: "#19496A",
  bgBlackColor: "#1a1a1a",
  bgColor: "#fafafa",
  darkGrey: "#999",
  lightGrey: "#e6e6e6",
  navy: "#1D3548",
  navyDarkGrey: "#2A394B",
  red: "#c0392b"
};
