import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TransferInput = styled.input`
  border: 0;
  border-radius: 0;
  width: 100%;
  letter-spacing: 5px;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 20px;
  padding-right: 65px;
  text-align: right;
`;

const DelimiterSpan = styled.span`
  position: absolute;
  right: 5px;
  top: 6px;
  font-size: 18px;
`;

const Container = ({ tokenAmountInput, asset, placeholder }) => (
  <InputWrapper>
    <TransferInput placeholder={placeholder} {...tokenAmountInput} />
    <DelimiterSpan>{asset}</DelimiterSpan>
  </InputWrapper>
);

export default Container;
