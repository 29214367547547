import React, { useEffect } from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "react-sidebar";
import { css } from "glamor";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import Web3 from "web3";

import Routes from "./Components/Routes";
import Contract_CAMP from "./Resources/Contract";
import Theme from "./Styles/Theme";
import GlobalContext from "./Hooks/globalContext";
import useState from "./Hooks/useState";
import GlobalStyles from "./Styles/GlobalStyle";
import "react-toastify/dist/ReactToastify.css";
import SidebarContainer from "./Components/Common/Sidebar";
import { CONTRACT_ADDRESS, HOST, NODE_ENDPOINT } from "./Utils/constants";

import "react-toastify/dist/ReactToastify.css";
import { ToFixed } from "./Utils/util";
const web3 = new Web3(
  new Web3.providers.HttpProvider(NODE_ENDPOINT, {
    headers: [],
  })
);

const Wrapper = styled.div`
  height: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const App = () => {
  const isLogin = useState(localStorage.getItem("token") ? true : false);
  const user = useState(null);
  const sidebarState = useState(false);
  const language = useState("korean");
  const device = useState(null);
  const pushToken = useState(null);
  const qrData = useState(null);
  const contract = useState();
  const balance = useState(null);
  const toggleMain = useState(false);

  const sidebarStyle = {
    sidebar: {
      zIndex: 10,
      width: "100%",
      background: Theme.navy,
      boxShadow: "rgba(0, 0, 0, 0.15) 4px 4px 8px",
      maxWidth: 768,
    },
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      zIndex: 1,
      width: "100%",
    },
  };

  const sidebarToggle = () => {
    sidebarState.setState(!sidebarState.state);
  };

  const logoutOnSubmit = () => {
    user.setState(null);
    localStorage.removeItem("token");
    window.location.replace("/");
    isLogin.setState(false);
  };

  const languageOnChange = ({ target }) => {
    if (target.value !== "") {
      language.setState(target.value);
    }
  };
  const balanceConvert = (eth) => {
    try {
      const numberEth = Number(eth);
      const converted = numberEth.toFixed(9);
      const result = converted.substring(0, converted.length - 1);
      return Number(result);
    } catch (e) {
      return eth;
    }
  };

  const httpHeader = () => {
    const httpHeader = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    return httpHeader;
  };

  const getUser = async () => {
    try {
      const { data } = await axios.post(`${HOST}/user/info`, { detail: true }, httpHeader());
      user.setState(data.user);
      return data.user;
    } catch (e) {
      logoutOnSubmit();
    }
  };

  const getTxCount = async (address) => {
    const count = await web3.eth.getTransactionCount(address, "pending");
    return count;
  };

  const refreshEth = async (symbol, rowAddress) => {
    balance.setState({ ...balance.state, eth: undefined });
    const _balance = await web3.eth.getBalance(rowAddress ? rowAddress : user.state.address);
    const convertedBalance = ToFixed(web3.utils.fromWei(_balance), 10000);
    return convertedBalance;
  };

  const refreshToken = async (symbol, rowAddress) => {
    let _balance = await contract.state[symbol].methods.balanceOf(rowAddress ? rowAddress : user.state.address).call();
    const convertedBalance = ToFixed(web3.utils.fromWei(_balance), 10000);
    return convertedBalance;
  };

  const getBalance = async (symbol, admin = false, rowAddress = null) => {
    switch (symbol) {
      case "eth":
        const _eth = await refreshEth(symbol, rowAddress);
        balance.setState({ ...balance.state, eth: _eth });
        return;
      case "camp":
        balance.setState({ ...balance.state, camp: null });
        const campBalance = await refreshToken(symbol, rowAddress);
        balance.setState({ ...balance.state, camp: campBalance });
        return;
      default:
        const eth = await refreshEth(symbol, rowAddress);
        const camp = await refreshToken("camp", rowAddress);
        const merged = {
          eth,
          camp,
        };

        if (!admin) {
          balance.setState(merged);
        } else {
          return merged;
        }
        return;
    }
  };

  const preload = () => {
    const pcDevice = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
      if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.search("android") > -1) {
          device.setState("android");
        } else if (userAgent.search("iphone") > -1 || userAgent.search("ipod") > -1 || userAgent.search("ipad") > -1) {
          device.setState("ios");
        } else {
          device.setState("mobile");
        }
      } else {
        device.setState("pc");
      }
    }
    window.addEventListener("message", function (event) {
      if (event.data && event.data !== "undefined") {
        try {
          const parsed = JSON.parse(event.data);
          if (parsed.code) {
            qrData.setState(parsed.code);
          } else {
            pushToken.setState(parsed.pushToken);
          }
        } catch (e) {
          if (
            !(event.data === "onboardingcomplete") &&
            !(event.data === "recaptcha-setup") &&
            !(typeof event.data === "object")
          ) {
            // qrData.setState(event.data);
          }
        }
      }
    });
    document.addEventListener("message", function (event) {
      if (event.data && event.data !== "undefined") {
        try {
          const parsed = JSON.parse(event.data);
          if (parsed.code) {
            qrData.setState(parsed.code);
          } else {
            pushToken.setState(parsed.pushToken);
          }
        } catch (e) {
          if (
            !(event.data === "onboardingcomplete") &&
            !(event.data === "recaptcha-setup") &&
            !(typeof event.data === "object")
          ) {
            // qrData.setState(event.data);
          }
        }
      }
    });
  };

  const setContract = () => {
    const userInit = async () => {
      await getUser();
    };

    const contractInit = async () => {
      await loginHandler();
    };

    if (!user.state && isLogin.state) {
      userInit();
    }

    if (!contract.state && isLogin.state) {
      contractInit();
    }
  };

  const loginHandler = () => {
    const initialize = async () => {
      const _user = await getUser();
      if (_user === "expired") {
        logoutOnSubmit();
        return;
      }
      const contracts = [];

      await Promise.all(
        CONTRACT_ADDRESS.map(async ({ address, name }, index) => {
          switch (name) {
            case "camp":
              contracts[name] = new web3.eth.Contract(Contract_CAMP, address);
              break;
          }
        })
      );
      contract.setState(contracts);
    };
    if (isLogin.state) {
      initialize();
    }
  };
  useEffect(() => {
    setContract();
  }, [isLogin.state]);

  useEffect(preload, []);

  return (
    <>
      <GlobalStyles />
      <GlobalContext.Provider
        value={{
          isLogin,
          user,
          sidebarToggle,
          logoutOnSubmit,
          language,
          languageOnChange,
          httpHeader,
          getUser,
          pushToken,
          qrData,
          balance,
          refreshEth,
          refreshToken,
          web3,
          getBalance,
          toggleMain,
          contract,
          balanceConvert,
        }}
      >
        <ThemeProvider theme={Theme}>
          <Router>
            <Helmet>
              <meta name="viewport" content="initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width" />
            </Helmet>
            <ToastContainer
              position={toast.POSITION.BOTTOM_LEFT}
              autoClose={3500}
              toastClassName={css({ fontSize: "13px" })}
            />
            <Sidebar
              sidebar={
                <SidebarContainer
                  sidebarToggle={sidebarToggle}
                  user={user}
                  isLogin={isLogin}
                  logoutOnSubmit={logoutOnSubmit}
                  language={language}
                />
              }
              pullRight={true}
              open={sidebarState.state}
              onSetOpen={sidebarToggle}
              styles={sidebarStyle}
            >
              <Wrapper>
                <InnerWrapper>
                  <Routes />
                </InnerWrapper>
              </Wrapper>
            </Sidebar>
          </Router>
        </ThemeProvider>
      </GlobalContext.Provider>
    </>
  );
};

export default App;
