import React, { useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Axios from "axios";
import Web3 from "web3";
import Popup from "reactjs-popup";
import { Transaction as EthereumTx } from "ethereumjs-tx";
import GlobalContext from "../../Hooks/globalContext";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "../../Styles/pagination.css";
import { Button, ButtonGroup, Alert } from "reactstrap";
import Table from "rc-table";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import HeaderLogoBlack from "../../Resources/Images/header_logo1.png";
import contract from "../../Resources/Contract";
import GasSelector from "../../Components/Common/GasSelector";
import {
  GAS_PRICE_API,
  HOST,
  CHAIN_ID,
  NODE_ENDPOINT,
  NUMBER_REGEX,
  CHAIN_NAME,
  AMOUNT_REGEX,
  ETHERSCAN_TX_URL,
  SECOND,
  ADMIN_PAGE_UNIT,
  CONTRACT_ADDRESS as C,
  NEW_CONTRACT_ADDRESS as N,
} from "../../Utils/constants";
import { dateConvertor, numberWithCommas } from "../../Utils/util";

import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import { HamburgerIcon } from "../../Components/Common/Icons";
import TokenAdminAction from "../../Components/Admin/TokenAdminAction";
import AmountInput from "../../Components/Common/AmountInput";
import Input from "../../Components/Common/Input";
import Theme from "../../Styles/Theme";
import AdminHeader from "../../Components/Admin/AdminHeader";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import RegisterEvent from "../../Components/Admin/RegisterEvent";
import LogPage from "../../Components/Admin/LogPage";
import Airdrop from "../../Components/Admin/Airdrop";
import AdminKycPage from "../../Components/Admin/AdminKycPage";
import ImportColumns from "../../Components/Admin/TableColumns";

const NEW_CONTRACT_ADDRESS = N;
const CONTRACT_ADDRESS = C[0].address;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const PopUpForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.black};
`;

const Main = styled.div`
  width: calc(100% - 250px);
  min-width: 800px;
  background-color: ${(props) => props.theme.lightGrey};
`;

const MainContent = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  padding: 0px 20px 20px 20px;
`;

const Content = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TransferInput = styled.input`
  border: 0;
  border-radius: 0;
  width: 100%;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 10px;
  text-align: center;
`;

const InputForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 15px;
  }
`;

const Label = styled.div`
  font-weight: 800;
  text-align: center;
  width: 100%;
  margin-top: 15px;
`;

const Caption = styled.div`
  padding: 10px 0px;
  color: red;
  font-weight: 800;
  font-size: 13px;
  text-align: center;
`;

const StakeInfo = styled.div`
  padding: 10px;
  font-size: 16px;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomInput = styled.input`
  border: 0;
  border: 1px solid #e6e6e6;
  border-radius: 2.5px;
  background-color: #fafafa;
  font-size: 12px;
  box-sizing: border-box;
  max-width: 300px;
  width: 100%;
  padding: 5px;
  outline: none;
  :disabled {
    border: 1px dotted #e6e6e6;
  }
`;

const CustomForm = styled.form`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 300px;
  position: relative;
  input {
    padding-right: 50px;
  }
`;

const CustomButton = styled(Button)`
  position: absolute;
`;

const Styles = styled.div`
  padding: 1rem;
  width: 100%;
  overflow: auto;

  ul {
    justify-content: center;
  }

  table {
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.darkGrey};
    width: 100%;
    font-size: 13px;
    button {
      border: 0;
      margin: 0;
      min-width: 55px;
      color: white;
      background-color: ${(props) => props.theme.mainColor};
      :not(:last-child) {
        margin-right: 5px;
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.mainDarkColor};
      }

      :focus {
        outline: none;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.lightGrey};
      }
    }

    th {
      white-space: nowrap;
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
    td {
      margin: 0;
      padding: 0.5rem;
      white-space: nowrap;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const options = [
  { value: 0, label: "모두 보기" },
  { value: 1, label: "휴대폰 인증 안된 유저만 보기" },
  { value: 2, label: "휴대폰 인증이 완료된 유저만 보기" },
];

const commonOptions = [
  { value: 0, label: "(신)캠프 코인" },
  { value: 1, label: "캠프" },
];

const rewardOptions = [
  { value: 0, label: "(신)캠프 코인으로 보상지급" },
  { value: 1, label: "이더리움으로 보상 지급" },
];
/** Container  */

const web3 = new Web3(
  new Web3.providers.HttpProvider(NODE_ENDPOINT, {
    headers: [],
  })
);

const Container = ({ history, page }) => {
  const { isLogin, sidebarToggle, user, getUser, logoutOnSubmit } = useContext(GlobalContext);
  const actionState = useState("userList");
  const pageState = useState({ pageCount: -1, selected: 1, perPage: ADMIN_PAGE_UNIT, loading: true });
  const tableState = useState();
  const nonceState = useState();
  const gasState = useState({});
  const buttonState = useState(false);
  const contractState = useState();
  const newContract = useState(null);
  const tokenSum = useState(null);
  const errorReward = useState(null);
  const rewardActionType = useState(null);
  const isLoaded = useState(null);
  const actionType = useState(null);
  const radioSelected = useState(1);

  const lockNTime = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });
  const lockMpercent = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });

  const lockAmount = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });
  const lockDay = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });

  const gasSelectedState = useState({
    fastest: false,
    average: true,
    safeLow: false,
  });
  const privateKeyInput = useInput({
    defaultValue: "",
    maxLength: 64,
  });
  const toAddressInput = useInput({
    defaultValue: "",
    maxLength: 42,
  });
  const tokenAmountInput = useInput({
    defaultValue: "",
    maxLength: 64,
    regex: AMOUNT_REGEX,
  });
  const rewardInput = useInput({
    defaultValue: 0,
    maxLength: 30,
    regex: AMOUNT_REGEX,
  });
  const searchInput = useInput({
    defaultValue: "",
    maxLength: 50,
  });

  const timeInput = useInput({ defaultValue: "", maxLength: 64, regex: NUMBER_REGEX });

  const getHeader = () => ({
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });

  const sendReward = async () => {
    if (toast.isActive("sendReward")) {
      return;
    }

    if (privateKeyInput.value === "" || privateKeyInput.value.length !== 64) {
      toast.error("올바른 비밀 키를 입력하세요.", {
        toastId: "sendReward",
        autoClose: 2000,
      });
      return;
    }

    let gasPrice = 0;

    if (gasSelectedState.state.fastest) {
      gasPrice = gasState.state.fastest / 10;
    } else if (gasSelectedState.state.average) {
      gasPrice = gasState.state.average / 10;
    } else if (gasSelectedState.state.safeLow) {
      gasPrice = gasState.state.safeLow / 10;
    }
    const weiGasPrice = web3.utils.toWei(gasPrice.toString(), "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);
    const privateKey = Buffer.from(privateKeyInput.value, "hex");

    const {
      data: { select },
    } = await Axios.post(`${HOST}/admin/reward/all`, {}, getHeader());

    buttonState.setState(true);

    if (select.length === 0) {
      toast.error(`보상을 받을 유저가 없습니다.`, {
        autoClose: 4000,
      });
      return;
    }

    const adminWei = await web3.eth.getBalance(user.state.address);
    const adminTokenWei = await newContract.state.methods.balanceOf(user.state.address).call();
    const adminEther = Number(web3.utils.fromWei(adminWei));
    const adminToken = Number(web3.utils.fromWei(adminTokenWei));

    if (rewardActionType.state !== 0 && rewardActionType.state !== 1) {
      toast.error(`보상 유형을 선택해주세요.`, {
        autoClose: 4000,
      });
      return;
    }

    if (rewardActionType.state === 1 && adminEther < Number(rewardInput.value)) {
      toast.error(`관리자 계정의 잔액이 부족합니다. (현재 잔액 : ${adminEther} eth`, {
        autoClose: 4000,
      });
      return;
    }

    if (rewardActionType.state === 0 && adminToken < Number(rewardInput.value)) {
      toast.error(`관리자 계정의 잔액이 부족합니다. (현재 잔액 : ${adminToken} camp`, {
        autoClose: 4000,
      });
      return;
    }
    let nonce = nonceState.state;
    let calculatedReward = 0;
    let errorToggle = false;

    select.forEach(async (userRow, index) => {
      if (userRow.reward === 1) {
        const reward = calculateReward(userRow);

        const contractData =
          rewardActionType.state === 0
            ? newContract.state.methods.transfer(userRow.walletAddress, web3.utils.toWei(reward)).encodeABI()
            : null;

        const rawTransaction =
          rewardActionType.state === 0
            ? {
                from: user.state.address,
                gasPrice: hexGasPrice,
                gasLimit: web3.utils.toHex(210000),
                to: NEW_CONTRACT_ADDRESS,
                value: "0x0",
                data: contractData,
                nonce: web3.utils.toHex(nonceState.state),
              }
            : {
                from: user.state.address,
                gasPrice: hexGasPrice,
                gasLimit: web3.utils.toHex(210000),
                to: userRow.walletAddress,
                value: web3.utils.toHex(web3.utils.toWei(reward, "ether")),
                chainId: CHAIN_ID,
                nonce: web3.utils.toHex(nonce++),
              };

        const transaction = new EthereumTx(rawTransaction, { chain: CHAIN_NAME });

        try {
          transaction.sign(privateKey);
        } catch (error) {
          toast.error(`트랜잭션 오류입니다. 올바른 개인키를 입력하세요. : ${error}`, {
            autoClose: 4000,
          });
          return;
        }

        web3.eth.sendSignedTransaction("0x" + transaction.serialize().toString("hex"), async (error, hash) => {
          if (error) {
            toast.error(`${userRow.email} 유저에게 보상을 지급하는데 에러가 발생했습니다. : ${error}`, {
              autoClose: 8000,
            });
            errorToggle = true;
            if (index + 1 === select.length) {
              if (errorToggle) {
                const errorSum = Number(rewardInput.value) - Number(calculatedReward);
                const errorAmount = errorSum.toFixed(9);
                const errorAmountResult = errorAmount.substring(0, errorAmount.length - 1);
                const { data } = await Axios.post(
                  `${HOST}/admin/reward/error/update`,
                  { rewardAmount: Number(rewardInput.value), amount: Number(errorAmountResult), status: 0 },
                  getHeader()
                );
              }
              nonceState.setState(nonce);
              resetTable("reward");
              toast.success(`보상이 모든 유저에게 지급되었습니다. 이더스캔에서 확인하세요.`, {
                autoClose: 4000,
              });
            }

            buttonState.setState(false);
          } else {
            const { data } = await Axios.post(`${HOST}/admin/user/unstake`, { userId: userRow.id }, getHeader());
            toast.info(`${userRow.email} : ${hash}`, {
              autoClose: 500,
            });
            calculatedReward = calculatedReward + Number(reward);

            if (index + 1 === select.length) {
              if (errorToggle) {
                const errorSum = Number(rewardInput.value) - Number(calculatedReward);
                const errorAmount = errorSum.toFixed(9);
                const errorAmountResult = errorAmount.substring(0, errorAmount.length - 1);
                const { data } = await Axios.post(
                  `${HOST}/admin/reward/error/update`,
                  { rewardAmount: Number(rewardInput.value), amount: Number(errorAmountResult), status: 0 },
                  getHeader()
                );
              }

              buttonState.setState(false);
              nonceState.setState(nonce);
              resetTable("reward");
              toast.success(`보상이 모든 유저에게 지급되었습니다. 이더스캔에서 확인하세요.`, {
                autoClose: 4000,
              });
            }
          }
        });
      } else {
        if (index + 1 === select.length) {
          buttonState.setState(false);
          resetTable("reward");
          toast.success(`보상이 모든 유저에게 지급되었습니다. 이더스캔에서 확인하세요.`, {
            autoClose: 4000,
          });
        }
      }
    });
  };

  const actionButtonOnClick = (user) => {
    if (toast.isActive("action")) {
      return;
    }

    if (privateKeyInput.value === "" || privateKeyInput.value.length !== 64) {
      toast.error("올바른 비밀 키를 입력하세요.", {
        toastId: "action",
        autoClose: 2000,
      });
      return;
    }

    let gasPrice = 0;

    if (gasSelectedState.state.fastest) {
      gasPrice = gasState.state.fastest / 10;
    } else if (gasSelectedState.state.average) {
      gasPrice = gasState.state.average / 10;
    } else if (gasSelectedState.state.safeLow) {
      gasPrice = gasState.state.safeLow / 10;
    }
    const weiGasPrice = web3.utils.toWei(gasPrice.toString(), "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);
    const privateKey = Buffer.from(privateKeyInput.value, "hex");
    let contractData = {};
    switch (actionState.state) {
      case "stake":
        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods.freezeAccount(user.walletAddress, true).encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods.freezeAccount(user.walletAddress, true).encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }
        break;
      case "userList":
        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods.freezeAccount(user.walletAddress, false).encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods.freezeAccount(user.walletAddress, false).encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }
        break;
      case "airdrop":
        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods
            .transfer(user.walletAddress, web3.utils.toWei(`${user.airdrop * 100}`))
            .encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods
            .transfer(user.walletAddress, web3.utils.toWei(`${user.airdrop * 100}`))
            .encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }

        break;
      case "timeLockForm":
        const second = Number(timeInput.value);
        const gmt = new Date(new Date().toGMTString()).getTime() + SECOND * second;
        const createdAt = gmt * 0.001;

        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods
            .lock(toAddressInput.value, createdAt, web3.utils.toWei(tokenAmountInput.value))
            .encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods
            .lock(toAddressInput.value, createdAt, web3.utils.toWei(tokenAmountInput.value))
            .encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }

        break;
      case "mintForm":
        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods
            .mintToken(toAddressInput.value, web3.utils.toWei(tokenAmountInput.value))
            .encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods
            .mintToken(toAddressInput.value, web3.utils.toWei(tokenAmountInput.value))
            .encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }

        break;
      case "burnForm":
        if (actionType.state === 0) {
          //신토큰
          contractData = newContract.state.methods.burn(web3.utils.toWei(tokenAmountInput.value)).encodeABI();
        } else if (actionType.state === 1) {
          //구토큰
          contractData = contractState.state.methods.burn(web3.utils.toWei(tokenAmountInput.value)).encodeABI();
        } else {
          //선택x
          return toast.error("관리할 토큰 타입을 선택해주세요.", {
            toastId: "action",
            autoClose: 2000,
          });
        }
        break;
      default:
        history.push("/");
        break;
    }
    const rawTransaction = {
      from: user.state.address,
      gasPrice: hexGasPrice,
      gasLimit: web3.utils.toHex(210000),
      to: actionType.state === 0 ? NEW_CONTRACT_ADDRESS : CONTRACT_ADDRESS,
      value: "0x0",
      data: contractData,
      nonce: web3.utils.toHex(nonceState.state),
    };

    const config = getHeader();
    const transaction = new EthereumTx(rawTransaction, { chain: CHAIN_NAME });

    buttonState.setState(true);
    try {
      transaction.sign(privateKey);
    } catch (error) {
      toast.error(`트랜잭션 오류입니다. 올바른 개인키를 입력하세요. : ${error}`, {
        autoClose: 4000,
      });
      buttonState.setState(false);
      return;
    }
    web3.eth.sendSignedTransaction("0x" + transaction.serialize().toString("hex"), (error, hash) => {
      switch (actionState.state) {
        case "stake":
          if (error) {
            toast.error(`트랜잭션 오류 : ${error}`, {
              autoClose: 4000,
            });
          } else {
            nonceState.setState(nonceState.state + 1);
            stakeConfirm(user);
          }
          buttonState.setState(false);
          break;
        case "userList":
          if (error) {
            toast.error(`트랜잭션 오류 : ${error}`, {
              autoClose: 4000,
            });
          } else {
            nonceState.setState(nonceState.state + 1);
            unStake(user);
          }
          buttonState.setState(false);
          break;
        case "airdrop":
          if (error) {
            toast.error(`트랜잭션 오류 : ${error}`, {
              autoClose: 4000,
            });
          } else {
            nonceState.setState(nonceState.state + 1);
          }
          buttonState.setState(false);
          break;
        default:
          if (error) {
            toast.error(`트랜잭션 오류 : ${error}`, {
              autoClose: 4000,
            });
            buttonState.setState(false);
          } else {
            toast.success(`트랜잭션 전송 완료 : ${hash}`, {
              autoClose: 2000,
            });
            window.open(`${ETHERSCAN_TX_URL}${hash}`);
            nonceState.setState(nonceState.state + 1);
            buttonState.setState(false);
          }
          break;
      }
    });
  };

  const userInit = (search) => {
    pageState.setState({
      ...pageState.state,
      userList: { ...pageState.state.userList, loading: true },
    });
    Axios.post(`${HOST}/admin/users`, { UNIT: ADMIN_PAGE_UNIT, search, init: true }, getHeader()).then((response) => {
      tableState.setState({
        ...tableState.state,
        userList: response.data.userList.list,
      });
      pageState.setState({
        ...pageState.state,
        userList: {
          ...pageState.state.userList,
          loading: false,
          pageCount: Math.ceil(response.data.userList.count / ADMIN_PAGE_UNIT),
        },
      });
    });
  };

  const stakeInit = (search) => {
    pageState.setState({
      ...pageState.state,
      stakeList: { ...pageState.state.stakeList, loading: true },
    });
    Axios.post(`${HOST}/admin/stake`, { UNIT: ADMIN_PAGE_UNIT, search, init: true }, getHeader()).then((response) => {
      tableState.setState({
        ...tableState.state,
        stakeList: response.data.stakeList.list,
      });
      pageState.setState({
        ...pageState.state,
        stakeList: {
          ...pageState.state.stakeList,
          loading: false,
          pageCount: Math.ceil(response.data.stakeList.count / ADMIN_PAGE_UNIT),
        },
      });
    });
  };

  const rewardInit = (search) => {
    pageState.setState({
      ...pageState.state,
      rewardList: { ...pageState.state.rewardList, loading: true },
    });
    Axios.post(`${HOST}/admin/reward`, { UNIT: ADMIN_PAGE_UNIT, search, init: true }, getHeader()).then((response) => {
      tokenSum.setState(response.data.rewardList.sum);
      tableState.setState({
        ...tableState.state,
        rewardList: response.data.rewardList.list,
      });
      pageState.setState({
        ...pageState.state,
        rewardList: {
          ...pageState.state.rewardList,
          loading: false,
          pageCount: Math.ceil(response.data.rewardList.count / ADMIN_PAGE_UNIT),
        },
      });
    });
  };

  const searchOnSubmit = (e) => {
    e.preventDefault();
    userInit(searchInput.value);
  };

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    const config = getHeader();
    pageState.setState({ ...pageState.state, loading: true, selected: page });
    Axios.post(
      `${HOST}/admin/${actionState.state === "userList" ? "users" : actionState.state}`,
      { selected: page, perPage: ADMIN_PAGE_UNIT, init: false },
      config
    ).then((response) => {
      switch (actionState.state) {
        case "userList":
          tableState.setState({
            ...tableState.state,
            userList: response.data.select,
          });
          pageState.setState({
            ...pageState.state,
            userList: {
              ...pageState.state.userList,
              loading: false,
              pageCount: Math.ceil(response.data.count / ADMIN_PAGE_UNIT),
            },
          });
          break;
        case "stake":
          tableState.setState({
            ...tableState.state,
            stakeList: response.data.select,
          });
          pageState.setState({
            ...pageState.state,
            stakeList: {
              ...pageState.state.stakeList,
              loading: false,
              pageCount: Math.ceil(response.data.count / ADMIN_PAGE_UNIT),
            },
          });
          break;

        case "reward":
          tokenSum.setState(response.data.sum);
          tableState.setState({
            ...tableState.state,
            rewardList: response.data.select,
          });
          pageState.setState({
            ...pageState.state,
            rewardList: {
              ...pageState.state.rewardList,
              loading: false,
              pageCount: Math.ceil(response.data.count / ADMIN_PAGE_UNIT),
            },
          });
          break;
        default:
          break;
      }
    });
  };

  const stakeConfirm = async (user) => {
    const config = getHeader();
    const { data } = await Axios.post(`${HOST}/admin/stake/confirm`, { userId: user.id }, config);
    if (data.status) {
      toast.success(`스테이킹 트랜잭션이 승인되었습니다. 실 승인까지 몇분정도 소요될 수 있습니다.`, {
        autoClose: 4000,
      });
      resetTable("stake");
    } else {
      toast.error(`서버 에러입니다. 관리자에게 문의하세요.`);
    }
  };

  const stakeRejection = async (user) => {
    const config = getHeader();
    const { data } = await Axios.post(`${HOST}/admin/stake/rejection`, { userId: user.id }, config);
    if (data.status) {
      toast.success(`스테이킹이 거절되었습니다.`);
      resetTable("stake");
    } else {
      toast.error(`서버 에러입니다. 관리자에게 문의하세요.`);
    }
  };

  const deleteUser = async (user) => {
    if (toast.isActive("deleteUser")) {
      return;
    }
    if (privateKeyInput.value !== "delete") {
      toast.error(`유저 삭제를 위해 비밀키 입력창에 delete 를 입력해주세요.`, {
        toastId: "deleteUser",
        autoClose: 4000,
      });
      return;
    }

    const { data } = await Axios.post(`${HOST}/admin/user/delete`, { userId: user.id }, getHeader());
    if (data.status) {
      toast.success(`${user.email} 유저가 삭제되었습니다.`, { toastId: "deleteUser", autoClose: 4000 });
    } else {
      toast.error(`서버 에러입니다. 관리자에게 문의하세요.`, { toastId: "deleteUser" });
    }
    resetTable("userList");
  };

  const unStake = async (user) => {
    const { data } = await Axios.post(`${HOST}/admin/user/unstake`, { userId: user.id }, getHeader());
    if (data.status) {
      toast.success(`${user.email} 유저의 지갑 락을 해제하고 스테이킹을 초기화했습니다.`, {
        toastId: "unStake",
        autoClose: 4000,
      });
    } else {
      toast.error(`서버 에러입니다. 관리자에게 문의하세요.`, { toastId: "unStake" });
    }
    resetTable("userList");
  };

  const errorRewardInit = () => {
    Axios.post(`${HOST}/admin/reward/error`, {}, getHeader())
      .then((response) => {
        errorReward.setState(response.data.select);
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          logoutOnSubmit();
          toast.error("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", {
            toastId: "jwt",
            autoClose: 4000,
          });
        }
      });
  };

  const updateUserWallet = async (row) => {
    if (privateKeyInput.value !== "wallet") {
      toast.error(`유저 지갑 재발행을 위해 비밀키 입력창에 wallet 을 입력해주세요`, {
        toastId: "deleteError",
        autoClose: 4000,
      });
      return;
    }

    const { data } = await Axios.post(`${HOST}/admin/wallet`, { userId: row.id }, getHeader());
    if (data.status) {
      toast.success(`선택한 유저의 지갑이 재발행되었습니다.`, {
        toastId: "deleteError",
        autoClose: 4000,
      });
      resetTable("userList");
      return;
    } else {
      toast.error(`서버 에러입니다. 관리자에게 문의하세요.`, { toastId: "deleteUser" });
    }
  };

  const walletLockUp = async () => {};

  const deleteError = async () => {
    if (toast.isActive("deleteError")) {
      return;
    }
    if (privateKeyInput.value !== "delete") {
      toast.error(`에러 이력 삭제를 위해 비밀키 입력창에 delete 를 입력해주세요.`, {
        toastId: "deleteError",
        autoClose: 4000,
      });
      return;
    }
    const { data } = await Axios.post(
      `${HOST}/admin/reward/error/update`,
      { rewardAmount: 0, amount: 0, status: 1 },
      getHeader()
    );

    toast.success(`에러 이력이 삭제되었습니다.`, {
      toastId: "deleteError",
      autoClose: 4000,
    });
    resetTable("reward");
  };

  useEffect(() => {
    const config = getHeader();
    Axios.post(`${HOST}/user/info`, {}, config)
      .then((response) => {
        user.setState(response.data.user);

        const tokenContract = new web3.eth.Contract(contract, CONTRACT_ADDRESS, { from: response.data.user.address });
        const newTokenContract = new web3.eth.Contract(contract, NEW_CONTRACT_ADDRESS, {
          from: response.data.user.address,
        });
        newContract.setState(newTokenContract);
        contractState.setState(tokenContract);
        web3.eth.getTransactionCount(response.data.user.address).then((count) => {
          nonceState.setState(count);
        });
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          logoutOnSubmit();
          toast.error("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", {
            toastId: "jwt",
            autoClose: 4000,
          });
        }
      });
    errorRewardInit();
    Axios.post(GAS_PRICE_API).then((response) => {
      gasState.setState(response.data);
    });
  }, []);

  const resetTable = (action) => {
    switch (action) {
      case "userList":
        userInit();
        break;
      case "stake":
        stakeInit();
        break;
      case "reward":
        rewardInit();
        errorRewardInit();
        break;
      default:
        break;
    }
  };

  const calculateReward = (data) => {
    const one = tokenSum.state / 100;
    const percent = (data.stakedAmount / one).toFixed(9);
    const percentResult = percent.substring(0, percent.length - 1);

    const ethOne = rewardInput.value / 100;
    const rewardEth = (ethOne * percentResult).toFixed(9);
    const rewardEthResult = rewardEth.substring(0, rewardEth.length - 1);
    return rewardEthResult;
  };

  const columns = {
    userList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "E-Mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "추천인 코드",
        dataIndex: "referralCode",
        key: "referralCode",
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "전화번호",
        dataIndex: "phone",
        key: "phone",
        render: (phone) => <>{phone}</>,
      },
      {
        title: "메일인증",
        dataIndex: "emailConfirmed",
        key: "emailConfirmed",
        render: (emailConfirmed) => <>{emailConfirmed === 0 ? "X" : "O"}</>,
      },
      {
        title: "휴대폰인증",
        dataIndex: "phoneConfirmed",
        key: "phoneConfirmed",
        render: (phoneConfirmed) => <>{phoneConfirmed === 0 ? "X" : "O"}</>,
      },
      {
        title: "KYC인증",
        dataIndex: "kycAuth",
        key: "kycAuth",
        render: (kycAuth) => <>{kycAuth === 0 ? "X" : "O"}</>,
      },
      {
        title: "추천인",
        dataIndex: "recommend",
        key: "recommend",
      },
      {
        title: "가입일",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => <>{dateConvertor(new Date(createdAt))}</>,
      },
      {
        title: "락업된 금액",
        dataIndex: "locked_amount",
        key: "locked_amount",
        render: (item) => <>{numberWithCommas(Number(item))} </>,
      },
      {
        title: "지갑주소",
        dataIndex: "walletAddress",
        key: "walletAddress",
      },
      {
        title: "스테이킹 갯수",
        dataIndex: "stakedAmount",
        key: "stakedAmount",
        render: (stakedAmount) => <>{!stakedAmount ? "-" : stakedAmount}</>,
      },
      {
        title: "관리",
        key: "manage",
        render: (data) => {
          const lockOnClick = async (close) => {
            if (toast.isActive("lockup")) {
              return;
            }

            if (lockAmount.value === "" || lockDay.value === "") {
              toast.error("입력값이 없습니다.", {
                toastId: "lockup",
              });
              return;
            }

            if (isNaN(Number(lockAmount.value)) || isNaN(Number(lockDay.value))) {
              toast.error("숫자를 입력해주세요.", {
                toastId: "lockup",
              });
              return;
            }

            let requestBody = {
              userId: data.id,
              amount: Number(lockAmount.value),
              day: Number(lockDay.value),
              type: "normal",
            };

            if (radioSelected.state === 2) {
              if (lockNTime.value === "" || lockMpercent.value === "") {
                toast.error("입력값이 없습니다.", {
                  toastId: "lockup",
                });
                return;
              }

              if (isNaN(Number(lockMpercent.value)) || isNaN(Number(lockMpercent.value))) {
                toast.error("숫자를 입력해주세요.", {
                  toastId: "lockup",
                });
                return;
              }

              requestBody.lockNTime = lockNTime.value;
              requestBody.lockMpercent = lockMpercent.value;
              requestBody.type = "detail";
            }
            const { data: result } = await Axios.post(`${HOST}/admin/users/lock`, requestBody, getHeader());
            if (result.status) {
              close();
              lockAmount.setValue("");
              lockDay.setValue("");
              resetTable("userList");
              toast.success("락업이 완료되었습니다.", { toastId: "lockup" });
            }
          };

          return (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Popup
                trigger={
                  <button
                    style={{ padding: 5, backgroundColor: "green" }}
                    type="button"
                    onClick={lockOnClick.bind(null, data)}
                  >
                    락업
                  </button>
                }
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <PopUpForm>
                    <ButtonGroup style={{ marginBottom: 15 }}>
                      <Button
                        color="primary"
                        onClick={() => radioSelected.setState(1)}
                        active={radioSelected.state === 1}
                      >
                        일반 락업
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => radioSelected.setState(2)}
                        active={radioSelected.state === 2}
                      >
                        상세 락업
                      </Button>
                    </ButtonGroup>
                    <Input
                      placeholder={"락업 갯수"}
                      style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                      {...lockAmount}
                    />
                    <Input
                      placeholder={"락업 시간 (일 단위)"}
                      style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                      {...lockDay}
                    />

                    {radioSelected.state === 1 && (
                      <Alert
                        style={{ whiteSpace: "normal", width: "100%", fontSize: 12, wordBreak: "word-break" }}
                        color="primary"
                      >
                        * 일반 락업은 락업시간(일단위)가 지나면 전체 금액에 대한 락업이 해제됩니다.
                      </Alert>
                    )}
                    {radioSelected.state === 2 && (
                      <>
                        <Alert
                          style={{ whiteSpace: "normal", width: "100%", fontSize: 12, wordBreak: "word-break" }}
                          color="primary"
                        >
                          * 상세 락업은 락업시간(일단위) 이후에 바로 락업이 풀리지 않습니다. 락업 시간이 끝나는 시점에
                          전체 금액의 m%만큼의 락업이 해제됩니다. 이는 n일마다 m%의 금액이 전체 락업 금액을 넘어설
                          때까지 지속됩니다.
                        </Alert>
                        <Input
                          placeholder={"n (락업 시간 이후에 n일 마다)"}
                          style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                          {...lockNTime}
                        />
                        <Input
                          placeholder={"m (m% 씩 락업을 해제합니다.)"}
                          style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                          {...lockMpercent}
                        />
                      </>
                    )}
                    <Button
                      onClick={lockOnClick.bind(null, close)}
                      block={true}
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    >
                      락업
                    </Button>
                  </PopUpForm>
                )}
              </Popup>

              <button style={{ padding: 5 }} type="button" onClick={updateUserWallet.bind(null, data)}>
                지갑 재발행
              </button>
              <button style={{ padding: 5 }} type="button" onClick={deleteUser.bind(null, data)}>
                유저삭제
              </button>
              {data.isStaked === 2 && (
                <button style={{ padding: 5 }} type="button" onClick={actionButtonOnClick.bind(null, data)}>
                  스테이킹 해제(보상 지급 X)
                </button>
              )}
            </div>
          );
        },
      },
    ],
    stakeList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "E-Mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "지갑주소",
        dataIndex: "walletAddress",
        key: "walletAddress",
      },
      {
        title: "스테이킹할 토큰 갯수",
        dataIndex: "stakedAmount",
        key: "stakedAmount",
        render: (data) => <>{Number(data)}</>,
      },
      {
        title: "날짜",
        dataIndex: "stakedAt",
        key: "stakedAt",
        render: (stakedAt) => <>{dateConvertor(new Date(stakedAt))}</>,
      },
      {
        title: "보상 지급일",
        dataIndex: "rewardTime",
        key: "rewardTime",
        render: (rewardTime) => <>{rewardTime ? dateConvertor(new Date(rewardTime)) : "-"}</>,
      },
      {
        title: "보상 지급 유무",
        dataIndex: "rewardTime",
        key: "rewardTime",
        render: (rewardTime) => (
          <>
            {rewardTime ? <span style={{ color: "green" }}>지급됨</span> : <span style={{ color: "red" }}>미지급</span>}
          </>
        ),
      },
    ],
    rewardList: [
      {
        title: "유저 E-Mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "보상이 지급될 지갑주소",
        dataIndex: "walletAddress",
        key: "walletAddress",
      },
      {
        title: "스테이킹한 갯수",
        dataIndex: "stakedAmount",
        key: "stakedAmount",
      },
      {
        title: "백분율",
        key: "percent",
        render: (data) => {
          const one = tokenSum.state / 100;
          const percent = (data.stakedAmount / one).toFixed(2);
          return <>{percent}%</>;
        },
      },
      {
        title: "예상 보상 수령액",
        key: "reward",
        render: (data) => {
          const rewardEthResult = calculateReward(data);
          return <>{rewardEthResult}</>;
        },
      },
      {
        title: "보상 여부",
        dataIndex: "reward",
        key: "rewardStatus",
        render: (data) =>
          data === 0 ? (
            <span style={{ color: "red" }}>미신청</span>
          ) : (
            <span style={{ color: "green" }}>보상신청됨</span>
          ),
      },
    ],
  };

  useEffect(() => {
    const preload = async () => {
      const me = await getUser();

      if (me.isAdmin === 0) {
        history.push("/");
      } else {
        isLoaded.setState(true);
        switch (actionState.state) {
          case "userList":
            userInit();
            break;
          case "stake":
            stakeInit();
            break;
          case "reward":
            rewardInit();
            break;
          default:
            break;
        }
      }
    };
    preload();
  }, [actionState.state]);

  const handleChange = ({ value, label }) => {
    actionType.setState(value);
  };

  const handleRewardChange = ({ value, label }) => {
    rewardActionType.setState(value);
  };

  return (
    <Wrapper>
      {isLoaded.state && (
        <>
          <ReactTooltip multiline={true} place="top" />
          <Helmet>
            <title>Admin | CAMP</title>
          </Helmet>
          <AdminSideBar page={actionState} />
          <Main>
            <AdminHeader page={actionState.state} />
            <MainContent>
              <Content>
                <div style={{ width: 300 }}>
                  <Select
                    onChange={handleChange}
                    isClearable={false}
                    isSearchable={false}
                    styles={{}}
                    placeholder={"관리 토큰 유형"}
                    options={commonOptions}
                  />
                </div>
                {actionState.state === "registerEvent" && <RegisterEvent />}
                {actionState.state !== "registerEvent" && (
                  <InputForm>
                    <Label>비밀 키</Label>
                    <TransferInput
                      data-tip="컨트랙트에서 관리자 계정으로 <br/> 지정한 지갑 주소의 비밀키를 입력하세요. "
                      placeholder="관리자 지갑 비밀 키"
                      type="password"
                      {...privateKeyInput}
                    />
                    <GasSelector gasState={gasState} gasSelectedState={gasSelectedState} />
                  </InputForm>
                )}
                {actionState.state === "kyc" && <AdminKycPage />}
                {actionState.state === "userList" && (
                  <>
                    <Styles>
                      {tableState.state && tableState.state.userList && (
                        <>
                          <Table rowKey={({ id }) => id} columns={columns.userList} data={tableState.state.userList} />
                          {pageState.state.userList && (
                            <>
                              <PageWrapper>
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={pageState.state.userList.pageCount}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={5}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  subContainerClassName={"pages pagination"}
                                  activeClassName={"active"}
                                />
                                <CustomForm onSubmit={searchOnSubmit}>
                                  <CustomInput placeholder="E-Mail로 유저 검색" {...searchInput} />
                                  <CustomButton size="sm">검색</CustomButton>
                                </CustomForm>
                              </PageWrapper>
                            </>
                          )}
                        </>
                      )}
                    </Styles>
                  </>
                )}
                {actionState.state === "airdrop" && (
                  <Airdrop
                    airdropProps={{ contractState, privateKeyInput, gasSelectedState, gasState, web3, nonceState }}
                  />
                )}
                {actionState.state === "stake" && (
                  <>
                    <Styles>
                      {tableState.state && tableState.state.stakeList && (
                        <>
                          <Table
                            rowKey={({ id }) => id}
                            columns={columns.stakeList}
                            data={tableState.state.stakeList}
                          />
                          {pageState.state.stakeList && (
                            <PageWrapper>
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageState.state.stakeList.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </PageWrapper>
                          )}
                        </>
                      )}
                    </Styles>
                  </>
                )}
                {actionState.state === "lockList" && (
                  <LogPage
                    adminPage={page}
                    tableName="_lockup"
                    columnName="lockupList"
                    searchList={[
                      ["id", "번호"],
                      ["user_id", "유저 번호"],
                    ]}
                  />
                )}
                {actionState.state === "reward" && (
                  <>
                    <StakeInfo>
                      스테이킹 총량{" "}
                      <strong>{tokenSum.state ? numberWithCommas(`${Number(tokenSum.state)}`) : "0"}</strong> CAMP
                    </StakeInfo>
                    <div style={{ width: 300 }}>
                      <Select
                        onChange={handleRewardChange}
                        isClearable={false}
                        isSearchable={false}
                        styles={{}}
                        placeholder={"지급할 보상 유형"}
                        options={rewardOptions}
                      />
                    </div>
                    <StakeInfo>
                      <div>보상할 코인의 총 갯수를 입력하세요.</div>
                      <Input {...rewardInput} placeholder="보상 이더 총량" />
                      <div style={{ fontSize: 12, marginTop: 10, color: "red", textAlign: "center" }}>
                        * 보상 지급 전에 반드시 한번 새로고침 해주세요.
                      </div>
                      <div style={{ fontSize: 12, color: "red", textAlign: "center" }}>
                        * 보상 지급을 위한 수수료가 충분한지 확인하세요.
                      </div>
                      <div style={{ fontSize: 12, color: "red", textAlign: "center" }}>
                        * 보상 지급 도중 에러가 발생할 시 처음 입력했던 보상 금액(A)에 에러가 발생한 출금액(B)을 뺀
                        금액(A-B)을 디비에 저장합니다.
                        <br />
                        에러 이력에 밑줄쳐진 금액을 입력하고 보상 버튼을 누르면 처음 입력했던 보상 예상 금액과 동일하게
                        정상적으로 출금됩니다.
                      </div>
                    </StakeInfo>
                    <Button onClick={sendReward} block style={{ maxWidth: 500, backgroundColor: Theme.mainDarkColor }}>
                      보상 지급
                    </Button>
                    <div
                      style={{
                        fontSize: 13,
                        color: Theme.mainDarkColor,
                        textAlign: "center",
                        marginTop: 10,
                        paddingTop: 10,
                        borderTop: "1px solid #eaeaea",
                      }}
                    >
                      {errorReward.state &&
                        errorReward.state.status === 1 &&
                        `보상 지급 중 에러가 발생하여 지급이 중단된 내역이 없습니다.`}
                      {errorReward.state && errorReward.state.status === 0 && (
                        <>
                          {dateConvertor(new Date(errorReward.state.createdAt))} 에 {errorReward.state.rewardAmount}{" "}
                          eth를 보상으로 지급 중에 에러가 발생한 이력이 있습니다.
                          <br />
                          수수료가 충분한지 등의 정보를 다시 확인하시어{" "}
                          <strong style={{ textDecoration: "underline" }}>{errorReward.state.amount}</strong>개의 코인을
                          입력하시고 보상 지급 버튼을 누르시면 초기에 백분율로 나눠진 금액과 동일한 금액으로 출금
                          가능합니다.
                          <div>
                            <Button onClick={deleteError}>에러 이력 삭제</Button>
                          </div>
                        </>
                      )}
                    </div>
                    <Styles>
                      {tableState.state && tableState.state.rewardList && (
                        <>
                          <Table
                            rowKey={({ id }) => id}
                            columns={columns.rewardList}
                            data={tableState.state.rewardList}
                          />
                          {pageState.state.rewardList && (
                            <PageWrapper>
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageState.state.rewardList.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                              />
                            </PageWrapper>
                          )}
                        </>
                      )}
                    </Styles>
                  </>
                )}
                {actionState.state === "timeLockForm" && (
                  <div style={{ width: 500 }}>
                    <ReactTooltip multiline={true} place="top" />
                    <Label>토큰 잠금 시간(초 단위)</Label>
                    <TransferInput
                      data-tip="지정한 시간만큼 토큰을 락(lock)합니다. <br/> 1시간 : 3,600 <br/> 1일 : 86,400 <br/> 1달(30일) : 2,592,000 "
                      placeholder="초 단위로 입력하세요."
                      type="text"
                      {...timeInput}
                    />
                    <Label>락(lock) 시킬 지갑주소</Label>
                    <TransferInput placeholder="Wallet address" type="text" {...toAddressInput} />
                    <Label>금액</Label>
                    <AmountInput tokenAmountInput={tokenAmountInput} />
                    <InputForm>
                      <Button block disabled={buttonState.state} onClick={actionButtonOnClick}>
                        토큰 잠금
                      </Button>
                    </InputForm>
                  </div>
                )}
                {actionState.state === "mintForm" && (
                  <div style={{ width: 500 }}>
                    <ReactTooltip multiline={true} place="top" />
                    <Label>받는 사람 주소</Label>
                    <TransferInput
                      data-tip="추가 발행을 받고 싶은 지갑 주소입니다. <br/> 이곳에 입력된 주소로 발행됩니다."
                      placeholder="Wallet address"
                      type="text"
                      {...toAddressInput}
                    />
                    <Label>금액</Label>
                    <AmountInput tokenAmountInput={tokenAmountInput} />
                    <InputForm>
                      <Button block disabled={buttonState.state} onClick={actionButtonOnClick}>
                        추가 발행
                      </Button>
                    </InputForm>
                    <Caption>1. 추가 발행은 전체 토큰 발행량에 영향을 미칩니다.</Caption>
                    <Caption>2. 되도록 받는 지갑 주소는 관리자 주소로 사용하세요.</Caption>
                    <Caption>
                      3. 관리자 계정에 등록된 지갑주소가 컨트랙트 어드민 주소일 경우에만 트랜잭션이 승인됩니다.
                      <br /> 즉, 임의로 관리자를 추가하여 기능을 사용하면 트랜잭션이 승인되지 않을 수 있습니다.
                    </Caption>
                  </div>
                )}
                {actionState.state === "burnForm" && (
                  <div style={{ width: 500 }}>
                    <ReactTooltip multiline={true} place="top" />
                    <Label>소각시킬 금액</Label>
                    <AmountInput tokenAmountInput={tokenAmountInput} />
                    <InputForm>
                      <Button block disabled={buttonState.state} onClick={actionButtonOnClick}>
                        소각
                      </Button>
                    </InputForm>
                    <Caption>1. 소각은 전체 토큰 발행량에 영향을 미칩니다.</Caption>
                    <Caption>2. 소각은 관리자 지갑에서 이루어집니다.</Caption>
                    <Caption>3. 소각 금액은 해당 지갑 주소의 잔액보다 많아야 합니다.</Caption>
                  </div>
                )}
              </Content>
            </MainContent>
          </Main>
        </>
      )}
    </Wrapper>
  );
};

export default Container;
