import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import GlobalContext from "../../Hooks/globalContext";
import { languagePack, languageSelect } from "../../Utils/language";
import HeaderLogoWhite from "../../Resources/Images/header_logo2.png";
import { CloseIcon, RightArrowIcon } from "./Icons";
import Theme from "../../Styles/Theme";

const Sidebar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ElementWrapper = styled.div`
  width: 100%;
`;

const ElementAttribute = `
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12.5%;
  padding-right: 6.25%;
  color: ${Theme.mainColor};
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid ${Theme.navyDarkGrey}
  :hover {
    opacity: 0.25;
    color: ${(props) => props.theme.mainColor};
    text-decoration: none;}
  
`;

const TokenElementLink = styled(Link)`
  ${ElementAttribute} :hover {
    opacity: 0.25;
    color: ${(props) => props.theme.mainColor};
    text-decoration: none;
  }
`;

const TokenAdminElementLink = styled(TokenElementLink)`
  background-color: rgba(0, 0, 0, 0.1);
`;

const Element = styled.div`
  ${ElementAttribute}
`;

const SideBarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0px;
  padding: 0px 15px;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.navyDarkGrey};
`;

const LanguageSelect = styled.select`
  color: white;
  border: 0;
  :focus {
    outline: none;
  }
  background-size: 10px;
  background-color: ${(props) => props.theme.mainColor};
  padding: 5px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 15px;
  border-radius: 20px;
  margin-right: 15px;
`;

const HeaderRow = ({ onClick, language, text, to }) => (
  <TokenElementLink onClick={onClick} to={to}>
    {text}
    <RightArrowIcon color={Theme.mainColor} size={18} />
  </TokenElementLink>
);

const Container = ({ user, isLogin, logoutOnSubmit, sidebarToggle, language }) => {
  const { languageOnChange } = useContext(GlobalContext);
  return (
    <Sidebar>
      {user.state && user.state.email && isLogin.state && (
        <>
          <SideBarHeader>
            <img style={{ width: 110, height: 32 }} src={HeaderLogoWhite} />
            <div>
              <LanguageSelect onChange={languageOnChange}>
                <option value="">language</option>
                <option value="english">{languageSelect("English", language.state)}</option>
                <option value="korean">{languageSelect("한국어", language.state)}</option>
              </LanguageSelect>
              <CloseIcon onClick={sidebarToggle} size={20} />
            </div>
          </SideBarHeader>
          {/* <PasswordReset
            onClick={() => {
              sidebarToggle();
              historyFunction.state.push("/user/password");
            }}
          >
            {languagePack("비밀번호 변경", language.state)}
          </PasswordReset> */}
          <ElementWrapper>
            <HeaderRow text={languagePack("홈", language.state)} to="/" onClick={sidebarToggle} language={language} />
            <HeaderRow
              text={languagePack("히스토리", language.state)}
              to="/history/eth"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={`${languagePack("히스토리", language.state)} (CAMP)`}
              to="/history/camp"
              onClick={sidebarToggle}
              language={language}
            />
            {/* <HeaderRow
              text={`${languagePack("히스토리", language.state)} (CAMP COIN)`}
              to="/history/camp"
              onClick={sidebarToggle}
              language={language}
            /> */}
            <HeaderRow
              text={languagePack("송금", language.state)}
              to="/transfer"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={languagePack("스테이킹", language.state)}
              to="/stake"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={languagePack("비밀번호 변경", language.state)}
              to="/user/password"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={languagePack("추천인 코드", language.state)}
              to="/user/referral"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={languagePack("전화번호 인증", language.state)}
              to="/user/authentication/phone"
              onClick={sidebarToggle}
              language={language}
            />
            <HeaderRow
              text={languagePack("KYC 인증", language.state)}
              to="/user/authentication/kyc"
              onClick={sidebarToggle}
              language={language}
            />
            {/* <HeaderRow text={"OTP"} to="/user/otp" onClick={sidebarToggle} language={language} /> */}
            <Element>
              <a style={{ color: Theme.mainColor }} href="https://camp-global.com">
                {languagePack("CAMP 홈페이지 바로가기", language.state)}
              </a>
            </Element>
            <Element
              onClick={() => {
                sidebarToggle();
                logoutOnSubmit();
              }}
            >
              {languagePack("로그아웃", language.state)}
            </Element>
            {user.state.isAdmin === 1 && (
              <TokenAdminElementLink onClick={sidebarToggle} to="/admin">
                관리자페이지
              </TokenAdminElementLink>
            )}
          </ElementWrapper>
        </>
      )}
    </Sidebar>
  );
};

export default Container;
