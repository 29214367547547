import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { BounceLoader, ClipLoader, FadeLoader, MoonLoader, SyncLoader, PropagateLoader } from "react-spinners";

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FitLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Loader = ({ type = "BounceLoader", size = 60, color = "black", fit = false }) =>
  fit ? (
    <FitLoaderWrapper>
      {type === "BounceLoader" && <BounceLoader size={size} />}
      {type === "MoonLoader" && <MoonLoader size={size} />}
      {type === "SyncLoader" && <SyncLoader size={size} color={color} />}
      {type === "PropagateLoader" && <PropagateLoader size={size} />}
      {type === "FadeLoader" && <FadeLoader />}
      {type === "ClipLoader" && <ClipLoader size={15} />}
    </FitLoaderWrapper>
  ) : (
    <LoaderWrapper>
      {type === "BounceLoader" && <BounceLoader size={size} />}
      {type === "MoonLoader" && <MoonLoader size={size} />}
      {type === "SyncLoader" && <SyncLoader size={size} color={color} />}
      {type === "PropagateLoader" && <PropagateLoader size={size} />}
      {type === "FadeLoader" && <FadeLoader />}
      {type === "ClipLoader" && <ClipLoader size={15} />}
    </LoaderWrapper>
  );

export default Loader;
