import React, { useContext } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";

import { languagePack } from "../../../Utils/language";
import { SquareIcon } from "../../../Components/Common/Icons";
import Theme from "../../../Styles/Theme";
import Input from "../../../Components/Common/Input";
import { Button } from "reactstrap";
import useInput from "../../../Hooks/useInput";
import useState from "../../../Hooks/useState";
import { HOST, CLIENT_HOST } from "../../../Utils/constants";
import globalContext from "../../../Hooks/globalContext";
import PageWrapper from "../../../Components/Common/PageWrapper";

const ReferralWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Referral = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Title = styled.span`
  margin-left: 10px;
`;

const InputWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;
`;

const ReferralPage = () => {
  const { user, language } = useContext(globalContext);
  const referralInput = useInput({ defaultValue: "", maxLength: 15 });
  const isLoading = useState(false);

  const onCopy = ({ copy }) => {
    if (toast.isActive("copy")) {
      return;
    }

    toast.info(
      languagePack(
        copy === "address" ? `추천인 코드가 복사되었습니다.` : `추천인 링크가 복사되었습니다.`,
        language.state
      ),
      {
        toastId: "copy",
        position: toast.POSITION.BOTTOM_RIGHT,
      }
    );
  };

  const applyOnClick = async () => {
    if (toast.isActive("referral")) {
      return;
    }
    if (referralInput.value === "") {
      toast.error(
        languagePack("잘못된 코드입니다.", language.state, {
          toastId: "referral",
        })
      );
      return;
    }
    if (referralInput.value === user.state.referralCode) {
      toast.error(
        languagePack("자기 자신은 등록할 수 없습니다.", language.state, {
          toastId: "referral",
        })
      );
      return;
    }

    isLoading.setState(true);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    const { data } = await axios.post(`${HOST}/user/referral`, { recommend: referralInput.value }, config);

    if (data.status) {
      toast.success(languagePack("추천인 코드를 등록했습니다.", language.state), {
        toastId: "referral",
      });
    } else {
      switch (data.code) {
        case "already":
          toast.error(languagePack("이미 등록된 추천인 코드가 있습니다.", language.state), {
            toastId: "referral",
          });
        case "invalid":
          toast.error(languagePack("잘못된 코드입니다.", language.state), {
            toastId: "referral",
          });
        case "server":
          toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
            toastId: "referral",
          });
      }
    }

    isLoading.setState(false);
  };

  return (
    <PageWrapper>
      <ReferralWrapper>
        <Referral>
          <SquareIcon color={Theme.mainColor} size={6} />
          <Title>{languagePack("추천인 코드를 받았나요?", language.state)}</Title>
        </Referral>
        <InputWrapper>
          <Input placeholder="referral code" {...referralInput} />
        </InputWrapper>
        <Button
          style={{
            marginTop: 10,
            marginBottom: 25,
            border: 0,
            borderRadius: 0,
            backgroundColor: Theme.mainColor,
            width: 150,
          }}
          type="button"
          size="sm"
          onClick={applyOnClick}
          disabled={isLoading.state}
        >
          Apply
        </Button>
        <Referral>
          <SquareIcon color={Theme.mainColor} size={6} />
          <Title>{languagePack("당신의 추천인 코드입니다.", language.state)}</Title>
        </Referral>
        <InputWrapper>
          <Input value={user.state.referralCode} required={false} disabled={true} />
        </InputWrapper>
        <div>
          <CopyToClipboard text={user.state.referralCode} onCopy={onCopy.bind(null, { copy: "address" })}>
            <Button
              style={{
                marginTop: 10,
                marginBottom: 25,
                marginRight: 10,
                border: 0,
                borderRadius: 0,
                backgroundColor: Theme.mainColor,
                width: 150,
              }}
              type="button"
              size="sm"
            >
              Copy
            </Button>
          </CopyToClipboard>
          <CopyToClipboard
            text={`${CLIENT_HOST}/${Buffer.from(user.state.referralCode).toString("base64")}`}
            onCopy={onCopy.bind(null, { copy: "link" })}
          >
            <Button
              style={{
                marginTop: 10,
                marginBottom: 25,
                border: 0,
                borderRadius: 0,
                backgroundColor: Theme.mainColor,
                width: 150,
              }}
              type="button"
              size="sm"
            >
              Referral Link
            </Button>
          </CopyToClipboard>
        </div>
      </ReferralWrapper>
    </PageWrapper>
  );
};
export default ReferralPage;
