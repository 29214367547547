import React from "react";
import styled from "styled-components";
import { languagePack } from "../../Utils/language";

const GasWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  min-height: 40px;
`;

const GasBox = styled.div`
  width: 30%;
  height: fit-content;
  text-align: center;
  margin: 0px 5px;
  border-bottom: 1px solid grey;
  font-size: 12px;
  padding: 2px 0px 5px 0px;
  cursor: pointer;
  :hover {
    background-color: #e6e6e6;
  }
`;

const SelectedGasBox = styled.div`
  width: 30%;
  height: fit-content;
  text-align: center;
  margin: 0px 5px;
  border: 2px solid ${(props) => props.theme.mainColor};
  font-size: 12px;
  padding: 2px 0px 3px 0px;
  cursor: pointer;
  :hover {
    background-color: #e6e6e6;
  }
`;

const GasContainer = ({ text, price, isChecked, onClick }) =>
  isChecked ? (
    <SelectedGasBox>
      <div>
        <strong>{text}</strong>
      </div>
      {price}
    </SelectedGasBox>
  ) : (
    <GasBox onClick={onClick}>
      <div>
        <strong>{text}</strong>
      </div>
      {price}
    </GasBox>
  );

const Container = ({ gasState, gasSelectedState, language = "korean" }) => (
  <GasWrapper>
    {gasSelectedState.state.safeLow ? (
      <GasContainer
        isChecked={gasSelectedState.state.safeLow}
        text={languagePack("느림", language)}
        price={`${gasState.state.safeLow ? gasState.state.safeLow / 10 : 0} Gwei`}
      />
    ) : (
      <GasContainer
        isChecked={gasSelectedState.state.safeLow}
        text={languagePack("느림", language)}
        price={`${gasState.state.safeLow ? gasState.state.safeLow / 10 : 0} Gwei`}
        onClick={() => {
          gasSelectedState.setState({ average: false, safeLow: true, fastest: false });
        }}
      />
    )}
    {gasSelectedState.state.average ? (
      <GasContainer
        isChecked={gasSelectedState.state.average}
        text={languagePack("보통", language)}
        price={`${gasState.state.average ? gasState.state.average / 10 : 0} Gwei`}
      />
    ) : (
      <GasContainer
        isChecked={gasSelectedState.state.average}
        text={languagePack("보통", language)}
        price={`${gasState.state.average ? gasState.state.average / 10 : 0} Gwei`}
        onClick={() => {
          gasSelectedState.setState({ average: true, safeLow: false, fastest: false });
        }}
      />
    )}
    {gasSelectedState.state.fastest ? (
      <GasContainer
        isChecked={gasSelectedState.state.fastest}
        text={languagePack("빠름", language)}
        price={`${gasState.state.fastest ? gasState.state.fastest / 10 : 0} Gwei`}
      />
    ) : (
      <GasContainer
        isChecked={gasSelectedState.state.fastest}
        text={languagePack("빠름", language)}
        price={`${gasState.state.fastest ? gasState.state.fastest / 10 : 0} Gwei`}
        onClick={() => {
          gasSelectedState.setState({ average: false, safeLow: false, fastest: true });
        }}
      />
    )}
  </GasWrapper>
);

export default Container;
