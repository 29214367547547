import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import TokenInfoRow from "../../Components/Home/TokenRow";
import PageWrapper from "../../Components/Common/PageWrapper";
import globalContext from "../../Hooks/globalContext";

const Container = () => {
  const { balance, balanceConvert, toggleMain, user, contract, getBalance } = useContext(globalContext);

  const preload = () => {
    if (user.state && contract.state && !toggleMain.state) {
      toggleMain.setState(true);
      getBalance();
    }
  };

  useEffect(preload, [user.state, contract.state]);

  return (
    <PageWrapper>
      {balance.state && (
        <>
          <TokenInfoRow symbol="CAMP" fullName="Camp" balance={balance.state.camp} />
          <TokenInfoRow symbol="ETH" fullName="Ethereum" balance={balance.state.eth} />
        </>
      )}
    </PageWrapper>
  );
};

export default Container;
