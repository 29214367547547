import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button, Input } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";

import TokenInfoRow from "../../Components/Home/TokenRow";
import { languagePack } from "../../Utils/language";
import PageWrapper from "../../Components/Common/PageWrapper";
import globalContext from "../../Hooks/globalContext";
import Theme from "../../Styles/Theme";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import Axios from "axios";
import { HOST, NUMBER_REGEX } from "../../Utils/constants";
import { SquareIcon } from "../../Components/Common/Icons";
import { toast } from "react-toastify";

const OtpWrapper = styled.div`
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 50px;
`;

const CaptionBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const Title = styled.span`
  margin-left: 10px;
`;

const Clipboard = styled(CopyToClipboard)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: ${(props) => props.theme.darkGrey};
  text-decoration: underline;
  margin: 0;
  margin-bottom: 5px;
`;

const Key = styled.div`
  font-size: 20px;
  margin-bottom: 3px;
  text-align: center;
`;

const OTPNotification = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
`;

const Container = () => {
  const { user, language, httpHeader } = useContext(globalContext);
  const isLoading = useState(null);
  const otpSecret = useState(null);
  const otpIsNull = useState(null);
  const otpInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const phase = useState(0);

  const buttonOnClick = async () => {
    if (toast.isActive("otp")) {
      return;
    }

    if (phase.state === 0) {
      const { data } = await Axios.post(`${HOST}/user/otp/generate`, {}, httpHeader());
      otpSecret.setState(data.secret);
      phase.setState(1);
    } else if (phase.state === 1) {
      if (otpInput.value === "" || otpInput.value.length !== 6) {
        return toast.error(languagePack("올바른 OTP 인증번호가 아닙니다.", language.state), { toastId: "otp" });
      }

      const requestParams = {
        seed: otpSecret.state,
        token: otpInput.value,
      };
      isLoading.setState(true);
      const { data } = await Axios.post(`${HOST}/user/otp/generate`, requestParams, httpHeader());
      isLoading.setState(false);
      if (data.status) {
        otpSecret.setState(null);
        otpInput.setValue("");
        phase.setState(0);
        toast.success(languagePack("OTP가 등록되었습니다.", language.state), { toastId: "otp" });
        getOtp();
      } else {
        switch (data.code) {
          case "invalid":
            toast.error(languagePack("잘못된 OTP 인증번호입니다.", language.state), { toastId: "otp" });
            break;
          default:
            toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
              toastId: "otp",
            });
            break;
        }
      }
    }
  };

  const otpRelease = async () => {
    if (toast.isActive("otp")) {
      return;
    }

    if (otpInput.value === "" || otpInput.value.length !== 6) {
      return toast.error(languagePack("올바른 OTP 인증번호가 아닙니다.", language.state), { toastId: "otp" });
    }

    const requestParams = {
      pin: otpInput.value,
    };
    isLoading.setState(true);
    const { data } = await Axios.post(`${HOST}/user/otp/release`, requestParams, httpHeader());
    isLoading.setState(false);
    if (data.status) {
      otpSecret.setState(null);
      otpInput.setValue("");
      phase.setState(0);
      toast.success(languagePack("OTP가 해제되었습니다.", language.state), { toastId: "otp" });
      getOtp();
    } else {
      switch (data.code) {
        case "invalid":
          toast.error(languagePack("잘못된 OTP 인증번호입니다.", language.state), { toastId: "otp" });
          break;
        default:
          toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
            toastId: "otp",
          });
          break;
      }
    }
  };

  const getOtp = async () => {
    const { data } = await Axios.post(`${HOST}/user/otp/check`, null, httpHeader());
    otpIsNull.setState(data.isNull);
  };

  const preload = () => {
    getOtp();
  };

  const onCopy = ({ copy }) => {
    if (toast.isActive("copy")) {
      return;
    }
    toast.info(languagePack("OTP 키가 복사되었습니다.", language.state), { toastId: "copy" });
  };

  useEffect(preload, []);

  return (
    <PageWrapper>
      <OtpWrapper>
        {otpIsNull.state === 0 && (
          <>
            <CaptionBox>
              <SquareIcon color={Theme.mainColor} size={6} />
              <Title>{languagePack("이미 OTP가 등록되어있습니다.", language.state)}</Title>
            </CaptionBox>
            <CaptionBox>
              <Title>{languagePack("OTP를 해제하시려면 OTP 인증번호를 입력해주세요.", language.state)}</Title>
            </CaptionBox>
            <Input {...otpInput} placeholder={languagePack("OTP 인증번호 입력", language.state)} />
            <Button
              style={{ marginBottom: 25, border: 0, borderRadius: 0, backgroundColor: Theme.mainColor, width: 150 }}
              type="button"
              size="sm"
              onClick={otpRelease}
              disabled={isLoading.state}
            >
              {languagePack("다음", language.state)}
            </Button>
          </>
        )}
        {otpIsNull.state === 1 && (
          <>
            {phase.state === 0 && (
              <>
                <CaptionBox>
                  <SquareIcon color={Theme.mainColor} size={6} />
                  <Title>{languagePack("등록된 OTP가 없습니다. OTP를 등록하시겠습니까?", language.state)}</Title>
                </CaptionBox>
                <OTPNotification>
                  ({languagePack("OTP 등록 시 송금 인증에 비밀번호 대신 OTP가 사용됩니다.", language.state)})
                </OTPNotification>

                <Button
                  style={{ marginBottom: 25, border: 0, borderRadius: 0, backgroundColor: Theme.mainColor, width: 150 }}
                  type="button"
                  size="sm"
                  onClick={buttonOnClick}
                  disabled={isLoading.state}
                >
                  {languagePack("다음", language.state)}
                </Button>
              </>
            )}
            {phase.state === 1 && (
              <>
                <CaptionBox>
                  <SquareIcon color={Theme.mainColor} size={6} />
                  <Title>
                    {"Google OTP(Android), Google Authenticator(IOS) " +
                      languagePack("앱에 키를 등록해주세요.", language.state)}
                  </Title>
                </CaptionBox>
                <div style={{ padding: 20, paddingBottom: 0, justifyContent: "center", textAlign: "center" }}>
                  <QRCode
                    value={`otpauth://totp/${user.state.email}?secret=${otpSecret.state}&issuer=Camp-Wallet`}
                    size={150}
                  />
                  <Key>{otpSecret.state}</Key>
                  <Clipboard text={otpSecret.state} onCopy={onCopy}>
                    <div> {languagePack("클립보드로 복사", language.state)}</div>
                  </Clipboard>
                  <OTPNotification>
                    {"Google OTP(Authenticator)" +
                      " " +
                      languagePack("앱에 해당 OTP 키를 입력해주세요.", language.state)}
                  </OTPNotification>
                </div>
                <Input {...otpInput} placeholder={languagePack("OTP 인증번호 입력", language.state)} />
                <Button
                  style={{ marginBottom: 25, border: 0, borderRadius: 0, backgroundColor: Theme.mainColor, width: 150 }}
                  type="button"
                  size="sm"
                  onClick={buttonOnClick}
                  disabled={isLoading.state}
                >
                  {languagePack("다음", language.state)}
                </Button>
              </>
            )}
          </>
        )}
      </OtpWrapper>
    </PageWrapper>
  );
};

export default Container;
