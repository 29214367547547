import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const Button = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: ${props => props.theme.mainColor};
  width: 100%;
  color: white;
  padding: 15px;
  :hover {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;

const DefaultButton = ({ style = {}, onClick = () => null, text = "null", type = "button", disabled = false }) => {
  return (
    <Button style={style} onClick={disabled ? () => null : onClick} type="button">
      {disabled ? <ClipLoader sizeUnit={"px"} size={15} color={"white"} /> : text}
    </Button>
  );
};

export default DefaultButton;
