import React, { useContext } from "react";
import styled from "styled-components";
import globalContext from "../../Hooks/globalContext";
import HeaderLogoBlack from "../../Resources/Images/header_logo1.png";
import { HamburgerIcon } from "./Icons";

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0px;
  padding: 0px 15px;
  background-color: white;
  height: 50px;
  min-height: 50px;
`;

const Header = () => {
  const { sidebarToggle } = useContext(globalContext);

  return (
    <HeaderWrapper>
      <img style={{ width: 110, height: 32 }} src={HeaderLogoBlack} />
      <HamburgerIcon onClick={sidebarToggle} />
    </HeaderWrapper>
  );
};

export default Header;
