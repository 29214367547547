import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Axios from "axios";

import { ETHERSCAN_TX_URL, PAGE_UNIT, ETHERSCAN_API_ENDPOINT_ETH } from "../../../Utils/constants";
import { numberWithCommas, dateConvertor } from "../../../Utils/util";
import { ArrowIcon, EthereumIcon } from "../../../Components/Common/Icons";
import { languagePack } from "../../../Utils/language";
import useState from "../../../Hooks/useState";
import globalContext from "../../../Hooks/globalContext";
import PageWrapper from "../../../Components/Common/PageWrapper";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HistoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 15px;
`;

const HistoryRow = styled.div`
  display: flex;
  height: 60px;
  min-height: 60px;
  padding: 5px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  :not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
`;

const HistoryVerticalCommon = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HistoryLeft = styled.div`
  width: calc(100% - 140px);
  height: 100%;
`;
const HistoryRight = styled.div`
  width: 140px;
  height: 100%;
  display: flex;
  justify-content: center;
  font-size: 10px;
  flex-direction: column;
`;
const HistoryTop = styled(HistoryVerticalCommon)`
  color: grey;
  font-size: 13px;
`;
const HistoryBottom = styled(HistoryVerticalCommon)`
  font-size: 11px;
`;

const HashSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BalanceSpan = styled.span`
  font-family: monospace, monospace;
  white-space: normal;
  word-break: break-all;
  padding: 0px 2px;
`;

const BalanceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TypeWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: flex-end;
`;

const ArrowWrapper = styled.div`
  padding-left: 5px;
`;

const MoreWrapper = styled.div`
  padding: 25px 0px;
  text-align: center;
`;

const MoreSpan = styled.span`
  cursor: pointer;
  :hover {
    color: #d5d5d5;
    text-decoration: underline;
  }
  color: grey;
`;

const GreySpan = styled.span`
  color: grey;
`;

const BlockNumber = styled.span`
  font-weight: 800;
  color: #262626;
`;

const BlockDate = styled.span`
  font-weight: 300;
  color: grey;
`;

const Container = () => {
  const { user, language, web3 } = useContext(globalContext);
  const historyState = useState();
  const pageState = useState(1);
  const isLoading = useState(false);
  const countState = useState(0);
  const eof = useState(false);

  const preload = async () => {
    try {
    } catch (e) {
      toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
        toastId: "register",
      });
    }
    const { data } = await Axios.post(
      `${ETHERSCAN_API_ENDPOINT_ETH({
        address: user.state.address,
        page: pageState.state,
        offset: PAGE_UNIT,
        sort: "desc",
      })}`
    );
    countState.setState(data.result.length);
    historyState.setState(data.result);
  };

  const moreOnClick = () => {
    if (isLoading.state) {
      return;
    }
    isLoading.setState(true);
    const page = pageState.state;
    pageState.setState(page + 1);
    Axios.post(
      `${ETHERSCAN_API_ENDPOINT_ETH({
        address: user.state.address,
        page: page + 1,
        offset: PAGE_UNIT,
        sort: "desc",
      })}`
    )
      .then((response) => {
        if (response.data.result.length === 0) {
          isLoading.setState(false);
          eof.setState(true);
          return;
        }
        if (response.data.status === "0") {
          countState.setState(-1);
          isLoading.setState(false);
          return;
        }
        const concat = historyState.state.concat(response.data.result);
        historyState.setState(concat);
        isLoading.setState(false);
      })
      .catch((error) => {
        toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
          toastId: "register",
        });
        isLoading.setState(false);
      });
  };

  useEffect(() => {
    preload();
  }, []);

  return (
    <PageWrapper>
      {historyState.state ? (
        <HistoryWrapper>
          {historyState.state.map((element, index) => {
            const timeStamp = new Date(Number(`${element.timeStamp}000`));
            const date = dateConvertor(timeStamp);
            return (
              <HistoryRow
                key={index}
                onClick={() => {
                  window.open(`${ETHERSCAN_TX_URL}${element.hash}`);
                }}
              >
                <HistoryLeft>
                  <HistoryTop>
                    <BlockNumber>
                      {`#${element.blockNumber}`}
                      <BlockDate> ({date})</BlockDate>
                    </BlockNumber>
                  </HistoryTop>
                  <HistoryBottom>
                    <HashSpan>{element.hash}</HashSpan>
                  </HistoryBottom>
                </HistoryLeft>
                <HistoryRight>
                  <BalanceWrapper>
                    {element.value !== "0" && (
                      <>
                        <EthereumIcon size={15} />
                        <BalanceSpan>{numberWithCommas(web3.utils.fromWei(element.value))}</BalanceSpan>
                      </>
                    )}
                  </BalanceWrapper>
                  <TypeWrapper>
                    {element.value !== "0" ? (
                      <>
                        {element.from === user.state.address.toLowerCase() ? (
                          <>
                            <span>{languagePack("보낸 항목", language.state)}</span>
                            <ArrowWrapper>
                              <ArrowIcon size={16} color="#58E827" />
                            </ArrowWrapper>
                          </>
                        ) : (
                          <>
                            <span>{languagePack("받은 항목", language.state)}</span>
                            <ArrowWrapper>
                              <ArrowIcon size={16} color="#608EFF" isReceived={true} />
                            </ArrowWrapper>
                          </>
                        )}
                      </>
                    ) : (
                      <span>{languagePack("컨트랙트 호출", language.state)}</span>
                    )}
                  </TypeWrapper>
                </HistoryRight>
              </HistoryRow>
            );
          })}
          <MoreWrapper>
            {isLoading.state ? (
              <ClipLoader sizeUnit={"px"} size={15} color={"#747474"} />
            ) : historyState.state.length === 0 ? (
              <GreySpan>{languagePack("기록이 존재하지 않습니다.", language.state)}</GreySpan>
            ) : (
              <MoreSpan onClick={moreOnClick}>More...</MoreSpan>
            )}
          </MoreWrapper>
        </HistoryWrapper>
      ) : (
        <LoaderWrapper>
          <ClipLoader sizeUnit={"px"} size={15} color={"#747474"} />
        </LoaderWrapper>
      )}
    </PageWrapper>
  );
};
export default Container;
