import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import GlobalContext from "../Hooks/globalContext";

import TokenAdmin from "../Routes/TokenAdmin";
// import TokenMain from "../Routes/TokenMain";
import Home from "../Routes/Home";
import HistoryEth from "../Routes/History/Eth";
import HistoryCamp from "../Routes/History/Camp";

import UserReferral from "../Routes/User/Referral";
import UserPassword from "../Routes/User/Password";
import UserPhone from "../Routes/User/Phone";
import UserKyc from "../Routes/User/Kyc";

import Send from "../Routes/Send";
import Staking from "../Routes/Staking";
import Otp from "../Routes/OTP";

import Auth from "../Routes/Auth";
import Privacy from "../Routes/Privacy";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 2.5px 2.5px 5px 5px rgba(0, 0, 0, 0.2);
  input {
    margin: 10px 0px;
  }
  button {
    margin: 5px 0px;
  }
`;

const LoginRoutes = ({ user }) =>
  user.state ? (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/transfer" component={Send} />
      <Route exact path="/history/eth" component={HistoryEth} />
      <Route exact path="/history/camp" component={HistoryCamp} />
      <Route exact path="/user/referral" component={UserReferral} />
      <Route exact path="/user/password" component={UserPassword} />
      <Route exact path="/admin" render={(props) => <TokenAdmin {...props} />} />
      <Route exact path="/stake" component={Staking} />
      {/* <Route exact path="/user/otp" component={Otp} /> */}
      <Route exact path="/user/authentication/phone" component={UserPhone} />
      <Route exact path="/user/authentication/kyc" component={UserKyc} />
      <Redirect from="*" to="/" />
    </Switch>
  ) : (
    <LoaderWrapper>
      <ClipLoader size={15} color="grey" />
    </LoaderWrapper>
  );

const LogoutRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Auth {...props} page="intro" />} />
      <Route exact path="/login/verify/phone" render={(props) => <Auth {...props} page="login/verify/phone" />} />
      <Route exact path="/login/verify" render={(props) => <Auth {...props} page="login/verify" />} />
      <Route exact path="/login" render={(props) => <Auth {...props} page="login" />} />
      <Route exact path="/register/wallet" render={(props) => <Auth {...props} page="wallet" />} />
      <Route exact path="/register/:recommend" render={(props) => <Auth {...props} page="register" />} />
      <Route exact path="/register" render={(props) => <Auth {...props} page="register" />} />
      <Route exact path="/password/forgot" render={(props) => <Auth {...props} page="forgot" />} />
      <Route exact path="/password/verify" render={(props) => <Auth {...props} page="verify" />} />
      <Route exact path="/privacy" render={(props) => <Privacy {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const AppRouter = () => {
  const { isLogin, user } = useContext(GlobalContext);

  return isLogin.state ? <LoginRoutes user={user} /> : <LogoutRoutes />;
};

export default AppRouter;
