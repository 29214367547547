import React, { useContext } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

import Header from "./Header";
import globalContext from "../../Hooks/globalContext";
import MyWalletInfo from "../Home/MyWalletInfo";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  background-color: white;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  padding: 10px;
  background-color: white;
  align-items: center;
  flex-direction: column;
`;

const Box = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  box-shadow: 2.5px 2.5px 5px 5px rgba(0, 0, 0, 0.2);
  input {
    margin: 10px 0px;
  }
  button {
    margin: 5px 0px;
  }
`;

const PageWrapper = ({ children, style = null, contentStyle = null }) => {
  const { user } = useContext(globalContext);
  return (
    <Wrapper style={style}>
      <Box>
        <Header />
        <MyWalletInfo user={user} />
        <ContentWrapper style={contentStyle}>
          {user.state ? children : <ClipLoader size={15} color="white" />}
        </ContentWrapper>
      </Box>
    </Wrapper>
  );
};

export default PageWrapper;
