import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BeatLoader, ClipLoader } from "react-spinners";

import GlobalContext from "../../../Hooks/globalContext";
import Theme from "../../../Styles/Theme";
import { SquareIcon } from "../../../Components/Common/Icons";
import useInput from "../../../Hooks/useInput";
import Input from "../../../Components/Common/Input";
import useState from "../../../Hooks/useState";
import { languagePack } from "../../../Utils/language";
import { HOST } from "../../../Utils/constants";
import globalContext from "../../../Hooks/globalContext";
import PageWrapper from "../../../Components/Common/PageWrapper";

const AuthenticationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const AuthBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Title = styled.span`
  margin-left: 10px;
`;

const InputWrapper = styled.div`
  padding: 0px 15px;
  width: 100%;
`;

const Container = () => {
  const { user, language } = useContext(globalContext);
  const phoneInput = useInput({ defaultValue: "", maxLength: 11 });
  const tokenInput = useInput({ defaultValue: "", maxLength: 6 });
  const isLoading = useState(false);
  const authStep = useState(0);
  const phoneReadOnly = useState(false);
  const { httpHeader, getUser } = useContext(GlobalContext);

  const sendOnClick = async () => {
    if (toast.isActive("updatePhone")) {
      return;
    }
    if (phoneInput.value.length < 10 || phoneInput.value.length > 11) {
      return toast.error(languagePack("올바른 전화번호가 아닙니다.", language.state), {
        toastId: "updatePhone",
      });
    }
    isLoading.setState(true);

    const sendConfig = {
      phone: phoneInput.value,
    };
    const { data } = await axios.post(`${HOST}/auth/phone/send`, sendConfig, httpHeader());

    if (data.status) {
      authStep.setState(1);
      toast.info(languagePack("인증 번호를 전송했습니다.", language.state), { toastId: "updatePhone" });
    } else {
      switch (data.code) {
        case "already":
          toast.error(languagePack("이미 인증된 계정입니다.", language.state), { toastId: "updatePhone" });
          break;
        case "duplicate":
          toast.error(languagePack("이미 등록된 전화번호입니다.", language.state), { toastId: "updatePhone" });
          break;
        default:
          toast.error(languagePack("잘못된 요청입니다.", language.state), { toastId: "updatePhone" });
          break;
      }
    }
    isLoading.setState(false);
  };

  const verifyOnClick = async () => {
    isLoading.setState(true);
    if (toast.isActive("updatePhone")) {
      return;
    }
    if (tokenInput.value.length < 6) {
      return toast.error(languagePack("잘못된 코드입니다.", language.state), {
        toastId: "updatePhone",
      });
    }

    const { data } = await axios.post(
      `${HOST}/auth/phone/verify`,
      { token: tokenInput.value, phone: phoneInput.value },
      httpHeader()
    );

    if (data.status) {
      toast.success(languagePack("성공적으로 처리되었습니다.", language.state), { toastId: "updatePhone" });
      await getUser();
      authStep.setState(0);
    } else {
      switch (data.code) {
        case "invalid":
          toast.error(languagePack("잘못된 요청입니다.", language.state), {
            toastId: "updatePhone",
          });
          break;
        case "timeout":
          toast.error(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state), {
            toastId: "updatePhone",
          });
          break;
        case "token":
          toast.error(languagePack("잘못된 코드입니다.", language.state), { toastId: "verify" });
          break;
        default:
          toast.error(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state), {
            toastId: "updatePhone",
          });
          break;
      }
    }

    isLoading.setState(false);
  };

  const preload = async () => {
    const me = await getUser();
    if (me.phoneConfirmed === 1) {
      phoneInput.setValue(me.phone);
      phoneReadOnly.setState(true);
    }
  };

  useEffect(() => {
    preload();
  }, []);

  return (
    <PageWrapper>
      <AuthenticationWrapper>
        <AuthBox>
          <SquareIcon color={Theme.mainColor} size={6} />
          <Title>{languagePack("전화번호 인증", language.state)}</Title>
        </AuthBox>
        <InputWrapper>
          <Input readOnly={authStep.state === 1 || phoneReadOnly.state} placeholder="Phone Number" {...phoneInput} />
        </InputWrapper>
        {authStep.state === 0 && (
          <>
            {user.state &&
              (user.state.phoneConfirmed === 0 ? (
                <Button
                  style={{
                    marginTop: 10,
                    marginBottom: 25,
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: Theme.mainColor,
                    width: 150,
                  }}
                  type="button"
                  size="sm"
                  onClick={sendOnClick}
                  disabled={isLoading.state}
                >
                  {languagePack("인증", language.state)}
                </Button>
              ) : (
                <div style={{ color: Theme.darkGrey, textAlign: "center" }}>
                  {languagePack("이미 전화번호 인증이 완료된 계정입니다.", language.state)}
                </div>
              ))}
          </>
        )}

        {authStep.state === 1 && (
          <>
            <InputWrapper>
              <Input placeholder="Auth Token" {...tokenInput} />
            </InputWrapper>
            <Button
              style={{
                marginTop: 10,
                marginBottom: 25,
                border: 0,
                borderRadius: 0,
                backgroundColor: Theme.mainColor,
                width: 150,
              }}
              type="button"
              size="sm"
              onClick={verifyOnClick}
              disabled={isLoading.state}
            >
              {languagePack("인증", language.state)}
            </Button>
          </>
        )}
      </AuthenticationWrapper>
    </PageWrapper>
  );
};

export default Container;
