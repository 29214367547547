import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import Table from "rc-table";
import Paginate from "react-paginate";
import { toast } from "react-toastify";
import { Transaction as EthereumTx } from "ethereumjs-tx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";

import useInput from "../../Hooks/useInput";
import Input from "../Common/Input";
import GlobalContext from "../../Hooks/globalContext";
import { HOST, ADMIN_PAGE_UNIT, CONTRACT_ADDRESS, CHAIN_NAME } from "../../Utils/constants";
import useState from "../../Hooks/useState";
import Loader from "./Loader";
import { dateConvertor } from "../../Utils/util";
import Theme from "../../Styles/Theme";

const Top = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const Bottom = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const KycImage = styled.img`
  width: 80vw;
  height: auto;
  max-height: 80vh;
`;

const UpdateButton = styled.div`
  background-color: cadetblue;
  color: white;
  padding: 5px;
  width: 50px;
  border-radius: 5px;
  text-align: center;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
  :first-child {
    margin-right: 10px;
  }
`;

const DeleteButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.red};
`;

const DeleteBlock = styled(DeleteButton)`
  margin-top: 15px;
  width: 100%;
`;

const ReasonWrapper = styled.div`
  padding: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableStyles = styled.div`
  padding: 1rem;
  width: 100%;

  ul {
    justify-content: center;
  }

  table {
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.darkGrey};
    width: 100%;
    font-size: 13px;
    button {
      border: 0;
      margin: 0;
      color: white;
      background-color: ${(props) => props.theme.mainColor};
      :first-child {
        margin-right: 5px;
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.mainDarkColor};
      }

      :focus {
        outline: none;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.lightGrey};
      }
    }

    th {
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const SelectWrapper = styled.div`
  width: 300px;
`;

const options = [
  {
    value: "pending",
    label: "인증 대기상태 유저만 보기",
  },
  {
    value: "reject",
    label: "거절된 유저만 보기",
  },
  {
    value: "confirm",
    label: "승인된 유저만 보기",
  },
];
const customStyles = {};

const BottomItem = styled.div``;

const Page = () => {
  const table = useState(null);
  const page = useState(null);
  const { httpHeader, user } = useContext(GlobalContext);
  const isLoading = useState(null);
  const modal = useState(null);
  const selectedSort = useState(null);
  const nowPage = useState(0);

  const kycImage = useState(null);
  const selectedRow = useState(null);
  const reasonInput = useInput({ defaultValue: "", maxLength: 256 });
  const reasonOpen = useState(null);

  const handleChange = (selectedOption) => {
    const { value } = selectedOption;
    selectedSort.setState(selectedOption);
    tableFactory({ selected: 0, init: true, sort: value });
  };

  const toggle = () => {
    modal.setState(false);
    kycImage.setState(null);
    reasonOpen.setState(null);
    selectedRow.setState(null);
    reasonInput.setValue("");
  };
  const preload = () => {
    tableFactory({ selected: 0, init: true });
  };

  const tableFactory = async ({ selected, init = false, sort = null }) => {
    if (!sort && selectedSort.state) {
      sort = selectedSort.state.value;
    }
    nowPage.setState(selected);

    page.setState({ ...page.state, loading: true });
    const requestBody = { perPage: ADMIN_PAGE_UNIT, init, selected: selected + 1, sort };
    const { data } = await axios.post(`${HOST}/admin/kyc`, requestBody, httpHeader());
    table.setState(data.select);
    page.setState({
      ...page.state,
      loading: false,
      pageCount: Math.ceil(data.count / ADMIN_PAGE_UNIT),
    });
  };

  const getImage = async (item) => {
    const { data } = await axios.post(`${HOST}/admin/kyc/image`, { file: item }, httpHeader());
    modal.setState(true);
    kycImage.setState(`data:image/png;base64,${data.img}`);
  };

  const confirmAction = async (row) => {
    if (toast.isActive("kyc")) {
      return null;
    }
    const requestBody = { kycId: row.id, type: 1, email: row.email };
    const { data } = await axios.post(`${HOST}/admin/kyc/crud`, requestBody, httpHeader());

    if (data.status) {
      toast.success("성공적으로 처리되었습니다.", {
        toastId: "kyc",
        autoClose: 2000,
      });
    }
    tableFactory({ selected: nowPage.state });
  };

  const rejectAction = async () => {
    if (toast.isActive("kyc")) {
      return null;
    }
    const requestBody = { kycId: selectedRow.state.id, reason: reasonInput.value, type: 0 };
    const { data } = await axios.post(`${HOST}/admin/kyc/crud`, requestBody, httpHeader());

    if (data.status) {
      toast.success("성공적으로 처리되었습니다.", {
        toastId: "kyc",
        autoClose: 2000,
      });
    }
    toggle();
    tableFactory({ selected: nowPage.state });
  };

  const inputReason = (item) => {
    modal.setState(true);
    reasonOpen.setState(true);
    selectedRow.setState(item);
  };

  useEffect(preload, []);

  const airdropForm = [
    {
      title: "KYC 번호",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "E-Mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "이름",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "주소",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "신청 날짜",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <>{dateConvertor(new Date(item))}</>,
    },
    {
      title: "승인/거절일",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (item) => <>{item ? dateConvertor(new Date(item)) : "-"}</>,
    },
    {
      title: "거절사유",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "이미지 확인",
      dataIndex: "kycImage",
      key: "kycImage",
      render: (item) => (
        <>
          <UpdateButton onClick={getImage.bind(null, item)}>확인</UpdateButton>
        </>
      ),
    },
    {
      title: "상태",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <>
          {item === 0 && `승인 대기`}
          {item === 1 && <span style={{ color: "blue" }}>승인됨</span>}
          {item === 2 && <span style={{ color: Theme.red }}>거절됨</span>}
        </>
      ),
    },
    {
      title: "관리",
      key: "admin",
      render: (item) => {
        return (
          <ButtonWrapper>
            {item.status === 0 && (
              <>
                <UpdateButton onClick={confirmAction.bind(null, item)}>승인</UpdateButton>
                <DeleteButton onClick={inputReason.bind(null, item)}>거절</DeleteButton>
              </>
            )}
          </ButtonWrapper>
        );
      },
    },
  ];
  return table.state ? (
    <>
      <div>
        <Modal style={{ maxWidth: "fit-content" }} isOpen={modal.state} toggle={toggle}>
          <ModalHeader toggle={toggle}>KYC INFO</ModalHeader>
          <ModalBody>
            {kycImage.state && <KycImage src={kycImage.state} />}
            {reasonOpen.state && (
              <ReasonWrapper>
                <Input {...reasonInput} />
                <DeleteBlock onClick={rejectAction}>거절</DeleteBlock>
              </ReasonWrapper>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              닫기
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <Top>
        <SelectWrapper>
          <Select
            onChange={handleChange}
            isClearable={false}
            isSearchable={false}
            styles={customStyles}
            placeholder={"보기 유형"}
            options={options}
          />
        </SelectWrapper>
        <TableStyles>
          <Table rowKey={({ id }) => id} columns={airdropForm} data={table.state} />
        </TableStyles>
      </Top>
      <Bottom>
        <BottomItem></BottomItem>
        <BottomItem>
          {page.state && (
            <Paginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={page.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={tableFactory}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </BottomItem>
        <BottomItem></BottomItem>
      </Bottom>
    </>
  ) : (
    <Loader />
  );
};

export default Page;
