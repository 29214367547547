import React from "react";
import styled from "styled-components";

const ActionForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const ActionButton = styled.button`
  width: 33.3%;
  background-color: white;
  border: 1px solid #262626;
  font-size: 11px;
  padding: 5px 0px;
  min-height: 28px;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.75;
  }
  :not(:last-child) {
    border-right: 0;
  }
`;

const EnabledActionButton = styled(ActionButton)`
  border: 0;
  color: white;
  background-color: ${(props) => props.theme.mainColor};
  :hover {
    opacity: 0.75;
  }
`;

const Container = ({ actionState }) => (
  <ActionForm>
    {actionState.state === "userList" ? (
      <EnabledActionButton type="button">회원 관리</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("userList");
        }}
      >
        회원 관리
      </ActionButton>
    )}
    {actionState.state === "airdrop" ? (
      <EnabledActionButton>에어드롭</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("airdrop");
        }}
      >
        에어드롭
      </ActionButton>
    )}
    {actionState.state === "stake" ? (
      <EnabledActionButton>스테이킹 관리</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("stake");
        }}
      >
        스테이킹 관리
      </ActionButton>
    )}
    {actionState.state === "reward" ? (
      <EnabledActionButton>보상 관리</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("reward");
        }}
      >
        보상 관리
      </ActionButton>
    )}
    {actionState.state === "timeLockForm" ? (
      <EnabledActionButton>토큰 타임 락</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("timeLockForm");
        }}
      >
        토큰 타임 락
      </ActionButton>
    )}
    {actionState.state === "mintForm" ? (
      <EnabledActionButton>추가 발행</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("mintForm");
        }}
      >
        추가 발행
      </ActionButton>
    )}
    {actionState.state === "burnForm" ? (
      <EnabledActionButton>토큰 소각</EnabledActionButton>
    ) : (
      <ActionButton
        onClick={() => {
          actionState.setState("burnForm");
        }}
      >
        토큰 소각
      </ActionButton>
    )}
  </ActionForm>
);
export default Container;
