import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import Switch from "react-switch";

import GlobalContext from "../../Hooks/globalContext";
import { HOST, NUMBER_REGEX } from "../../Utils/constants";
import useState from "../../Hooks/useState";
import Input from "../Common/Input";
import useInput from "../../Hooks/useInput";
import { dateConvertor } from "../../Utils/util";
import DefaultButton from "../Common/DefaultButton";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Top = styled.div`
  padding: 10px;
  height: calc(100% - 60px);
  width: 100%;
`;

const Bottom = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const BottomItem = styled.div``;

const InnerBox = styled.div`
  :first-child {
    margin-bottom: 25px;
  }
`;

const InnerTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const InnerContent = styled.div`
  padding: 5px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 550px;
`;

const Page = () => {
  const { httpHeader } = useContext(GlobalContext);
  const config = useState(null);
  const registerAirdropInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const referralAirdropInput = useInput({ defaultValue: "", regex: NUMBER_REGEX });
  const checked = useState(null);

  const updateOnClick = async () => {
    if (toast.isActive("switch")) {
      return;
    }

    const { data } = await axios.post(
      `${HOST}/admin/config/update`,
      { registerAirdrop: registerAirdropInput.value, referralAirdrop: referralAirdropInput.value },
      httpHeader()
    );
    toast.info(`정보를 변경했습니다.`, {
      toastId: "switch",
      autoClose: 2000,
    });
  };

  const switchOnChange = async () => {
    if (toast.isActive("switch")) {
      return;
    }

    const { data } = await axios.post(
      `${HOST}/admin/config/update`,
      { registerEventStatus: !checked.state },
      httpHeader()
    );

    toast.info(checked.state ? "가입 이벤트를 종료했습니다." : "가입 이벤트를 시작하였습니다.", {
      toastId: "switch",
      autoClose: 2000,
    });

    checked.setState(!checked.state);
  };

  const getConfig = async () => {
    const { data } = await axios.post(`${HOST}/admin/config`, {}, httpHeader());
    registerAirdropInput.setValue(Number(data.registerAirdrop));
    referralAirdropInput.setValue(Number(data.referralAirdrop));
    config.setState(data);
    checked.setState(data.registerEventStatus === 1);
  };

  const preload = () => {
    getConfig();
  };

  useEffect(preload, []);
  return config.state ? (
    <>
      <Top>
        <InnerBox>
          <InnerTitle>현재 상태</InnerTitle>
          <InnerContent>
            <div>이벤트 상태</div>
            <div>{config.state.registerEventStatus === 0 ? "X" : "O"}</div>
          </InnerContent>
          <InnerContent>
            <div>가입시 에어드롭 지급량</div>
            <div>{Number(config.state.registerAirdrop)} CAMP</div>
          </InnerContent>
          <InnerContent>
            <div>추천인 에어드롭 지급량</div>
            <div>{Number(config.state.referralAirdrop)} CAMP</div>
          </InnerContent>
          <InnerContent>
            <div>최종 변경일</div>
            <div>{dateConvertor(new Date(config.state.createdAt))}</div>
          </InnerContent>
        </InnerBox>
        <InnerBox>
          <InnerTitle>정보 수정</InnerTitle>

          <InnerContent>
            <div>이벤트 상태 변경</div>
            <div>{checked.state !== null && <Switch onChange={switchOnChange} checked={checked.state} />}</div>
          </InnerContent>
          <InnerContent>
            <div>가입시 에어드롭 지급량</div>
            <div>
              <Input {...registerAirdropInput} placeholder={"가입 유저 에어드롭"} />
            </div>
          </InnerContent>
          <InnerContent>
            <div>추천인 에어드롭 지급량</div>
            <div>
              <Input {...referralAirdropInput} placeholder={"추천인 에어드롭"} />
            </div>
          </InnerContent>
        </InnerBox>
      </Top>
      <Bottom>
        <BottomItem></BottomItem>
        <BottomItem></BottomItem>
        <BottomItem>
          <DefaultButton style={{ padding: "10px 15px" }} text="변경" onClick={updateOnClick} />
        </BottomItem>
      </Bottom>
    </>
  ) : (
    <LoaderWrapper>
      <BounceLoader />
    </LoaderWrapper>
  );
};

export default Page;
